var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
        title: "Chi tiết khoản chi : " + _vm.nameKC,
        visible: _vm.isOpenDetailKT,
        top: "5vh",
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isOpenDetailKT = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "formReq",
          attrs: {
            "label-position": _vm.labelPosition,
            model: _vm.formReq,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": "80px",
                label: "Số lượng",
                prop: "quantityKC",
              },
            },
            [
              _c("el-input-number", {
                attrs: { disabled: _vm.isDisabled },
                model: {
                  value: _vm.formReq.quantityKC,
                  callback: function ($$v) {
                    _vm.$set(_vm.formReq, "quantityKC", $$v)
                  },
                  expression: "formReq.quantityKC",
                },
              }),
              _vm.formReq.quantityKC < 0
                ? _c("span", { staticClass: "error-message" }, [
                    _vm._v("    * Nhà trường thu tiền."),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": "80px",
                label: "Đơn giá",
                prop: "priceKC",
              },
            },
            [
              _c("el-currency-input", {
                staticStyle: { "text-align": "center" },
                attrs: {
                  id: "priceKC",
                  disabled: _vm.isDisabled,
                  autocomplete: "off",
                },
                model: {
                  value: _vm.formReq.priceKC,
                  callback: function ($$v) {
                    _vm.$set(_vm.formReq, "priceKC", $$v)
                  },
                  expression: "formReq.priceKC",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "font-size": "20px", "margin-left": "1px" } },
        [
          _vm._v(
            "Thành tiền :\n    " +
              _vm._s(
                _vm._f("formatCurrency")(
                  Math.round(
                    Number(_vm.formReq.quantityKC) * Number(_vm.formReq.priceKC)
                  )
                )
              ) +
              "\n  "
          ),
        ]
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isEditing,
                  expression: "isEditing",
                },
              ],
              attrs: { type: "primary", loading: _vm.loadingButton },
              on: {
                click: function ($event) {
                  return _vm.saveDetailKT("formReq")
                },
              },
            },
            [_vm._v("Cập nhật ")]
          ),
          this.statusOrder !== "Ẩn phiếu lương"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isEditing,
                      expression: "!isEditing",
                    },
                  ],
                  attrs: { type: "success" },
                  on: { click: _vm.editForm },
                },
                [_vm._v("Sửa")]
              )
            : _vm._e(),
          _c(
            "el-button",
            { attrs: { type: "danger" }, on: { click: _vm.handleClose } },
            [_c("i", { staticClass: "el-icon-circle-close" }), _vm._v(" Đóng")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <el-dialog
        :visible.sync="dialogVisibleEx"
        width="1000px"
        :before-close="closeDialog"
        :close-on-click-modal="false"
        top="3vh"
        title="Gửi thông báo qua App cho nhân viên"
    >
      <el-form
          label-width="140px"
          :model="createStudentNotify"
          label-position="left"
          :rules="rules"
          ref="createStudentNotify"
      >
        <el-row :gutter="40">
          <el-col :span="24">
            <div class="grid-content">
              <el-form-item label="Tiêu đề" prop="sendTitle">
                <el-input
                    placeholder="Nhập tiêu đề"
                    v-model="createStudentNotify.sendTitle"
                ></el-input>
              </el-form-item>
              <el-form-item label="Nội dung" prop="sendContent">
                <el-input
                    :rows="7"
                    type="textarea"
                    placeholder="Nhập nội dung..."
                    v-model="createStudentNotify.sendContent"
                ></el-input>
              </el-form-item>
              <span style="margin-left: 139px; color:#eb2020 ">Nhà trường có thể chọn đính kèm thông tin về học phí để PH nắm được chi tiết.</span>
              <el-form-item style="margin-top: 10px">
                <div>
                  <el-checkbox v-model="createStudentNotify.statusAttachNotifyFinance" @change="toggleAttachMessage">Đính
                    kèm thông tin công lương
                  </el-checkbox>
                  <el-checkbox v-model="createStudentNotify.attachOrderPDF">Đính kèm ảnh phiếu lương</el-checkbox>
                </div>
                <div v-if="showAttachMessage">
                  <span style="color: #eb2020">* Nội dung của bạn sẽ được đính kèm theo cả thông báo công lương!</span>
                </div>
              </el-form-item>
              <el-form-item label="File đính kèm" prop="attachFile">
                <el-row>
                  <el-col :span = "3">
                    <el-upload
                        action
                        :on-remove="handleRemove"
                        :before-remove="beforeRemove"
                        :on-change="toggleUpload"
                        :auto-upload="false"
                        multiple
                        :limit="5"
                        :on-exceed="handleExceed"
                        :file-list="fileList"
                        ref="upload"
                    >
                      <el-button size="mini" type="primary">Tải lên</el-button>
                    </el-upload>
                  </el-col>
                  <el-col :span = "12">
                    <span>Nhà trường có thể tải thêm file bổ sung nếu cần.</span>
                  </el-col>
                </el-row>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div class="tab-infor-button">
        <el-button
            type="success"
            size="medium"
            :loading="loadingButton"
            mini
            @click="submitForm('createStudentNotify')"
        >
          <i class="el-icon-circle-check"/>
          <span>Gửi</span>
        </el-button>
        <el-button
            type="danger"
            size="medium"
            @click="closeDialogByButton('createStudentNotify')"
        >
          <i class="el-icon-circle-close"/>
          <span>Đóng</span>
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {mapState} from "vuex";
import newSalaryService from "@/services/EmployeeService/NewSalaryService";

export default {
  name: "SendAppEmployeeSalary",
  props: {
    dialogVisibleEx: {
      type: Boolean,
      default: () => false,
    },

  },
  computed: {
    ...mapState("employeeSalaryNew", [
      "idInfoList",
      "dataSearch"
    ]),
  },
  data() {
    return {
      showAttachMessage: false,
      checked: false,
      fileList: [],
      fileAvatar: "",
      showUpload: true,
      dialogImageUrl: "",
      dialogVisible1: false,
      disabled: false,

      value: "",
      loadingButton: false,
      createStudentNotify: {
        attachOrderPDF : false,
        statusAttachNotifyFinance: false,
        spendTurn: 0,
        sendTitle: "",
        sendContent: "",
        idInfoList: "",
        date: "",
        multipartFileList: [],
      },

      multipartFileList: [],
      rules: {
        sendTitle: [
          {
            required: true,
            message: "Tiêu đề không được để trống",
            trigger: "blur",
          },
        ],
        sendContent: [
          {
            required: true,
            message: "Nội dung không được để trống",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;border:1px solid #fff";
    },
    closeDialog() {
      this.$refs.upload.clearFiles();
      this.multipartFileList = [];
      this.$refs["createStudentNotify"].resetFields();
      this.$emit("close", false);
    },
    //click when click button
    closeDialogByButton(fromData) {
      this.createStudentNotify.attachOrderPDF =false
      this.showAttachMessage = false
      this.createStudentNotify.statusAttachNotifyFinance = false
      this.$refs[fromData].resetFields();
      // this.showUpload = true;
      this.$refs.upload.clearFiles();
      this.multipartFileList = [];
      this.createStudentNotify.sendTitle = "";
      this.createStudentNotify.sendContent = "";
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
      this.$emit("close", false);
    },
    handleClose(done) {
      this.$confirm("Are you sure to close this dialog?")
          .then(() => {

            done();
          })
          .catch((error) => {
            console.log(error)
          });
    },
    submitForm(fromData) {

      this.createStudentNotify.idInfoList = this.idInfoList;
      this.createStudentNotify.spendTurn = this.dataSearch.spendTurn;
      this.createStudentNotify.date = this.$funcCommon.formatMY(this.dataSearch.year, this.dataSearch.month);
      this.$refs[fromData].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          let formData = new FormData();
          formData.append("statusAttachNotifyFinance", this.createStudentNotify.statusAttachNotifyFinance);
          formData.append("spendTurn", this.createStudentNotify.spendTurn);
          formData.append("sendTitle", this.createStudentNotify.sendTitle);
          formData.append("sendContent", this.createStudentNotify.sendContent);
          formData.append("date", this.createStudentNotify.date);
          formData.append("idInfoList", this.createStudentNotify.idInfoList);
          formData.append('attachOrderPDF',this.createStudentNotify.attachOrderPDF);
          if (this.multipartFileList.length > 0) {
            let sumSize = 0;

            this.multipartFileList.forEach((element, index) => {
              sumSize += element.size;
              if (element.size > 5 * 1024 * 1024) {
                this.$message.error("Kích thước file thứ" + index + "> 5MB");
              }
            });
            if (sumSize > 25 * 1024 * 1024) {
              this.$message.error("Tổng Kích thước file quá lớn > 25MB");
            }
            this.multipartFileList.forEach((element) => {
              formData.append("multipartFileList", element.raw);
            });
          }
          newSalaryService.sendNotifyFinanceForApp(formData)
              .then((response) => {
                if (response.data.message === "Chưa kích hoạt") {
                  this.$message({
                    message: "Nhân viên chưa kích hoạt tài khoản",
                    type: "error",
                    duration: 5000
                  });
                }else {
                  this.$message({
                    message: response.data.message,
                    type: "success",
                  });
                }
                setTimeout(() => {
                  this.closeDialogByButton(fromData);
                }, 500);
              })
              .catch((err) => {
                this.$message({
                  message: err.response.data.message,
                  type: "error",
                });

              })
              .finally(() => {
                setTimeout(() => {
                  this.loadingButton = false;
                }, 500);
              });
        } else {
          return false;
        }
      });
    },

    handleRemove(file) {
      // this.$refs.upload.clearFiles();
      let index = this.fileList.indexOf(file);
      if (index >= 0) {
        this.fileList.splice(index, 1);
      }
    },
    handleExceed() {
      this.$message.warning(`Bạn chỉ được chọn 5 file`);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible1 = true;
    },
    toggleUpload(file, fileList) {
      this.fileAvatar = file.raw;
      this.multipartFileList = fileList;
    },
    beforeRemove(file) {
      return this.$confirm(`Bạn chắc chắn muốn xóa ${file.name} ?`);
    },
    toggleAttachMessage() {
      this.showAttachMessage = this.createStudentNotify.statusAttachNotifyFinance;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__title {
  font-size: 25px;
  color: #606266;
}

/deep/ .el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}

/deep/ .el-button--primary {
  width: 100%;
  background: #409eff;
}

/deep/ .upload-demo {
  text-align: center;

  .el-upload {
    width: 100%;
  }
}

/deep/ .el-avatar {
  display: inline;

  img {
    border-radius: 5px;
    width: 100%;
  }
}

/deep/ .el-input.is-disabled .el-input__inner {
  text-align: center;
  color: #d13b93;
  font-weight: bold;
}

.el-select {
  display: block;
}

.tab-infor-button {
  text-align: right;
  margin-top: 20px;
}

.bg-purple {
  background: #d3dce6;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.group-radio-status {
  margin: 0 50px;

  .kids-status {
    margin-bottom: 10px;
  }
}

/deep/ .el-tabs__nav {
  background: #a0a19ce0;
}

/deep/ .el-tabs__item {
  color: white;
}

/deep/ .el-tabs__item.is-active {
  color: white;
  background: #67c23a;
}

/deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
  content: "*";
  color: #f56c6c;
  margin-left: 3px;
}

/deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: "";
  color: #f56c6c;
  margin-left: -4px;
}

.table-icon-1 {
  width: 30%;
  margin-left: 15%;
  display: inline-block;
}

.table-icon-2 {
  width: 30%;
  margin-right: 15%;
  float: right;
}
</style>

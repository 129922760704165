var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Điều chỉnh tiền đã chi ",
        visible: _vm.isOpenCollectedMoney,
        width: "800px",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isOpenCollectedMoney = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.dataResp,
            rules: _vm.rules,
            "label-position": _vm.labelPosition,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Tiền phải chi", "label-width": "170px" } },
            [
              _c("el-currency-input", {
                attrs: {
                  id: "sumMoney",
                  autocomplete: "off",
                  readonly: "",
                  type: "number",
                  disabled: true,
                },
                model: {
                  value: _vm.form.sumMoney,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sumMoney", $$v)
                  },
                  expression: "form.sumMoney",
                },
              }),
            ],
            1
          ),
          _vm.beforeMoney >= 0
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Tiền thừa tháng trước",
                    "label-width": "170px",
                    prop: "beforeMoney",
                  },
                },
                [
                  _c("el-currency-input", {
                    attrs: {
                      id: "beforeMoney",
                      autocomplete: "off",
                      options: _vm.customOption,
                      disabled: _vm.isDisabled,
                    },
                    model: {
                      value: _vm.beforeMoney,
                      callback: function ($$v) {
                        _vm.beforeMoney = $$v
                      },
                      expression: "beforeMoney",
                    },
                  }),
                ],
                1
              )
            : _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Tiền thiếu tháng trước",
                    "label-width": "170px",
                    prop: "beforeMoney",
                  },
                },
                [
                  _c("el-currency-input", {
                    attrs: {
                      id: "beforeMoney",
                      autocomplete: "off",
                      options: _vm.customOption,
                      disabled: _vm.isDisabled,
                    },
                    model: {
                      value: _vm.beforeMoney,
                      callback: function ($$v) {
                        _vm.beforeMoney = $$v
                      },
                      expression: "beforeMoney",
                    },
                  }),
                ],
                1
              ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "Tiền mặt",
                "label-width": "170px",
                prop: "cashMoney",
              },
            },
            [
              _c("el-currency-input", {
                attrs: {
                  autocomplete: "off",
                  id: "cashMoney",
                  type: "number",
                  options: _vm.customOption,
                  disabled: _vm.isDisabled,
                },
                model: {
                  value: _vm.cashMoney,
                  callback: function ($$v) {
                    _vm.cashMoney = $$v
                  },
                  expression: "cashMoney",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "Chuyển khoản",
                "label-width": "170px",
                prop: "transferMoney",
              },
            },
            [
              _c("el-currency-input", {
                attrs: {
                  autocomplete: "off",
                  id: "transferMoney",
                  type: "number",
                  options: _vm.customOption,
                  disabled: _vm.isDisabled,
                },
                model: {
                  value: _vm.transferMoney,
                  callback: function ($$v) {
                    _vm.transferMoney = $$v
                  },
                  expression: "transferMoney",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Tiền đã chi", "label-width": "170px" } },
            [
              [
                _c("el-currency-input", {
                  attrs: {
                    autocomplete: "off",
                    id: "spendMoney",
                    type: "number",
                    options: _vm.customOption,
                    disabled: true,
                  },
                  model: {
                    value: _vm.form.spendMoney,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "spendMoney", $$v)
                    },
                    expression: "form.spendMoney",
                  },
                }),
              ],
            ],
            2
          ),
          _vm.form.afterMoney >= 0
            ? _c(
                "el-form-item",
                {
                  attrs: { label: "Số tiền còn thừa", "label-width": "170px" },
                },
                [
                  _c("el-currency-input", {
                    attrs: {
                      id: "afterMoney",
                      autocomplete: "off",
                      type: "number",
                      options: _vm.customOption,
                      disabled: true,
                    },
                    model: {
                      value: _vm.afterMoneyMath,
                      callback: function ($$v) {
                        _vm.afterMoneyMath = $$v
                      },
                      expression: "afterMoneyMath",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.afterMoney < 0
            ? _c(
                "el-form-item",
                {
                  attrs: { label: "Số tiền còn thiếu", "label-width": "170px" },
                },
                [
                  _c("el-currency-input", {
                    attrs: {
                      id: "afterMoney",
                      autocomplete: "off",
                      type: "number",
                      options: _vm.customOption,
                      disabled: true,
                    },
                    model: {
                      value: _vm.afterMoneyMath,
                      callback: function ($$v) {
                        _vm.afterMoneyMath = $$v
                      },
                      expression: "afterMoneyMath",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "Lịch sử ghi chú", "label-width": "170px" } },
            [
              _c("el-input", {
                attrs: {
                  autocomplete: "off",
                  id: "noteExcel3",
                  type: "textarea",
                  rows: 3,
                  disabled: true,
                },
                model: {
                  value: _vm.form.noteExcel3,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "noteExcel3", $$v)
                  },
                  expression: "form.noteExcel3",
                },
              }),
            ],
            1
          ),
          this.statusSalary !== "Ẩn phiếu lương"
            ? _c(
                "el-form-item",
                { attrs: { label: "Ghi chú", "label-width": "170px" } },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      id: "noteExcel4",
                      rows: 3,
                      type: "textarea",
                      placeholder: "Nhập thông tin cần chỉnh sửa",
                      disabled: _vm.isDisabled,
                    },
                    model: {
                      value: _vm.noteExcel4,
                      callback: function ($$v) {
                        _vm.noteExcel4 = $$v
                      },
                      expression: "noteExcel4",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isEditing,
                  expression: "isEditing",
                },
              ],
              attrs: { type: "primary", loading: _vm.loadingButton },
              on: { click: _vm.modifyCollectedMoney },
            },
            [_vm._v("Cập nhật\n    ")]
          ),
          this.statusSalary !== "Ẩn phiếu lương"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isEditing,
                      expression: "!isEditing",
                    },
                  ],
                  attrs: { type: "success" },
                  on: { click: _vm.editForm },
                },
                [_vm._v("Sửa")]
              )
            : _vm._e(),
          _c(
            "el-button",
            { attrs: { type: "danger" }, on: { click: _vm.handleClose } },
            [_c("i", { staticClass: "el-icon-circle-close" }), _vm._v(" Đóng")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
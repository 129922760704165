import {handleAndDownloadExcel} from "@/utils/dowloadExcel";
import NewSalaryService from "@/services/EmployeeService/NewSalaryService";

export default {
    data() {
        return {
            loadingBill: false,
        }
    },
    methods:{
        //Xuất excel theo đợt thu
        handleExportExcelBySpendTurn(guiId, date, idInfoList, spendTurn) {
            const param = {
                guiId: guiId,
                date: date,
                idInfoList: idInfoList,
                spendTurn: spendTurn
            }
            NewSalaryService
                .exportExcelBySpendTurn(param)
                .then((res) => {
                    // chạy nếu k conf processing
                    if (res.data.message === "processing") {
                        this.loadingBill = true
                    }
                    // Call the function again after 5 seconds
                    const checkProcess = setInterval(() => {
                        NewSalaryService.exportExcelBySpendTurn(param).then((response) => {
                            // chạy nếu k conf processing
                            if (response.data.message === "processing") {
                                this.loadingBill = true
                                return;
                            }
                            if (response.data.message === "fail") {
                                this.loadingBill = false
                                clearInterval(checkProcess)
                                this.$notify.error({
                                    title: "Thông báo",
                                    message: "Lỗi không xuất được dữ liệu !",
                                });
                                return;
                            }
                            if (response.data.message !== "processing" && response.data.message !== "fail") {
                                const fileNameRegex = /complete_(.*).xlsx/;
                                const matches = response.data.message.match(fileNameRegex);
                                const fileName = matches[1];
                                this.loadingBill = false
                                this.$notify.success({
                                    title: "Thông báo",
                                    message: "Dữ liệu xuất thành công",
                                });
                                let excelFilename = response.data.message.split('complete_')[1];
                                clearInterval(checkProcess);
                                return NewSalaryService
                                    .downloadExcelFile(guiId)
                                    .then((res) => {
                                        const excelData = res.data;
                                        excelFilename = `${fileName}.xlsx`; // Provide a desired filename for the downloaded file
                                        handleAndDownloadExcel(excelData, excelFilename);
                                    });
                            }
                        })
                            .catch((err) => {
                                this.loadingBill = false
                                return Promise.reject(err);
                            });
                    }, 5000);
                })
                .catch((err) => {
                    this.loadingBill = false
                    return Promise.reject(err);
                });
        },

        // Xuất Excel công lương hàng loạt theo tháng
        exportExcelEmployeeSalaryAllByMonth(exportExcelAll) {
            NewSalaryService.downloadFileExcelAllByMonth(exportExcelAll).then((response) => {
                if (response.data.message === "processing") {
                    this.$notify.info({
                        title: "Thông báo",
                        message: "Dữ liệu đang được xử lý !",
                    });
                }
                const checkProcess = setInterval(() => {
                    NewSalaryService
                        .downloadFileExcelAllByMonth(exportExcelAll)
                        .then((response) => {
                            // chạy nếu k conf processing
                            if (response.data.message === "processing") {
                                this.loadingBill = true;
                                return;
                            }
                            if (response.data.message === "fail") {
                                this.loadingBill = false
                                clearInterval(checkProcess)
                                this.$notify.error({
                                    title: "Thông báo",
                                    message: "Lỗi không xuất được dữ liệu !",
                                });
                                return;
                            }
                            if (response.data.message !== "processing" && response.data.message !== "fail") {
                                const fileNameRegex = /complete_(.*).xlsx/;
                                const matches = response.data.message.match(fileNameRegex);
                                const fileName = matches[1];
                                this.loadingBill = false
                                this.$notify.success({
                                    title: "Thông báo",
                                    message: "Dữ liệu xuất thành công",
                                });
                                let excelFilename = response.data.message.split('complete_')[1];
                                clearInterval(checkProcess);
                                return NewSalaryService
                                    .downloadExcelFile(exportExcelAll.guiId)
                                    .then((res) => {
                                        const excelData = res.data;
                                        excelFilename = `${fileName}.xlsx`; // Provide a desired filename for the downloaded file
                                        handleAndDownloadExcel(excelData, excelFilename);
                                    });
                            }
                        })
                        .catch((err) => {
                            this.loadingBill = false
                            return Promise.reject(err);
                        });
                }, 5000);
            })
        },
    }

}
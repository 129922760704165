<template>
  <el-dialog @open="handleFetchTemplate" :visible.sync="isDialogTemplateVisible" top="30vh" width="530px"
             :close-on-click-modal="false"
             title="Xóa mẫu công lương" :before-close="handleClose">
    <el-row style="height: 90px">
      <el-col :span="9" style="margin-top: 5px">Danh sách mẫu công lương :</el-col>
      <el-col :span="15">
        <el-select v-model="listTemplate" @change="handleSelectChange" multiple size="small" style="width: 255px"
                   placeholder="Chọn file">
          <el-option
              v-for="(item,index) in listTemplateEmployeeSalary"
              :key="index"
              :label="item.nameFile"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <span v-if="listTemplate.length!==0">Mô tả file: {{ selectedDescription }} </span>
      </el-col>
    </el-row>
    <div class="custom_button">
      <el-button type="success" @click="handleDeleteTemplate" icon="el-icon-delete" size="medium"
                 v-if="listTemplate.length !== 0">Xóa File
      </el-button>
      <el-button type="danger" @click="handleClose" size="medium" > <i class="el-icon-circle-close"/> Đóng</el-button>
    </div>
  </el-dialog>
</template>
<script>
import {mapState} from "vuex";
import NewSalaryService from "@/services/EmployeeService/NewSalaryService";

export default {
  name: 'DeleteTemplateEmployeeSalary',
  props: {
    isDialogTemplateVisible: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      selectedDescription: '',
      listTemplate: [],
      params: {
        listTemplate: '',
      },
      listTemplateEmployeeSalary: []
    }
  },
  computed: {
    ...mapState("employeeSalaryNew", ["idInfoList", "dataSearch"]),
  },
  methods: {
    handleSelectChange() {
      const selectedFile = this.listTemplateEmployeeSalary.filter(file => this.listTemplate.includes(file.id))
      const foundSelectedFile = selectedFile.map(item => item.description);
      this.selectedDescription = foundSelectedFile.join(', ')
    },
    handleDeleteTemplate() {
      this.$confirm('Thao tác này sẽ xóa file hệ thống. Tiếp tục?', 'Warning', {
        title: 'Cảnh báo',
        confirmButtonText: 'Có',
        cancelButtonText: 'Không',
        type: 'warning'
      }).then(() => {
        this.params.listTemplate = this.listTemplate.join(',')
        NewSalaryService.deleteTemplateEmployeeSalary(this.params).then(() => {
          this.$message({
            type: 'success',
            message: 'Xóa thành công'
          })
          this.handleClose() // tắt màn
          ;
        }).catch(() => {
          this.$message({
            message: "Xóa file thất bại",
            type: "error",
          });
        });
      }).catch(() => {
        this.$message({
          type: "error",
          message: 'Xóa không thành công'
        });
      });
    },
    handleClose() {
      this.listTemplateEmployeeSalary = []
      this.listTemplate = []
      this.selectedDescription = ''
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
      this.$emit('closeTemplateDeleteDialog', false)
    },
    handleFetchTemplate() {
      NewSalaryService
          .getAllTemplateList()
          .then((res) => {
            this.listTemplateEmployeeSalary = res.data.filter(item => item.id !== 0)
          }).catch((error) =>{
        console.log(error)
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.el-button--danger {
  margin-right: 11%;
  color: #FFF;
  background-color: #F56C6C;
  border-color: #F56C6C;
}

.custom_button {
  display: flex;
  justify-content: right;
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            width: "450px",
            title: "Thêm khoản",
            visible: _vm.isOpenAddNameExpensesDialog,
            "before-close": _vm.handleCloseAddNameExpensesDialog,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isOpenAddNameExpensesDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "formData",
                  attrs: {
                    model: _vm.formData,
                    "label-position": "left",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên khoản mới", prop: "newNameKC" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Nhập tên khoản chi mới" },
                        model: {
                          value: _vm.formData.newNameKC,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "newNameKC", $$v)
                          },
                          expression: "formData.newNameKC",
                        },
                      }),
                      _c("span", { staticClass: "error-message" }, [
                        _vm._v(_vm._s(_vm.customErrorMessages.newNameKC)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "custom_button" },
                [
                  _vm.showSaveChange
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "success",
                            size: "medium",
                            loading: _vm.loadingChangeName,
                          },
                          on: { click: _vm.submitForm },
                        },
                        [
                          _c("i", { staticClass: "el-icon-circle-check" }),
                          _vm._v(" Lưu\n        "),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium", type: "danger" },
                      on: { click: _vm.handleCloseAddNameExpensesDialog },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-close" }),
                      _vm._v(" Đóng\n        "),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
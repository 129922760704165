<template>

  <div>
    <el-dialog
        :title="'Lịch sử phiếu lương: ' + titleInfoAdd"
        :visible.sync="isOpenHistoryDialog"
        width="1500px"
        :before-close="handleClose"
        :close-on-click-modal="false"
        top="5vh"
    >
      <el-table
          ref="multipleTable"
          :empty-text="textTable"
          highlight-current-row
          :data="historyList"
          :cell-style="tableRowStyle"
          :header-cell-style="tableHeaderColor"
          :max-height="$tableMaxHeight"
          border
      >
        <el-table-column
            type="index"
            fixed
            label="STT"
            width="50"
            align="center"
        ></el-table-column>
        <el-table-column
            width="150"
            fixed
            align="center"
            label="Ngày tạo"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.createdDate | formatDateTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
            width="130"
            fixed
            align="center"
            label="Ngày thanh toán"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.datePayment | formatDate }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Người thao tác"
            width="170"
            align="center"
            prop="userName"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.userName }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Người thanh toán"
            width="180"
            prop="schoolPays"
            align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.schoolPays }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Tiền mặt"
                         align="right"
                         width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.cashMoney | formatCurrency }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Chuyển khoản" align="right" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.transferMoney | formatCurrency }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Đã chi" align="right" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.spendMoney | formatCurrency }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Chuyển thanh toán"
                         min-width="110"
                         align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.transactionEntry==='MONEY_WALLET'">Không chuyển</span>
            <span v-if="scope.row.transactionEntry==='MONEY_MONTH'">Sang tháng sau</span>
          </template>
        </el-table-column>
        <el-table-column label="Mô tả"
                         min-width="180"
                         align="center">
          <template slot-scope="scope">
            <span class="description" @click="detailInformation(scope.row.noteHistory)">{{ scope.row.noteHistory }}</span>
          </template>
        </el-table-column>

      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" size="medium" @click="handleClose()">
          <i class="el-icon-circle-close"/>
          <span>{{ $t("button.close") }}</span>
        </el-button>
      </span>
    </el-dialog>
    <div>
<!--      Màn mô tả lịch sử thanh toán-->
      <DetailInformationEmployeeSalary
          :description = "description"
          :dialogVisible="showDetailInformation"
          @dialog-close="closeDetailInformation"
          ref="DetailInformationEmployeeSalary"
      />
    </div>
  </div>

</template>

<script>
import DetailInformationEmployeeSalary
  from "@/components/FinanceVer2/employeeSalary/employeeSalaryTableExcelDialog/DetailInformationEmployeeSalary.vue";
import NewSalaryService from "@/services/EmployeeService/NewSalaryService";

export default {
  name: "HistoryExpensesEmployeeSalary",
  components: {DetailInformationEmployeeSalary},
  emits: {},
  props: {
    isOpenHistoryDialog: {
      type: Boolean,
      default: () => true,
    },

  },
  data() {
    return {
      textTable: this.$tableEmpty,
      fullName: "",
      orderKidCode: "",
      historyList: [],
      idInfo: 0,
      month: 0,
      year: 0,
      spendTurn: 0,
      loadingButton: false,
      titleInfoAdd: "",
      showDetailInformation : false,
      description: ""
    }
  },
  computed: {},
  created() {
  },
  methods: {
    detailInformation(description){
      this.showDetailInformation = true
      this.description = description
    },
    closeDetailInformation(){
      this.showDetailInformation = false
    },
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;";
    },
    tableRowStyle({row}) {
      if (row.category === "out") {
        return "color: #409EFF";
      }
    },
    handleClose() {
      this.$emit("closeHistoryOrderKids", false);
    },

    getOrderHistoryInitial(idInfo,date, spendTurn) {
      this.idInfo = idInfo;
      this.spendTurn = spendTurn;
      const param = {
        date: date,
        idInfo : idInfo,
        spendTurn: spendTurn
      }
      NewSalaryService.toRetrievePaymentHistoryInformation(param)
          .then((resp) => {
            this.historyList = resp.data;
            console.log(this.historyList)
            this.fullName = this.historyList[0].fullName
            this.paySlipCode = this.historyList[0].paySlipCode

            this.titleInfoAdd = this.fullName + " - " + this.paySlipCode
          })
          .catch((err) => {
            this.responseList = [];
            this.$message({
              message: err.response.data.message,
              type: "error",
            });
          })
    },


  },
}
</script>

<style lang="scss" scoped>

.el-h1-text {
  font-size: 30px;
  font-weight: inherit;
  color: #eb2020;
}

.div-history {
  min-width: 500px !important;
}

.description{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  cursor: pointer
}
</style>

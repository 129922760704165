import NewSalaryService from "@/services/EmployeeService/NewSalaryService";

export default {
    data() {
        return {
            loadingUpdate: false,
            updateAttendance: true,
            updateAttendanceForGroup: true,
        }
    },
    methods: {

        // cập nhật điểm danh
        updateAttendanceDataEmployee(date, dataSearch) {
            console.log(date)
            this.loadingUpdate = true
            this.updateAttendance = false;
            this.$store.dispatch("employeeSalaryNew/init_employee_data", dataSearch);
            NewSalaryService.updateAttendanceEmployee(date).then((response) => {

                this.loadingUpdate = false
                this.updateAttendance = true
                if (response.data.message === "Cập nhật thành công") {
                    this.$notify.success({
                        title: "Thông báo",
                        message: "Cập nhật thành công",
                    });
                }
            }).catch((error) => {
                    this.loadingUpdate = false
                    console.log(error)
                }
            )
        },


    }
}
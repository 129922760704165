<template>
  <div class="table-content row-data">
    <!-- chi tiết khoản các học sinh -->
    <el-table
        element-loading-spinner="el-icon-loading"
        :empty-text="textTable"
        :data="responseFilterList"
        v-loading="loadingData"
        :element-loading-text="$tableLoading"
        element-loading-background="rgba(255,255,255, 0)"
        :header-cell-style="tableHeaderColor"
        @selection-change="handleSelectionChange"
        :max-height="$tableMaxHeight"
        border
    >
      <el-table-column
          type="selection"
          align="center"
          width="55"
      ></el-table-column>
      <el-table-column
          type="index"
          fixed="left"
          label="STT"
          width="50"
          align="center"
      ></el-table-column>

      <el-table-column width="205" fixed="left" label="Họ tên">
        <template slot-scope="scope">
          <el-tooltip placement="top">
            <div slot="content">Mã nhân viên: {{ scope.row.code }}<br>SĐT: {{ scope.row.phone }}</div>
            <span>{{ scope.row.fullName }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column width="145" label="Số tài khoản" align="center">
        <template slot-scope="scope">
          <el-tooltip placement="top">
            <div slot="content">Ngân hàng: {{ scope.row.nameBank }}</div>
            <span>{{ scope.row.bankAccountNumber }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" width="70" label="Hiển thị">
        <template slot-scope="scope">
          <el-checkbox
              v-model="scope.row.orderHidden"
              @change="handelShowOrHidden(scope.row)"
              :disabled="
              scope.row.statusSalary === null || scope.row.statusSalary === 'Ẩn phiếu lương'
            "
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="Thông tin bổ sung" align="center" width="90">
        <template slot-scope="scope">
          <el-button
              size="mini"
              @click="handleOpenEmployeeDetail(scope.row)"
          ><i class="el-icon-document"></i
          ></el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" width="100" label="Tồn phiếu lương">
        <template slot-scope="scope">
          <el-button
              class="click-in-row"
              type="text"
              @click="inventoryOrder(scope.row)"
              v-if="scope.row.count !== 0"
          >{{ scope.row.count }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" width="120" label="Trạng thái phiếu lương">
        <template slot-scope="{ row }">
          <div :class="getStatusColor(row.statusSalary)">
            {{ getStatusValue(row.statusSalary) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" width="120" label="Chuyển thanh toán">
        <template slot-scope="scope">
          <span v-if="scope.row.transactionEntry==='MONEY_WALLET'&& scope.row.afterMoney !==0" style="color: orange ">Không chuyển</span>
          <span v-if="scope.row.transactionEntry==='MONEY_MONTH'&& scope.row.afterMoney !==0" style="color: blue ">Sang tháng sau</span>
        </template>
      </el-table-column>
      <el-table-column
          align="right"
          width="110"
          label="Thiếu/Thừa tháng trước"
          prop="beforeMoney"
      >
        <template slot-scope="{ row }">
          {{ row.beforeMoney | formatCurrency }}
        </template>
      </el-table-column>
      <el-table-column align="right" width="110" label="Phải chi tháng này">
        <template slot-scope="{ row }">
          {{ row.sumMoney | formatCurrency }}
        </template>
      </el-table-column>
      <el-table-column align="right" width="115" label="Đã chi">
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.statusSalary) !=='Chưa có phiếu lương'"
              class="click-in-row"
              type="text"
              @click="collectedMoneyMethod(scope.row, scope.row.statusSalary)">
            <span v-if="scope.row.spendMoney">{{
                scope.row.spendMoney
                    | formatCurrency
              }}</span>
            <span v-else><b>-</b></span>
          </el-button>
        </template>
      </el-table-column>

      <el-table-column align="right" width="115" label="Tiền mặt">
        <template slot-scope="{ row }">
          {{ row.cashMoney | formatCurrency }}
        </template>
      </el-table-column>
      <el-table-column align="right" width="115" label="Chuyển khoản">
        <template slot-scope="{ row }">
          {{ row.transferMoney | formatCurrency }}
        </template>
      </el-table-column>
      <el-table-column align="right" width="115" label="Thiếu / Thừa còn lại">
        <template slot-scope="{ row }">
          {{ row.afterMoney | formatCurrency }}
        </template>
      </el-table-column>
      <el-table-column
          v-if="paySlipNameKT.nameK01 !== 'Khoản 1'"
          align="right"
          width="115"
          :label="paySlipNameKT.nameK01"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameExpenses(paySlipNameKT.nameK01)" style="cursor: pointer;">{{
                paySlipNameKT.nameK01
              }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.statusSalary) !=='Chưa có phiếu lương'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.statusSalary)
            "
          >
            <span v-if="scope.row.moneyK01"> {{ scope.row.moneyK01 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="paySlipNameKT.nameK02 !== 'Khoản 2'"
          align="right"
          width="115"
          :label="paySlipNameKT.nameK02"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameExpenses(paySlipNameKT.nameK02)" style="cursor: pointer;">{{
                paySlipNameKT.nameK02
              }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.statusSalary) !=='Chưa có phiếu lương'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.statusSalary)
            "
          >
            <span v-if="scope.row.moneyK02"> {{ scope.row.moneyK02 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="paySlipNameKT.nameK03 !== 'Khoản 3'"
          align="right"
          width="115"
          :label="paySlipNameKT.nameK03"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameExpenses(paySlipNameKT.nameK03)" style="cursor: pointer;">{{
                paySlipNameKT.nameK03
              }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.statusSalary) !=='Chưa có phiếu lương'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.statusSalary)
            "
          >
            <span v-if="scope.row.moneyK03"> {{ scope.row.moneyK03 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          align="right"
          v-if="paySlipNameKT.nameK04 !== 'Khoản 4'"
          width="115"
          :label="paySlipNameKT.nameK04"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameExpenses(paySlipNameKT.nameK04)" style="cursor: pointer;">{{
                paySlipNameKT.nameK04
              }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.statusSalary) !=='Chưa có phiếu lương'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.statusSalary)
            "
          >
            <span v-if="scope.row.moneyK04" style="text-align: right"> {{ scope.row.moneyK04 | formatCurrency }}</span>
            <span v-else style="text-align: right">-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="paySlipNameKT.nameK05 !== 'Khoản 5'"
          align="right"
          width="115"
          :label="paySlipNameKT.nameK05"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameExpenses(paySlipNameKT.nameK05)" style="cursor: pointer;">{{
                paySlipNameKT.nameK05
              }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.statusSalary) !=='Chưa có phiếu lương'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.statusSalary)
            "
          >
            <span v-if="scope.row.moneyK05"> {{ scope.row.moneyK05 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="paySlipNameKT.nameK06 !== 'Khoản 6'"
          align="right"
          width="115"
          :label="paySlipNameKT.nameK06"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameExpenses(paySlipNameKT.nameK06)" style="cursor: pointer;">{{
                paySlipNameKT.nameK06
              }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.statusSalary) !=='Chưa có phiếu lương'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.statusSalary)
            "
          >
            <span v-if="scope.row.moneyK06"> {{ scope.row.moneyK06 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="paySlipNameKT.nameK07 !== 'Khoản 7'"
          align="right"
          width="115"
          :label="paySlipNameKT.nameK07"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameExpenses(paySlipNameKT.nameK07)" style="cursor: pointer;">{{
                paySlipNameKT.nameK07
              }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.statusSalary) !=='Chưa có phiếu lương'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.statusSalary)
            "
          >
            <span v-if="scope.row.moneyK07"> {{ scope.row.moneyK07 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="paySlipNameKT.nameK08 !== 'Khoản 8'"
          align="right"
          width="115"
          :label="paySlipNameKT.nameK08"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameExpenses(paySlipNameKT.nameK08)" style="cursor: pointer;">{{
                paySlipNameKT.nameK08
              }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.statusSalary) !=='Chưa có phiếu lương'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.statusSalary)
            "
          >
            <span v-if="scope.row.moneyK08"> {{ scope.row.moneyK08 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="paySlipNameKT.nameK09 !== 'Khoản 9'"
          align="right"
          width="115"
          :label="paySlipNameKT.nameK09"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameExpenses(paySlipNameKT.nameK09)" style="cursor: pointer;">{{
                paySlipNameKT.nameK09
              }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.statusSalary) !=='Chưa có phiếu lương'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.statusSalary)
            "
          >
            <span v-if="scope.row.moneyK09"> {{ scope.row.moneyK09 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="paySlipNameKT.nameK10 !== 'Khoản 10'"
          align="right"
          width="115"
          :label="paySlipNameKT.nameK10"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameExpenses(paySlipNameKT.nameK10)" style="cursor: pointer;">{{
                paySlipNameKT.nameK10
              }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.statusSalary) !=='Chưa có phiếu lương'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.statusSalary)
            "
          >
            <span v-if="scope.row.moneyK10"> {{ scope.row.moneyK10 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="paySlipNameKT.nameK11 !== 'Khoản 11'"
          align="right"
          width="115"
          :label="paySlipNameKT.nameK11"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameExpenses(paySlipNameKT.nameK11)" style="cursor: pointer;">{{
                paySlipNameKT.nameK11
              }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.statusSalary) !=='Chưa có phiếu lương'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.statusSalary)
            "
          >
            <span v-if="scope.row.moneyK11"> {{ scope.row.moneyK11 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="paySlipNameKT.nameK12 !== 'Khoản 12'"
          align="right"
          width="115"
          :label="paySlipNameKT.nameK12"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameExpenses(paySlipNameKT.nameK12)" style="cursor: pointer;">{{
                paySlipNameKT.nameK12
              }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.statusSalary) !=='Chưa có phiếu lương'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.statusSalary)
            "
          >
            <span v-if="scope.row.moneyK12"> {{ scope.row.moneyK12 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="paySlipNameKT.nameK13 !== 'Khoản 13'"
          align="right"
          width="115"
          :label="paySlipNameKT.nameK13"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameExpenses(paySlipNameKT.nameK13)" style="cursor: pointer;">{{
                paySlipNameKT.nameK13
              }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.statusSalary) !=='Chưa có phiếu lương'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.statusSalary)
            "
          >
            <span v-if="scope.row.moneyK13"> {{ scope.row.moneyK13 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="paySlipNameKT.nameK14 !== 'Khoản 14'"
          align="right"
          width="115"
          :label="paySlipNameKT.nameK14"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameExpenses(paySlipNameKT.nameK14)" style="cursor: pointer;">{{
                paySlipNameKT.nameK14
              }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.statusSalary) !=='Chưa có phiếu lương'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.statusSalary)
            "
          >
            <span v-if="scope.row.moneyK14"> {{ scope.row.moneyK14 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="paySlipNameKT.nameK15 !== 'Khoản 15'"
          align="right"
          width="115"
          :label="paySlipNameKT.nameK15"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameExpenses(paySlipNameKT.nameK15)" style="cursor: pointer;">{{
                paySlipNameKT.nameK15
              }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.statusSalary) !=='Chưa có phiếu lương'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.statusSalary)
            "
          >
            <span v-if="scope.row.moneyK15"> {{ scope.row.moneyK15 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="paySlipNameKT.nameK16 !== 'Khoản 16'"
          align="right"
          width="115"
          :label="paySlipNameKT.nameK16"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameExpenses(paySlipNameKT.nameK16)" style="cursor: pointer;">{{
                paySlipNameKT.nameK16
              }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.statusSalary) !=='Chưa có phiếu lương'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.statusSalary)
            "
          >
            <span v-if="scope.row.moneyK16"> {{ scope.row.moneyK16 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="paySlipNameKT.nameK17 !== 'Khoản 17'"
          align="right"
          width="115"
          :label="paySlipNameKT.nameK17"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameExpenses(paySlipNameKT.nameK17)" style="cursor: pointer;">{{
                paySlipNameKT.nameK17
              }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.statusSalary) !=='Chưa có phiếu lương'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.statusSalary)
            "
          >
            <span v-if="scope.row.moneyK17"> {{ scope.row.moneyK17 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="paySlipNameKT.nameK18 !== 'Khoản 18'"
          align="right"
          width="115"
          :label="paySlipNameKT.nameK18"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameExpenses(paySlipNameKT.nameK18)" style="cursor: pointer;">{{
                paySlipNameKT.nameK18
              }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.statusSalary) !=='Chưa có phiếu lương'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.statusSalary)
            "
          >
            <span v-if="scope.row.moneyK18"> {{ scope.row.moneyK18 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="paySlipNameKT.nameK19 !== 'Khoản 19'"
          align="right"
          width="115"
          :label="paySlipNameKT.nameK19"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameExpenses(paySlipNameKT.nameK19)" style="cursor: pointer;">{{
                paySlipNameKT.nameK19
              }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.statusSalary) !=='Chưa có phiếu lương'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.statusSalary)
            "
          >
            <span v-if="scope.row.moneyK19"> {{ scope.row.moneyK19 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          v-if="paySlipNameKT.nameK20 !== 'Khoản 20'"
          align="right"
          width="115"
          :label="paySlipNameKT.nameK20"
      >
        <template slot="header" slot-scope="scope">
          <el-tooltip :content="scope.column.label" placement="top">
            <span @click="changeNameExpenses(paySlipNameKT.nameK20)" style="cursor: pointer;">{{
                paySlipNameKT.nameK20
              }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
              v-if="getStatusValue(scope.row.statusSalary) !=='Chưa có phiếu lương'"
              class="click-in-row"
              type="text"
              @click="
              getDetailKT(scope.row, scope.column.label, scope.row.statusSalary)
            "
          >
            <span v-if="scope.row.moneyK20"> {{ scope.row.moneyK20 | formatCurrency }}</span>
            <span v-else>-</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="Tác vụ" fixed="right" align="center" v-if="responseFilterList.length <=10" width="225px">
        <template slot-scope="scope">
          <div style="display: inline-flex">
            <el-button style="display: none"></el-button>
            <!--           giữ nút này do lỗi framework k cập nhật, check được :disable(thanh toán) khi render giao diện-->
            <el-button
                v-if="checkPermission(['salary_new_excel_payment'])"
                size="mini"
                :type="getButtonCashierType(scope.row)"
                @click="orderKidsCashierMethod(scope.row)"
                :disabled="scope.row.statusSalary === null || scope.row.statusSalary === 'Ẩn phiếu lương'|| scope.row.sumMoney === 0"
            >Thanh toán
            </el-button>

            <el-button
                type="success"
                size="mini"
                @click="orderKidsHistoryMethod(scope.row)"
                :disabled="
              scope.row.statusSalary === null|| scope.row.sumMoney === 0
            "
            >
              Lịch sử
            </el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Tác vụ" fixed="right" align="center" v-else width="208px">
        <template slot-scope="scope">
          <div style=" display: inline-flex">
            <el-button style="display: none"></el-button>
            <!--           giữ nút này do lỗi framework k cập nhật, check được :disable(thanh toán) khi render giao diện-->
            <el-button
                v-if="checkPermission(['salary_new_excel_payment'])"
                size="mini"
                :type="getButtonCashierType(scope.row)"
                @click="orderKidsCashierMethod(scope.row)"
                :disabled="scope.row.statusSalary === null || scope.row.statusSalary === 'Ẩn phiếu lương'|| scope.row.sumMoney === 0"
            >Thanh toán
            </el-button>

            <el-button
                type="success"
                size="mini"
                @click="orderKidsHistoryMethod(scope.row)"
                :disabled="
              scope.row.statusSalary === null|| scope.row.sumMoney === 0
            "
            >
              Lịch sử
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div>
      <!--      Mản tồn phiếu lương-->
      <InventoryOrderEmployeeSalary
          :isOpenInventoryOrder="showInventoryOrder"
          @closeInventoryOrder="closeInventoryOrderDialog()"
          ref="InventoryOrderEmployeeSalary"
      />
      <!--      Màn thông tin bổ sung-->
      <ExcelEmployeeSalaryDetail
          :isOpenEmployeeDetailDialog="isOpenEmployeeDetailDialog"
          :kids-detail-data="kidDetailData"
          @closeDetailKid="handleCloseDetailKid"
          ref="ExcelEmployeeSalaryDetail"
      />
      <!--      Màn điều chỉnh tiền đã chi-->
      <CollectedMoneyEmployeeSalary
          v-if="checkPermission(['fees_2_excel_cash_payment'])"
          :isOpenCollectedMoney="showCollectedMoney"
          @closeCollectedMoney="closeCollectedMoneyDialog()"
          ref="CollectedMoneyEmployeeSalary"
      />
      <!--      Chi tiết khoản chi-->
      <DetailExpensesEmployeeSalary
          :isOpenDetailKT="detailKT"
          @closeDetailKT="closeDetailKTDialog()"
          ref="DetailExpensesEmployeeSalary"
      />
      <!--      Màn lịch sử thanh toán-->
      <HistoryExpensesEmployeeSalary
          :isOpenHistoryDialog="showHistoryDialog"
          @closeHistoryOrderKids="closeOrderKidsHistoryDialog()"
          ref="HistoryExpensesEmployeeSalary"
      />
      <!--      Màn thanh toán-->
      <ExpensesEmployeeSalaryExcelCashier
          :isOpenCashierDialog="showOrderKidsExcelCashierDialog"
          @dialog-close="closeOrderKidsExcelCashierDialog"
          ref="ExpensesEmployeeSalaryExcelCashier"
          :isActivatedAccount="isActivatedAccount"
      />
      <!--      Màn thay đổi tên khoản chi-->
      <ChangeNameExpenses
          v-if="checkPermission(['fees_2_excel_cash_payment'])"
          :isOpenChangeNameExpensesDialog="modifierLesRevenus"
          :nameExpenses="nameExpenses"
          :paySlipNameKT="paySlipNameKT"
          :checkDelete="checkDelete"
          @closeChangeNameExpensesDialog="closeChangeNameExpensesDialog"
      />

    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import tableValidateMixin from "@/global/mixin/tableValidate.mixin";
import countColumnActionMixin from "@/global/mixinEmployee/countColumnAction.mixin";
import checkPermission from "@/utils/permission";
import ExcelEmployeeSalaryDetail
  from "@/components/FinanceVer2/employeeSalary/employeeSalaryTableExcelDialog/ExcelEmployeeSalaryDetail.vue";
import CollectedMoneyEmployeeSalary
  from "@/components/FinanceVer2/employeeSalary/employeeSalaryTableExcelDialog/CollectedMoneyEmployeeSalary.vue";
import DetailExpensesEmployeeSalary
  from "@/components/FinanceVer2/employeeSalary/employeeSalaryTableExcelDialog/DetailExpensesEmployeeSalary.vue";
import HistoryExpensesEmployeeSalary
  from "@/components/FinanceVer2/employeeSalary/employeeSalaryTableExcelDialog/HistoryExpensesEmployeeSalary.vue";
import ChangeNameExpenses
  from "@/components/FinanceVer2/employeeSalary/employeeSalaryTableExcelDialog/ChangeNameExpenses.vue";
import ExpensesEmployeeSalaryExcelCashier
  from "@/components/FinanceVer2/employeeSalary/employeeSalaryTableExcelDialog/ExpensesEmployeeSalaryExcelCashier.vue";
import NewSalaryService from "@/services/EmployeeService/NewSalaryService";
import InventoryOrderEmployeeSalary
  from "@/components/FinanceVer2/employeeSalary/employeeSalaryTableExcelDialog/InventoryOrderEmployeeSalary.vue";

export default {
  name: "EmployeeSalaryExcelTable",
  components: {
    InventoryOrderEmployeeSalary,
    ExpensesEmployeeSalaryExcelCashier,
    ChangeNameExpenses,
    HistoryExpensesEmployeeSalary,
    DetailExpensesEmployeeSalary,
    CollectedMoneyEmployeeSalary,
    ExcelEmployeeSalaryDetail,
  },

  computed: {
    ...mapState("employeeSalaryNew", [
      "responseFilterList",
      "loadingData",
      "paySlipNameKT",
      "dataSearch","checkResponse"
    ]),

  },

  mixins: [tableValidateMixin, countColumnActionMixin],
  data() {
    return {
      // Hiển thị/ Bỏ hiển thị phiếu lương
      showOrHidden: {
        date: "",
        idInfo: "",
        spendTurn: "",
        statusSalary: false,
      },
      // dialog field
      kidDetailData: {},
      isOpenEmployeeDetailDialog: false,
      showHistoryDialog: false,
      showOrderKidsExcelCashierDialog: false,
      multipleSelection: [],
      textTable: this.$tableEmpty,
      showCollectedMoney: false,
      showInventoryOrder: false,
      detailKT: false,
      modifierLesRevenus: false,
      nameExpenses: "",
      checkDelete: true,
      isActivatedAccount: false
    };
  },
  watch:{
    checkResponse(){
      if (this.checkResponse === false){
        this.textTable = this.$tableEmpty
      }
    },
  },
  methods: {
    checkPermission,
    getButtonCashierType(row){
      if (row.statusSalary === "Chi đủ 100%" || row.statusSalary === "Chi đủ thừa" || row.statusSalary === "Chi đủ thiếu"){
        return 'info';
      }
      return 'primary';
    },
    changeNameExpenses(name) {
      this.checkDelete = true
      this.modifierLesRevenus = true;
      this.nameExpenses = name;
      const listNameKC = []
      for (let i = 1; i < 10; i++) {
        if (this.paySlipNameKT[`nameK0${i}`] !== `Khoản ${i}`) {
          listNameKC.push(this.paySlipNameKT[`nameK0${i}`])
        }
      }
      for (let i = 10; i <= 20; i++) {
        if (this.paySlipNameKT[`nameK${i}`] !== `Khoản ${i}`) {
          listNameKC.push(this.paySlipNameKT[`nameK${i}`])
        }
      }
      if (name === listNameKC[listNameKC.length - 1]) {
        this.checkDelete = false
      }
    },

    // Hiển thị/bỏ hiển thị
    handelShowOrHidden(row) {
      const date = this.$funcCommon.formatMY(this.dataSearch.year, this.dataSearch.month)
      this.showOrHidden.date = date;
      this.showOrHidden.idInfo = row.id;
      this.showOrHidden.spendTurn = this.dataSearch.spendTurn;
      if (row.orderHidden) {
        this.showOrHidden.statusSalary = true
        this.$store.commit("employeeSalaryNew/set_show_app_message", row.fullName);
      } else {
        this.showOrHidden.statusSalary = false
        this.$store.commit("employeeSalaryNew/set_hidden_app_message", row.fullName);

      }
      NewSalaryService
          .showOrHiddenEmployeeSalary(this.showOrHidden)
          .then((res) => {
            return this.$message({
              showClose: true,
              message: res.data.message,
              type: "success",
            });

          })
          .catch(() => {
            return this.$message({
              showClose: true,
              message: `Lỗi hệ thống `,
              type: "danger",
            });
          });
    },
    // dialog hiển thị thông tin bổ sung
    handleOpenEmployeeDetail(row) {
      this.isOpenEmployeeDetailDialog = true;
      const date = this.$funcCommon.formatMY(this.dataSearch.year, this.dataSearch.month)
      this.$refs.ExcelEmployeeSalaryDetail.getInfoByMonthAndTurn(
          this.dataSearch.month,
          row.id,
          this.dataSearch.spendTurn,
          date
      );
    },
    handleCloseDetailKid(close) {
      this.isOpenEmployeeDetailDialog = close;
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
    },
    closeChangeNameExpensesDialog(close) {
      this.modifierLesRevenus = close;
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
    },
    /**
     * Mở dialog lưu lịch sử thanh toán
     * @param row
     */
    orderKidsCashierMethod(row) {
      if (this.dataSearch.spendTurn === 0) {
        this.$message({
          message: "Bạn chưa chọn đợt thu",
          type: "error",
        });
      } else {
        let idInfo = row.id;
        let fullName = row.fullName;
        let paySlipCode = row.paySlipCode;
        let paySlipCodeNotify = row.paySlipCodeNotify;
        let spendTurn = this.dataSearch.spendTurn;
        let month = this.dataSearch.month;
        let year = this.dataSearch.year;
        this.showOrderKidsExcelCashierDialog = true;
        this.$refs.ExpensesEmployeeSalaryExcelCashier.getDataCashierSalaryInitial(
            idInfo,
            fullName,
            paySlipCode,
            paySlipCodeNotify, spendTurn, month, year
        );
        this.isActivatedAccount = row.phone !== "";
      }
    },

    /**
     * Mở dialog hiển thị lịch sử thanh toán
     * @param row
     */
    orderKidsHistoryMethod(row) {
      this.showHistoryDialog = true;
      const date = this.$funcCommon.formatMY(this.dataSearch.year, this.dataSearch.month)
      this.$refs.HistoryExpensesEmployeeSalary.getOrderHistoryInitial(
          row.id,
          date,
          this.dataSearch.spendTurn,
      );
    },

    closeOrderKidsHistoryDialog() {
      this.showHistoryDialog = false;
      this.$store.commit('employeeSalaryNew/get_status_excel')
    },

    /**
     * Mở dialog hiển thị tiền đã thu
     */
    collectedMoneyMethod(row) {
      const date = this.$funcCommon.formatMY(this.dataSearch.year, this.dataSearch.month)
      this.showCollectedMoney = !this.showCollectedMoney;
      this.$refs.CollectedMoneyEmployeeSalary.getCollectedMoney(
          row.id,
          this.dataSearch.spendTurn,
          row.statusSalary,
          date,
      );
    },

    closeCollectedMoneyDialog(boolean) {
      this.showCollectedMoney = boolean;
    },

    /**
     * Mở dialog hiển thị tồn phiếu lương
     */
    inventoryOrder(row) {
      this.showInventoryOrder = !this.showInventoryOrder;
      this.$refs.InventoryOrderEmployeeSalary.getInventoryOrder(
          row.fullName,
          row.code,
          row.id,
          row.paySlipCodeNotify
      );
    },

    closeInventoryOrderDialog(boolean) {
      this.showInventoryOrder = boolean;
      this.$store.commit('employeeSalaryNew/get_status_excel')

    },

    /**
     * Mở dialog hiển thị chi tiết khoản thu
     */
    getDetailKT(row, nameKC, statusOrder) {
      this.detailKT = !this.detailKT;
      const date = this.$funcCommon.formatMY(this.dataSearch.year, this.dataSearch.month)
      this.$refs.DetailExpensesEmployeeSalary.showDetailKT(
          row.id,
          this.dataSearch.spendTurn,
          nameKC,
          statusOrder,
          date
      );
    },

    closeDetailKTDialog(boolean) {
      this.detailKT = boolean;
    },

    closeOrderKidsExcelCashierDialog() {
      this.showOrderKidsExcelCashierDialog = false;
      this.$store.commit('employeeSalaryNew/get_status_excel')
    },
    tableHeaderColor(column) {
      this.countNonEmptyAttributes(this.paySlipNameKT)
      if (14 < column.columnIndex && column.columnIndex <= (14 + this.countColumnAttributes)) {
        return "background-color: #ea580cb3;color: #fff;font-weight: light;"
      }
      return "background-color: #78a5e7;color: #fff;font-weight: light;";
    },
    // chọn nhiều truonwgf
    handleSelectionChange(val) {
      this.multipleSelection = [...val];
      this.listNullBillKids = [];
      this.listHiddenOrder = [];
      this.listShowForApp = [];
      this.listHiddenForApp = [];
      this.multipleSelection.forEach((row) => {
        if (this.validateNullBillEmployee(row)) {
          this.listNullBillKids = [...this.listNullBillKids, row.fullName];
        }
        if (this.validateHiddenOrderEmployee(row)) {
          this.listHiddenOrder = [...this.listHiddenOrder, row.fullName]; // lấy danh sách bị ẩn
        }
        if (row.orderHidden) {
          this.listShowForApp = [...this.listShowForApp, row.fullName]// lấy danh sách hiển thị lên app
        }
        if (!row.orderHidden) {
          this.listHiddenForApp = [...this.listHiddenForApp, row.fullName]// lấy danh sách không hiện thị lên app
        }
      });
      // quản lý danh sách null
      this.$store.commit(
          "employeeSalaryNew/set_hidden_order_kids",
          this.listHiddenOrder
      );
      this.$store.commit(
          "employeeSalaryNew/set_show_for_app",
          this.listShowForApp
      );
      this.$store.commit(
          "employeeSalaryNew/set_hidden_for_app",
          this.listHiddenForApp
      );
      this.$store.commit(
          "employeeSalaryNew/set_null_bill_kids",
          this.listNullBillKids
      );
      // eslint-disable-next-line no-unused-vars
      let multipleSelectionCopy = this.multipleSelection;
      let idInfoList = [];
      multipleSelectionCopy = multipleSelectionCopy.map((item) => {
        idInfoList = [...idInfoList, item.id];
      });
      this.$store.commit("employeeSalaryNew/set_id_employee_list", idInfoList);
    },

    getStatusValue(status) {
      return status !== null ? status : "Chưa có phiếu lương";
    },

    getStatusColor(status) {
      if (status === null) {
        return "status-red";
      } else if (status === "Chưa chi 100%" || status === "Chưa chi đủ") {
        return "status-orange";
      } else if (status === "Chi đủ  100%" || status === "Chi thừa") {
        return "status-blue";
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.menu-main {
  min-height: 950px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 210px;
  min-height: 700px;
  font-family: Arial, serif;
}

.router-link {
  text-decoration: none;
}

.el-menu-item {
  background-color: #1f2d3d !important;
}

.el-menu-item:hover {
  background-color: #49575f !important;
}

/deep/ .el-message-box {
  display: inline-block;
  vertical-align: top !important;
  width: 420px;
  padding-bottom: 10px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  font-size: 18px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  text-align: left;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/deep/ .el-message-box__wrapper::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: top !important;
}

.el-menu {
  margin-bottom: 20px;
}

.onekids-title {
  font-weight: bold;
}

/* Định nghĩa các lớp màu sắc */
.status-red {
  color: red;
}

.status-orange {
  color: orange;
}

.status-blue {
  color: blue;
}

/deep/ .el-table td,
/deep/ .el-table th {
  padding: 2px 0;
}

/deep/ .el-table th.el-table__cell {
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #FFF;
}

span.el-tooltip {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

</style>
<template>
  <div class="content">
    <EmployeeSalarySearch>
      <EmployeeSalaryExcelTable/>
    </EmployeeSalarySearch>
  </div>

</template>
<script>

import EmployeeSalarySearch from "@/components/FinanceVer2/employeeSalary/EmployeeSalarySearch.vue";
import EmployeeSalaryExcelTable from "@/components/FinanceVer2/employeeSalary/EmployeeSalaryExcelTable.vue";

export default {
  components: {EmployeeSalaryExcelTable, EmployeeSalarySearch}
}
</script>

<style lang="scss" scoped>

</style>

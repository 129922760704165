import newSalaryService from "@/services/EmployeeService/NewSalaryService";

export default {

    methods: {
        // Gửi SMS
        sendSMS(idInfoList, date, spendTurn) {
            if (idInfoList === "") {
                this.$message({
                    type: "error",
                    message: "Chưa có nhân viên nào được chọn.",
                });
            } else {
                const idInfoListConvert = idInfoList.split(",").map(Number);
                this.$confirm(
                    "Số lượng tin nhắn sẽ bị trừ vào quỹ tin của NHÀ TRƯỜNG! <br> Bạn có chắc chắn muốn gửi không?",
                    {
                        distinguishCancelAndClose: true,
                        confirmButtonText: "Có",
                        closeOnClickModal: false,
                        cancelButtonText: "Không",
                        dangerouslyUseHTMLString: true
                    }).then(() => {
                    const request = {
                        date: date,
                        idInfoList: idInfoListConvert,
                        spendTurn: spendTurn
                    };
                    newSalaryService.sendSMS(request).then((res) => {

                        if (res.data.message === "Chưa kích hoạt") {
                            this.$message({
                                message: "Nhân viên chưa kích hoạt tài khoản",
                                type: "error",
                                duration: 5000
                            });

                        } else {
                            this.$message({
                                message: res.data.message,
                                type: "success",
                            });
                        }
                    });
                })

            }
        },
    }
}
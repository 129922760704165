<template>
  <div>
    <el-dialog
        title="Danh sách nhân viên sai thông tin"
        :visible.sync="dialogFailInformation"
        width="1000px"
        :before-close="handleClose"
        :close-on-click-modal="false">
      <el-table
          :empty-text="textTable"
          ref="multipleTable"
          :data="listError"
          :header-cell-style="tableHeaderColor"
          @selection-change="handleSelectionChange"
          border
          :max-height="400"
      >
        <el-table-column
            type="index"
            fixed
            label="STT"
            width="60"
            align="center"
        ></el-table-column>
        <el-table-column
            width="150"
            fixed
            align="center"
            label="Họ và tên"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.fullName==null">
              <span style="color: red">Không tìm thấy học sinh</span>
            </div>
            <div v-else>
              <span style="color: cornflowerblue">{{ scope.row.fullName }}</span>
              <br>
              <span style="color: red">{{ scope.row.fullNameFail }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="Mã nhân viên"
            width="150"
            align="center"
            prop="name"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.code === null">
              <span style="color: red">{{ scope.row.codeFail }}</span>
            </div>
            <div v-else>
              <span style="color: cornflowerblue">{{ scope.row.code }}</span>
              <br>
              <span style="color: red">{{ scope.row.codeFail }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="Ngày sinh"
            width="150"
            align="center"
            prop="name"
        >
          <template slot-scope="scope">
            <span style="color: cornflowerblue">{{ scope.row.birthDay }}</span>
            <br>
            <span style="color: red">{{ scope.row.birthDayFail }}</span>

          </template>
        </el-table-column>
        <el-table-column
            label="Số điện thoại"
            width="150"
            align="center"
            prop="name"
        >
          <template slot-scope="scope">
            <span style="color: cornflowerblue">{{ scope.row.phone }}</span>
            <br>
            <span style="color: red">{{ scope.row.phoneFail }}</span>

          </template>
        </el-table-column>
        <el-table-column
            label="Số tài khoản"
            width="150"
            align="center"
            prop="name"
        >
          <template slot-scope="scope">
            <span style="color: cornflowerblue">{{ scope.row.bankAccountNumber }}</span>
            <br>
            <span style="color: red">{{ scope.row.bankAccountNumberFail }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Tên ngân hàng"
            width="150"
            align="center"
            prop="name"
        >
          <template slot-scope="scope">
            <span style="color: cornflowerblue">{{ scope.row.nameBank }}</span>
            <br>
            <span style="color: red">{{ scope.row.nameBankFail }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="custom_button_error">
        <div>
          <span style="color: red; margin-right: 320px">* Mời bạn nhấn "Tiếp tục" để hiển thị danh sách nhân viên đúng thông tin</span>
        </div>
        <div>
          <el-button v-if="isContinue" type="primary" @click="handleClickContinue" :loading="loadingImport">Tiếp tục
          </el-button>
          <el-button type="danger" @click="handleClose"
          ><i class="el-icon-circle-close"/> Đóng
          </el-button>
        </div>
      </div>
    </el-dialog>
    <div>
      <InformationEmployeeSalary
          :dialogFormVisible="dialogFormVisible"
          :listConfirm="listConfirm"
          @close="handleClose"
      />
    </div>
  </div>
</template>

<script>
import InformationEmployeeSalary
  from "@/components/FinanceVer2/employeeSalary/employeeSalarySearchDialog/InformationEmployeeSalary.vue";

export default {
  name: "FailInformationEmployeeSalary",
  components: {InformationEmployeeSalary},
  props: {
    dialogFailInformation: {
      type: Boolean,
      default: () => false,
    },
    listError: {
      type: Array,
      default: () => [],
    },
    listConfirm: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      isContinue: true,
      loadingImport: false,
      dialogFormVisible: false,
      multipleSelection: [],
      textTable: this.$tableEmpty,
    }
  },
  methods: {
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: semi-bold;";
    },
    // Chuyển sang màn thông tin chi tiết
    handleClickContinue() {
      // if (this.listConfirm.length === 0) {
      //   this.$message({
      //     message: "Tất cả học sinh đều sai thông tin, không thể tạo hóa đơn",
      //     type: "error",
      //     duration: 5000
      //   });
      //   this.loadingImport = false
      //   this.isContinue = false;
      // } else {
      this.dialogFormVisible = true
      this.loadingImport = false
      // }
    },

    /**
     *  Phương thức được gọi mỗi khi có sự thay đổi trong các hàng được chọn
     * @param val
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    handleClose() {
      this.dialogFormVisible = false
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
      this.$emit("closeFailInformation", false);
    }
  },
}
</script>

<style lang="scss" scoped>
.button_method {
  text-align: left;
}

.input-common {
  margin-right: 5px;
}

.excel-upload-input {
  display: none;
}

.overwrite {
  margin-top: 5px;
}

.excel_upload {
  float: left;
  margin-right: 10px;
}

/deep/ .el-form-item__label {
  text-align: left;
}

.custom_button {
  display: flex;
  justify-content: right;
  margin-right: 10px;
  margin-top: 10px;
}

.custom_button_error {
  display: flex;
  justify-content: right;
  margin-top: 20px;
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "700px",
        top: "50px",
        visible: _vm.isOpenEmployeeDetailDialog,
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.isOpenEmployeeDetailDialog = $event
        },
        click: _vm.handleOutsideClick,
      },
    },
    [
      _c("div", [_c("h2", [_vm._v("Thông tin bổ sung")])]),
      _c(
        "h4",
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 12 } }, [_vm._v("Tiêu đề")]),
              _c("el-col", { attrs: { span: 3 } }, [
                _vm._v(_vm._s("Tháng " + _vm.dataSearch.month)),
              ]),
              _c(
                "el-col",
                { staticStyle: { "margin-left": "13px" }, attrs: { span: 4 } },
                [_vm._v(_vm._s(_vm.monthBefore))]
              ),
              _c(
                "el-col",
                { staticStyle: { "margin-left": "10px" }, attrs: { span: 2 } },
                [_vm._v("Excel")]
              ),
              _c(
                "el-col",
                { staticStyle: { float: "right" }, attrs: { span: 2 } },
                [_vm._v("Đơn vị")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [_vm._v("Làm việc T2-T6")]),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "20px" }, attrs: { span: 3 } },
            [_vm._v(_vm._s(_vm.updateFields.workT2t6Total))]
          ),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "7px" }, attrs: { span: 4 } },
            [_vm._v(_vm._s(_vm.updateFields.workT2t6TotalMonthBefore))]
          ),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { span: 2 } }, [
                _vm._v(_vm._s(_vm.updateFields.workT2t6)),
              ])
            : _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", type: "number" },
                    model: {
                      value: _vm.updateFields.workT2t6,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "workT2t6", $$v)
                      },
                      expression: "updateFields.workT2t6",
                    },
                  }),
                ],
                1
              ),
          _c(
            "el-col",
            { staticStyle: { float: "right" }, attrs: { span: 2 } },
            [_vm._v("Ngày")]
          ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [_vm._v("Làm việc T7")]),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "20px" }, attrs: { span: 3 } },
            [_vm._v(_vm._s(_vm.updateFields.workT7Total))]
          ),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "7px" }, attrs: { span: 4 } },
            [_vm._v(_vm._s(_vm.updateFields.workT7TotalMonthBefore))]
          ),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { span: 2 } }, [
                _vm._v(_vm._s(_vm.updateFields.workT7)),
              ])
            : _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", type: "number" },
                    model: {
                      value: _vm.updateFields.workT7,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "workT7", $$v)
                      },
                      expression: "updateFields.workT7",
                    },
                  }),
                ],
                1
              ),
          _c(
            "el-col",
            { staticStyle: { float: "right" }, attrs: { span: 2 } },
            [_vm._v("Ngày")]
          ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [_vm._v("Làm việc CN")]),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "20px" }, attrs: { span: 3 } },
            [_vm._v(_vm._s(_vm.updateFields.workCnTotal))]
          ),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "7px" }, attrs: { span: 4 } },
            [_vm._v(_vm._s(_vm.updateFields.workCnTotalMonthBefore))]
          ),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { span: 2 } }, [
                _vm._v(_vm._s(_vm.updateFields.workCn)),
              ])
            : _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", type: "number" },
                    model: {
                      value: _vm.updateFields.workCn,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "workCn", $$v)
                      },
                      expression: "updateFields.workCn",
                    },
                  }),
                ],
                1
              ),
          _c(
            "el-col",
            { staticStyle: { float: "right" }, attrs: { span: 2 } },
            [_vm._v("Ngày")]
          ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _vm._v("Nghỉ có phép (T2-T6)"),
          ]),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "20px" }, attrs: { span: 3 } },
            [_vm._v(_vm._s(_vm.updateFields.absentCpT2t6Total))]
          ),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "7px" }, attrs: { span: 4 } },
            [_vm._v(_vm._s(_vm.updateFields.absentCpT2t6TotalMonthBefore))]
          ),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { span: 2 } }, [
                _vm._v(_vm._s(_vm.updateFields.absentCpT2t6) + "\n    "),
              ])
            : _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", type: "number" },
                    model: {
                      value: _vm.updateFields.absentCpT2t6,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "absentCpT2t6", $$v)
                      },
                      expression: "updateFields.absentCpT2t6",
                    },
                  }),
                ],
                1
              ),
          _c(
            "el-col",
            { staticStyle: { float: "right" }, attrs: { span: 2 } },
            [_vm._v("Ngày")]
          ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _vm._v("Nghỉ không phép (T2-T6)"),
          ]),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "20px" }, attrs: { span: 3 } },
            [_vm._v(_vm._s(_vm.updateFields.absentKpT2t6Total))]
          ),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "7px" }, attrs: { span: 4 } },
            [_vm._v(_vm._s(_vm.updateFields.absentKpT2t6TotalMonthBefore))]
          ),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { span: 2 } }, [
                _vm._v(_vm._s(_vm.updateFields.absentKpT2t6) + "\n    "),
              ])
            : _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", type: "number" },
                    model: {
                      value: _vm.updateFields.absentKpT2t6,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "absentKpT2t6", $$v)
                      },
                      expression: "updateFields.absentKpT2t6",
                    },
                  }),
                ],
                1
              ),
          _c(
            "el-col",
            { staticStyle: { float: "right" }, attrs: { span: 2 } },
            [_vm._v("Ngày")]
          ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [_vm._v("Nghỉ có phép (T7)")]),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "20px" }, attrs: { span: 3 } },
            [_vm._v(_vm._s(_vm.updateFields.absentCpT7Total))]
          ),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "7px" }, attrs: { span: 4 } },
            [_vm._v(_vm._s(_vm.updateFields.absentCpT7TotalMonthBefore))]
          ),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { span: 2 } }, [
                _vm._v(_vm._s(_vm.updateFields.absentCpT7) + "\n    "),
              ])
            : _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", type: "number" },
                    model: {
                      value: _vm.updateFields.absentCpT7,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "absentCpT7", $$v)
                      },
                      expression: "updateFields.absentCpT7",
                    },
                  }),
                ],
                1
              ),
          _c(
            "el-col",
            { staticStyle: { float: "right" }, attrs: { span: 2 } },
            [_vm._v("Ngày")]
          ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _vm._v("Nghỉ không phép (T7)"),
          ]),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "20px" }, attrs: { span: 3 } },
            [_vm._v(_vm._s(_vm.updateFields.absentKpT7Total))]
          ),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "7px" }, attrs: { span: 4 } },
            [_vm._v(_vm._s(_vm.updateFields.absentKpT7TotalMonthBefore))]
          ),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { span: 2 } }, [
                _vm._v(_vm._s(_vm.updateFields.absentKpT7) + "\n    "),
              ])
            : _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", type: "number" },
                    model: {
                      value: _vm.updateFields.absentKpT7,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "absentKpT7", $$v)
                      },
                      expression: "updateFields.absentKpT7",
                    },
                  }),
                ],
                1
              ),
          _c(
            "el-col",
            { staticStyle: { float: "right" }, attrs: { span: 2 } },
            [_vm._v("Ngày")]
          ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [_vm._v("Ca tối")]),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "20px" }, attrs: { span: 3 } },
            [_vm._v(_vm._s(_vm.updateFields.quantityEveningTotal))]
          ),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "7px" }, attrs: { span: 4 } },
            [_vm._v(_vm._s(_vm.updateFields.quantityEveningTotalMonthBefore))]
          ),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { span: 2 } }, [
                _vm._v(_vm._s(_vm.updateFields.quantityEvening) + "\n    "),
              ])
            : _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", type: "number" },
                    model: {
                      value: _vm.updateFields.quantityEvening,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "quantityEvening", $$v)
                      },
                      expression: "updateFields.quantityEvening",
                    },
                  }),
                ],
                1
              ),
          _c(
            "el-col",
            { staticStyle: { float: "right" }, attrs: { span: 2 } },
            [_vm._v("Buổi")]
          ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [_vm._v("Trông muộn")]),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "20px" }, attrs: { span: 3 } },
            [_vm._v(_vm._s(_vm.updateFields.quantityTakeCareLateTotal))]
          ),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "7px" }, attrs: { span: 4 } },
            [
              _vm._v(
                _vm._s(_vm.updateFields.quantityTakeCareLateTotalMonthBefore)
              ),
            ]
          ),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { span: 2 } }, [
                _vm._v(
                  _vm._s(_vm.updateFields.quantityTakeCareLate) + "\n    "
                ),
              ])
            : _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", type: "number" },
                    model: {
                      value: _vm.updateFields.quantityTakeCareLate,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "quantityTakeCareLate", $$v)
                      },
                      expression: "updateFields.quantityTakeCareLate",
                    },
                  }),
                ],
                1
              ),
          _c(
            "el-col",
            { staticStyle: { float: "right" }, attrs: { span: 2 } },
            [_vm._v("Lần")]
          ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [_vm._v("Thêm giờ")]),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "20px" }, attrs: { span: 3 } },
            [_vm._v(_vm._s(_vm.updateFields.quantityOvertimeTotal))]
          ),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "7px" }, attrs: { span: 4 } },
            [_vm._v(_vm._s(_vm.updateFields.quantityOvertimeTotalMonthBefore))]
          ),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { span: 2 } }, [
                _vm._v(_vm._s(_vm.updateFields.quantityOvertime) + "\n    "),
              ])
            : _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", type: "number" },
                    model: {
                      value: _vm.updateFields.quantityOvertime,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "quantityOvertime", $$v)
                      },
                      expression: "updateFields.quantityOvertime",
                    },
                  }),
                ],
                1
              ),
          _c(
            "el-col",
            { staticStyle: { float: "right" }, attrs: { span: 2 } },
            [_vm._v("Giờ")]
          ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [_vm._v("Đi muộn")]),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "20px" }, attrs: { span: 3 } },
            [_vm._v(_vm._s(_vm.updateFields.quantityComeLateTotal))]
          ),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "7px" }, attrs: { span: 4 } },
            [_vm._v(_vm._s(_vm.updateFields.quantityComeLateTotalMonthBefore))]
          ),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { span: 2 } }, [
                _vm._v(_vm._s(_vm.updateFields.quantityComeLate)),
              ])
            : _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", type: "number" },
                    model: {
                      value: _vm.updateFields.quantityComeLate,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "quantityComeLate", $$v)
                      },
                      expression: "updateFields.quantityComeLate",
                    },
                  }),
                ],
                1
              ),
          _c(
            "el-col",
            { staticStyle: { float: "right" }, attrs: { span: 2 } },
            [_vm._v("Giờ")]
          ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [_vm._v("Về sớm")]),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "20px" }, attrs: { span: 3 } },
            [_vm._v(_vm._s(_vm.updateFields.quantityBackSoonTotal))]
          ),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "7px" }, attrs: { span: 4 } },
            [_vm._v(_vm._s(_vm.updateFields.quantityBackSoonTotalMonthBefore))]
          ),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { span: 2 } }, [
                _vm._v(_vm._s(_vm.updateFields.quantityBackSoon) + "\n    "),
              ])
            : _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", type: "number" },
                    model: {
                      value: _vm.updateFields.quantityBackSoon,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "quantityBackSoon", $$v)
                      },
                      expression: "updateFields.quantityBackSoon",
                    },
                  }),
                ],
                1
              ),
          _c(
            "el-col",
            { staticStyle: { float: "right" }, attrs: { span: 2 } },
            [_vm._v("Giờ")]
          ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _vm._v("Số ngày theo lịch hàng tháng"),
          ]),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "20px" }, attrs: { span: 3 } },
            [_vm._v(_vm._s(_vm.updateFields.quantityDateTotal))]
          ),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "7px" }, attrs: { span: 4 } },
            [_vm._v(_vm._s(_vm.updateFields.quantityDateTotalMonthBefore))]
          ),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { span: 2 } }, [
                _vm._v(_vm._s(_vm.updateFields.quantityDate) + "\n    "),
              ])
            : _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", type: "number" },
                    model: {
                      value: _vm.updateFields.quantityDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "quantityDate", $$v)
                      },
                      expression: "updateFields.quantityDate",
                    },
                  }),
                ],
                1
              ),
          _c(
            "el-col",
            { staticStyle: { float: "right" }, attrs: { span: 2 } },
            [_vm._v("Ngày")]
          ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 14 } }, [
            _vm._v("(1) Ghi chú nhà trường:"),
          ]),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { offset: 1 } }, [
                _c("i", { staticStyle: { "white-space": "pre-line" } }, [
                  _vm._v(_vm._s(_vm.updateFields.noteExcel1)),
                ]),
              ])
            : _c(
                "el-col",
                { attrs: { offset: 1, span: 23 } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "mini",
                      placeholder: "Nhập tại đây",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.updateFields.noteExcel1,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "noteExcel1", $$v)
                      },
                      expression: "updateFields.noteExcel1",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 18 } }, [
            _vm._v("(2) Ghi chú trên phiếu lương:"),
          ]),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { offset: 1 } }, [
                _c("i", { staticStyle: { "white-space": "pre-line" } }, [
                  _vm._v(_vm._s(_vm.updateFields.noteExcel2)),
                ]),
              ])
            : _c(
                "el-col",
                { attrs: { offset: 1, span: 23 } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "mini",
                      placeholder: "Nhập tại đây",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.updateFields.noteExcel2,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "noteExcel2", $$v)
                      },
                      expression: "updateFields.noteExcel2",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 14 } }, [_vm._v("(3) Ghi chú khác:")]),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { offset: 1 } }, [
                _c("i", { staticStyle: { "white-space": "pre-line" } }, [
                  _vm._v(_vm._s(_vm.updateFields.noteExcel3)),
                ]),
              ])
            : _c(
                "el-col",
                { attrs: { offset: 1, span: 23 } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "mini",
                      placeholder: "Nhập tại đây",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.updateFields.noteExcel3,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "noteExcel3", $$v)
                      },
                      expression: "updateFields.noteExcel3",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 14 } }, [_vm._v("Mô tả đợt chi :")]),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { offset: 1 } }, [
                _c("i", { staticStyle: { "white-space": "pre-line" } }, [
                  _vm._v(_vm._s(_vm.updateFields.descriptionTurn)),
                ]),
              ])
            : _c(
                "el-col",
                { attrs: { offset: 1, span: 23 } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "mini",
                      placeholder: "Nhập tại đây",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.updateFields.descriptionTurn,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "descriptionTurn", $$v)
                      },
                      expression: "updateFields.descriptionTurn",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isEditing,
                  expression: "isEditing",
                },
              ],
              attrs: { size: "medium", type: "primary" },
              on: { click: _vm.saveInfoAttendance },
            },
            [_vm._v("Cập nhật")]
          ),
          this.statusOrder !== "Ẩn hóa đơn"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isEditing,
                      expression: "!isEditing",
                    },
                  ],
                  attrs: { size: "medium", type: "success" },
                  on: { click: _vm.handleUpdate },
                },
                [_vm._v("Sửa\n    ")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { size: "medium", type: "danger" },
              on: { click: _vm.handleClose },
            },
            [_c("i", { staticClass: "el-icon-circle-close" }), _vm._v(" Đóng")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Danh sách nhân viên sai thông tin",
            visible: _vm.dialogFailInformation,
            width: "1000px",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFailInformation = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: {
                "empty-text": _vm.textTable,
                data: _vm.listError,
                "header-cell-style": _vm.tableHeaderColor,
                border: "",
                "max-height": 400,
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  fixed: "",
                  label: "STT",
                  width: "60",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  width: "150",
                  fixed: "",
                  align: "center",
                  label: "Họ và tên",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.fullName == null
                          ? _c("div", [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("Không tìm thấy học sinh"),
                              ]),
                            ])
                          : _c("div", [
                              _c(
                                "span",
                                { staticStyle: { color: "cornflowerblue" } },
                                [_vm._v(_vm._s(scope.row.fullName))]
                              ),
                              _c("br"),
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v(_vm._s(scope.row.fullNameFail)),
                              ]),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Mã nhân viên",
                  width: "150",
                  align: "center",
                  prop: "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.code === null
                          ? _c("div", [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v(_vm._s(scope.row.codeFail)),
                              ]),
                            ])
                          : _c("div", [
                              _c(
                                "span",
                                { staticStyle: { color: "cornflowerblue" } },
                                [_vm._v(_vm._s(scope.row.code))]
                              ),
                              _c("br"),
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v(_vm._s(scope.row.codeFail)),
                              ]),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Ngày sinh",
                  width: "150",
                  align: "center",
                  prop: "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          { staticStyle: { color: "cornflowerblue" } },
                          [_vm._v(_vm._s(scope.row.birthDay))]
                        ),
                        _c("br"),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(scope.row.birthDayFail)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Số điện thoại",
                  width: "150",
                  align: "center",
                  prop: "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          { staticStyle: { color: "cornflowerblue" } },
                          [_vm._v(_vm._s(scope.row.phone))]
                        ),
                        _c("br"),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(scope.row.phoneFail)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Số tài khoản",
                  width: "150",
                  align: "center",
                  prop: "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          { staticStyle: { color: "cornflowerblue" } },
                          [_vm._v(_vm._s(scope.row.bankAccountNumber))]
                        ),
                        _c("br"),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(scope.row.bankAccountNumberFail)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Tên ngân hàng",
                  width: "150",
                  align: "center",
                  prop: "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          { staticStyle: { color: "cornflowerblue" } },
                          [_vm._v(_vm._s(scope.row.nameBank))]
                        ),
                        _c("br"),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(scope.row.nameBankFail)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "custom_button_error" }, [
            _c("div", [
              _c(
                "span",
                { staticStyle: { color: "red", "margin-right": "320px" } },
                [
                  _vm._v(
                    '* Mời bạn nhấn "Tiếp tục" để hiển thị danh sách nhân viên đúng thông tin'
                  ),
                ]
              ),
            ]),
            _c(
              "div",
              [
                _vm.isContinue
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", loading: _vm.loadingImport },
                        on: { click: _vm.handleClickContinue },
                      },
                      [_vm._v("Tiếp tục\n        ")]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  { attrs: { type: "danger" }, on: { click: _vm.handleClose } },
                  [
                    _c("i", { staticClass: "el-icon-circle-close" }),
                    _vm._v(" Đóng\n        "),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("InformationEmployeeSalary", {
            attrs: {
              dialogFormVisible: _vm.dialogFormVisible,
              listConfirm: _vm.listConfirm,
            },
            on: { close: _vm.handleClose },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <el-dialog
        v-if="inventoryList.length !== 0"
        :title="'Tồn phiếu lương: ' + fullName + ' - ' + code "
        :visible.sync="isOpenInventoryOrder"
        width="1510px"
        :before-close="handleClose"
        :close-on-click-modal="false"
        top="5vh"
    >
      <el-table
          ref="multipleTable"
          highlight-current-row
          :data="inventoryList"
          :cell-style="tableRowStyle"
          :header-cell-style="tableHeaderColor"
          border
      >
        <el-table-column
            type="index"
            fixed
            label="STT"
            width="50"
            align="center"
        ></el-table-column>
        <el-table-column
            width="90"
            fixed
            align="center"
            label="Tháng"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.month }}</span>

          </template>
        </el-table-column>
        <el-table-column
            label="Mã hóa đơn"
            width="180"
            align="center"
            prop="name"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.paySlipCode }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Phải chi tháng này"
            width="140"
            prop="name"
            align="right"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.sumMoney | formatCurrency }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Đã chi" align="right" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.spendMoney | formatCurrency }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Tiền mặt" align="right" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.cashMoney | formatCurrency }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Chuyển khoản" align="right" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.transferMoney | formatCurrency }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Thiếu / Thừa tháng trước" align="right" width="130">
          <template slot-scope="scope">
            <span>{{ scope.row.beforeMoney | formatCurrency }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Thiếu / Thừa tháng này" align="right" width="130">
          <template slot-scope="scope">

            <span v-if="scope.row.afterMoney<0">({{ Math.abs(scope.row.afterMoney) | formatCurrency }})</span>
            <span v-else>{{ scope.row.afterMoney | formatCurrency }}</span>

          </template>
        </el-table-column>
        <el-table-column label="Chuyển thanh toán" align="center" width="150">
          <template slot-scope="scope">

            <span v-if="scope.row.transactionEntry==='MONEY_WALLET'">Không chuyển</span>
            <span v-if="scope.row.transactionEntry==='MONEY_MONTH'">Sang tháng sau</span>

          </template>
        </el-table-column>
        <el-table-column label="Đợt chi" align="center" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.spendTurn }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Tác vụ" align="center" min-width="120" width="140">
          <template slot-scope="scope">
            <el-button
                v-if="checkPermission(['fees_2_excel_cash_payment'])"
                type="success"
                size="mini"
                @click="orderKidsCashierMethod(scope.row)"
            >Thanh toán
            </el-button>
            <!--            <el-button-->
            <!--                size="mini"-->
            <!--                type="warning"-->
            <!--                @click="changeStatusTransactionEntry(scope.row)"-->
            <!--                :disabled="scope.row.transactionEntry!=='MONEY_MONTH'">Sửa-->
            <!--            </el-button>-->

          </template>
        </el-table-column>

      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" size="medium" @click="handleClose()">
          <i class="el-icon-circle-close"/>
          <span>{{ $t("button.close") }}</span>
        </el-button>
      </span>
    </el-dialog>

    <ExpensesEmployeeSalaryExcelCashier
        :isOpenCashierDialog="showOrderKidsExcelCashierDialog"
        @dialog-close="closeOrderKidsExcelCashierDialog()"
        ref="ExpensesEmployeeSalaryExcelCashier"
    />

  </div>
</template>

<script>

import {mapState} from "vuex";
import checkPermission from "@/utils/permission";
import ExpensesEmployeeSalaryExcelCashier
  from "@/components/FinanceVer2/employeeSalary/employeeSalaryTableExcelDialog/ExpensesEmployeeSalaryExcelCashier.vue";
import NewSalaryService from "@/services/EmployeeService/NewSalaryService";

export default {
  name: "InventoryOrderEmployeeSalary",
  computed: {
    ...mapState("employeeSalaryNew", ["dataSearch"]),
  },
  components: {
    ExpensesEmployeeSalaryExcelCashier
  },

  data() {
    return {
      fullName: "",
      code: "",
      inventoryList: [],

      idInfo: "",
      paySlipCodeNotify: "",
      spendTurn: 0,
      dataRequest: {
        month: 0,
        idDepartment: '',
        code: "",
        year: ''
      },
      showOrderKidsExcelCashierDialog: false,
    }
  },
  props: {
    isOpenInventoryOrder: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    checkPermission,
    getInventoryOrder(fullName, code, idInfo, paySlipCodeNotify) {
      this.dataRequest.month = this.dataSearch.month;
      this.dataRequest.idDepartment = this.dataSearch.idDepartment;
      this.dataRequest.year = this.dataSearch.year;
      this.dataRequest.code = code;
      this.fullName = fullName;
      this.code = code;
      this.idInfo = idInfo;
      this.paySlipCodeNotify = paySlipCodeNotify;
      NewSalaryService.getInformationAboutEmployeeSalaryChecks(this.dataRequest).then((resp) => {
        this.inventoryList = resp.data;
      })
    },


    handleClose() {

      this.$emit("closeInventoryOrder", false);
      this.fullName = "";
      this.code = "";
      this.inventoryList = [];
      this.idInfo = "";
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);

    },

    tableRowStyle({row}) {
      if (row.category === "out") {
        return "color: #409EFF";
      }
    },
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;";
    },

    /**
     * Mở dialog lưu lịch sử thanh toán
     // * @param row
     */
    orderKidsCashierMethod(row) {
      let idInfo = this.idInfo;
      let fullName = this.fullName;
      let paySlipCode = row.paySlipCode;
      let paySlipCodeNotify = this.paySlipCodeNotify;
      let spendTurn = row.spendTurn;
      let month = row.month;
      let year = this.dataSearch.year;

      this.showOrderKidsExcelCashierDialog = true;
      this.$refs.ExpensesEmployeeSalaryExcelCashier.getDataCashierSalaryInitial(
          idInfo,
          fullName,
          paySlipCode,
          paySlipCodeNotify, spendTurn, month, year
      );

    },
    closeOrderKidsExcelCashierDialog() {
      this.showOrderKidsExcelCashierDialog = false;
      NewSalaryService.getInformationAboutEmployeeSalaryChecks(this.dataRequest).then((resp) => {
        this.inventoryList = resp.data;
      })
    },

    changeStatusTransactionEntry(row) {

      this.$confirm(
          "Bạn có chắc chắn muốn chuyển thanh toán hay không ? ",
          {
            distinguishCancelAndClose: true,
            confirmButtonText: "Có",
            closeOnClickModal: false,
            cancelButtonText: "Không",
          }
      ).then(() => {
        let request = {
          month: 0,
          code: "",
          newTransactionEntryPaySlip: "MONEY_WALLET",
          spendTurn: 0,
          year: ''
        }
        request.month = row.month
        request.code = this.code
        request.spendTurn = row.spendTurn
        request.year = this.dataSearch.year

        NewSalaryService.changeStatusTransactionEntry(request)
            .then(() => {
              this.$message({
                message: "Chuyển thanh toán thành công",
                type: "success",
              });

              this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);

              this.handleAfterChangeStatusSuccess()
            })
            .catch(() => {
              this.$message({
                message: "Chuyển thanh toán thất bại",
                type: "error",
              });
            });
      })
    },
    /**
     * Load lai dữ liệu khi thay đổi trạng thái kết chuyển thành công
     */
    handleAfterChangeStatusSuccess() {
      NewSalaryService.getInformationAboutEmployeeSalaryChecks(this.dataRequest).then((resp) => {
        this.inventoryList = resp.data;
      })
    }


  }
}
</script>

<style scoped>

</style>


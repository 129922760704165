<template>
  <el-dialog
      top="30vh"
      @open="handleOpen"
      width="700px"
      title="Xuất mẫu công lương"
      :visible.sync="isOpenExportTemplateDialog"
      :before-close="handleClose"
      :close-on-click-modal="false"
  >
    <el-row :gutter="5" style="margin-bottom: 5px">
      <el-col
          :offset="1"
          :span="3"
          style="padding-top: 7px"
      >Chọn tháng
      </el-col>
      <el-col :span="2">
        <el-date-picker
            size="small"
            style="width: 120px"
            class="input-common"
            :clearable="false"
            v-model="monthAndYear"
            type="month"
            placeholder="Chọn ngày"
            value-format="M-yyyy"
            format="MM-yyyy"
        >
        </el-date-picker>
      </el-col>
      <el-col
          :offset="5"
          :span="3"
          style="padding-left: 15px;padding-top: 7px"
      >Chọn mẫu
      </el-col>
      <el-col :span="2">
        <el-autocomplete
            size="small"
            style="width: 220px"
            v-model="selectedTemplate"
            :fetch-suggestions="querySearch"
            placeholder="Chọn Template mẫu"
            @select="handleSelect">
          <i
              class="el-icon-caret-bottom el-input__icon"
              slot="suffix"
              v-if="selectedTemplate === ''"
          >
          </i>
          <i
              class="el-icon-close"
              style="margin-top: 10px"
              slot="suffix"
              v-if="selectedTemplate !== ''"
              @click="deleteTemplate"
          >
          </i>
          <template slot-scope="{ item }">
            <div class="value">{{ item.nameFile }}</div>
          </template>
        </el-autocomplete>
      </el-col>
    </el-row>
    <el-row>
      <el-col :offset="14">
        <span style="color: red" v-if="selectedTemplate === ''">* Vui lòng chọn template mẫu</span>
      </el-col>
    </el-row>
    <el-row style="margin-top: 15px">
      <el-col :offset="1" :span="6" style="text-align: left">
        <span>Chọn dữ liệu điểm danh</span>
      </el-col>
      <el-col :offset="4" :span="7">
        <div class="title-select">
          <span v-if="exportTemplateParams.monthBeforeOrAfter === true && parseInt(monthAndYear.split('-')[0]) !== 1">Thiếu thừa tháng {{parseInt(monthAndYear.split('-')[0]) - 1}}-{{monthAndYear.split('-')[1]}}</span>
          <span v-if="exportTemplateParams.monthBeforeOrAfter === false">Thiếu thừa tháng {{monthAndYear}}</span>
          <span v-if="exportTemplateParams.monthBeforeOrAfter === true && parseInt(monthAndYear.split('-')[0]) === 1">Thiếu thừa tháng 12-{{parseInt(monthAndYear.split('-')[1]) - 1}}</span>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 10px">
      <el-col :offset="1" :span="4">
        <el-radio v-model="exportTemplateParams.monthBeforeOrAfter" :label="false">Tháng được chọn</el-radio>
      </el-col>
      <el-col :offset="6" :span="4">
        <div class="radio-1">
          <el-radio v-model="exportTemplateParams.dataAfterMoney" :label="false" @change="handleRadioChange('noData')">
            Không có dữ liệu
          </el-radio>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 14px">
      <el-col :offset="1" :span="4">
        <el-radio v-model="exportTemplateParams.monthBeforeOrAfter" :label="true">Trước 1 tháng</el-radio>
      </el-col>
      <el-col :offset="6" :span="4">
        <div class="radio-1">
          <el-radio v-model="exportTemplateParams.dataAfterMoney" :label="true" @change="handleRadioChange('haveData')">
            Có dữ liệu
          </el-radio>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 15px">
      <el-col :offset="11">
        <div class="radio-2" v-if="exportTemplateParams.dataAfterMoney===true">
          <el-radio v-model="exportTemplateParams.forwardTransaction" :label="true"
                    @change="handleRadioChange('forwardTransaction')">Kết chuyển
          </el-radio>
          <el-radio v-model="exportTemplateParams.noForwardTransaction" :label="true"
                    @change="handleRadioChange('noForwardTransaction')">Không chuyển
          </el-radio>
          <el-radio v-model="exportTemplateParams.allTransaction" :label="true"
                    @change="handleRadioChange('allTransaction')">Tất cả
          </el-radio>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div class="description">
          <span v-if="selectedDescription !== ''&& selectedTemplate !== ''">Mô tả file: {{
              selectedDescription
            }} </span>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 10px; margin-right: 50px">
      <el-col style="display: inline-flex;flex-direction: row-reverse;gap: 0.8rem">
        <el-button type="danger" @click="handleClose"><i class="el-icon-circle-close"/> Đóng</el-button>
        <el-button type="success" icon="el-icon-download" @click="handleExportTemplateFile"
                   v-if="selectedTemplate !== ''" :loading="loadingExcel">Xuất File
        </el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>
<script>
import {v4} from "uuid";
import {mapState} from "vuex";
import moment from "moment";
import {handleAndDownloadExcel} from "@/utils/dowloadExcel";
import NewSalaryService from "@/services/EmployeeService/NewSalaryService";

export default {

  name: "ExportTemplateEmployeeSalary",
  props: {
    isOpenExportTemplateDialog: {
      type: Boolean,
      default: () => false,
    },
  },

  computed: {
    ...mapState("employeeSalaryNew", ["idInfoList", "dataSearch"]),
  },
  data() {
    return {
      monthAndYear: moment().format('M-yyyy'),
      loadingExcel: false,
      selectedTemplate: '',
      timeout: null,
      selectedDescription: '',
      listFileTemplate: [],
      exportTemplateParams: {
        idTemplateSchool: "",
        monthNow: moment().format("M"),
        monthBeforeOrAfter: false,
        dataAfterMoney: false,
        guiId: "",
        idInfoList: "",
        allTransaction: false,
        forwardTransaction: false,
        noForwardTransaction: false,
        year: moment().format("YYYY"),
      },
    };
  },

  watch:{
    monthAndYear: {
      handler(val) {
        this.exportTemplateParams.monthNow = val.split('-')[0]
        this.exportTemplateParams.year = val.split('-')[1]
      }
    },
  },
  methods: {
    deleteTemplate() {
      this.selectedTemplate = ""
    },
    handleRadioChange(selectedRadio) {
      if (selectedRadio === 'allTransaction') {
        this.exportTemplateParams.forwardTransaction = false;
        this.exportTemplateParams.noForwardTransaction = false;
      } else if (selectedRadio === 'forwardTransaction') {
        this.exportTemplateParams.allTransaction = false;
        this.exportTemplateParams.noForwardTransaction = false;
      } else if (selectedRadio === 'noForwardTransaction') {
        this.exportTemplateParams.allTransaction = false;
        this.exportTemplateParams.forwardTransaction = false;
      } else if (selectedRadio === 'noData') {
        this.exportTemplateParams.allTransaction = false;
        this.exportTemplateParams.noForwardTransaction = false;
        this.exportTemplateParams.forwardTransaction = false;
      } else if (selectedRadio === 'haveData') {
        this.exportTemplateParams.allTransaction = true;
        this.exportTemplateParams.noForwardTransaction = false;
        this.exportTemplateParams.forwardTransaction = false;
      }
    },

    handleOpen() {
      this.monthAndYear = moment().format('M-yyyy')
      NewSalaryService
          .getAllTemplateList()
          .then((res) => {
            this.listFileTemplate = res.data
            this.exportTemplateParams.idTemplateSchool = this.listFileTemplate[0].id
            this.exportTemplateParams.idInfoList = this.idInfoList
          });
    },
    querySearch(queryString, callback) {
      let template = this.listFileTemplate;
      let results = queryString ? template.filter(this.createFilter(queryString)) : template;
      callback(results);
    },
    createFilter(queryString) {
      return (template) => {
        return template.nameFile.toLowerCase().includes(queryString.toLowerCase());
      };
    },
    handleSelect(item) {
      this.selectedTemplate = item.nameFile
      this.selectedDescription = item.description
      this.exportTemplateParams.idTemplateSchool = item.id
    },
    handleClose() {
      this.handleCloseTemporarily()
      this.handleClear()
    },
    handleCloseTemporarily() {
      this.loadingExcel = false;
      this.selectedTemplate = ""
      this.selectedDescription = ''
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
      this.$emit("closeExportTemplateDialog", false);
    },
    handleClear() {
      this.exportTemplateParams.monthBeforeOrAfter = false;
      this.exportTemplateParams.dataAfterMoney = false;
      this.exportTemplateParams.allTransaction = false;
      this.exportTemplateParams.forwardTransaction = false;
      this.exportTemplateParams.noForwardTransaction = false;
    },
    handleExportTemplateFile() {
      this.loadingExcel = true;
      const guiId = v4();
      this.exportTemplateParams.guiId = guiId
      // bắt đầu gọi export
      NewSalaryService.exportGeneralSalarySlips(this.exportTemplateParams).then((res) => {
        // chạy neeus k conf processing
        if (res.data.message === "processing") {
          this.loadingExcel = false;
          this.$notify.info({
            title: "Thông báo",
            message: "Dữ liệu đang được xử lý !",
          });
        }
        this.handleClose();
        // Call the function again after 5 seconds
        const checkProcess = setInterval(() => {
          NewSalaryService
              .exportGeneralSalarySlips(this.exportTemplateParams)
              .then((res) => {
                // chạy neeus k conf processing
                if (res.data.message === "processing") {
                  this.$notify.info({
                    title: "Thông báo",
                    message: "Dữ liệu đang được xử lý !",
                  });
                  return;
                }
                this.handleCloseTemporarily()
                if (res.data.message === "fail") {
                  clearInterval(checkProcess)
                  this.$notify.error({
                    title: "Thông báo",
                    message: "Lỗi không xuất được data !",
                  });
                  this.handleClear()
                  return;
                }

                if (res.data.message.match("complete")) {
                  const fileNameRegex = /complete_(.*).xlsx/;
                  const matches = res.data.message.match(fileNameRegex);
                  const fileName = matches[1];
                  this.$notify.success({
                    title: "Thông báo",
                    message: "Dữ liệu xuất thành công",
                  });
                  clearInterval(checkProcess);
                  return NewSalaryService
                      .downloadExcelFile(guiId)
                      .then((res) => {
                        const excelData = res.data;
                        const excelFilename = `${fileName}.xlsx`; // Provide a desired filename for the downloaded file
                        handleAndDownloadExcel(excelData, excelFilename);
                        this.handleClear()
                        this.$emit('closeExportTemplateDialog', false)
                      });
                }
              })
              .catch((err) => {
                return Promise.reject(err);
              });
        }, 5000);
      })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen {
  /deep/ .el-dialog__header {
    margin-left: 24px;
  }

  .description {
    margin-left: 30px;
    margin-top: 10px;
  }

  .title-select {
    margin-left: 15px
  }

  .radio-1 {
    margin-left: 15px;
  }

  .radio-2 {
    margin-left: 15px;
  }
}
</style>
<template>
  <div>
    <div class="search-table">
      <el-date-picker
          style="width: 115px"
          class="custom-date-picker"
          :clearable="false"
          v-model="monthAndYear"
          type="month"
          @change="handelUpdateDate"
          placeholder="Chọn tháng"
          value-format="M-yyyy"
          format="MM-yyyy"
          :time-arrow-control="false"
      >
      </el-date-picker>
      <!-- chọn trạng thái làm việc -->
      <el-select
          @change="handleChangeEmployeeStatus"
          style="width: 120px"
          class=""
          v-model="dataSearch.employeeStatus"
          placeholder="Trạng thái"
      >
        <el-option
            v-for="(item,index) in statusEmployeeListNew"
            :key="index"
            :value="item"
            :label="item"
        ></el-option>
      </el-select>

      <!-- Chọn phòng ban -->
      <el-select
          @change="handleChangeDepartment"
          class=" input-grade"
          style="width: 160px"
          v-model="dataSearch.idDepartment"
          placeholder="Chọn phòng ban"
          clearable
      >
        <el-option
            v-for="(item,index) in departmentList"
            :key="index"
            :value="item.id"
            :label="item.departmentName"
        ></el-option>
      </el-select>

      <!-- trạng thái chi tiền -->
      <el-select
          @change="handleChangeStatus"
          @remove-tag="handleChangeStatus"
          @clear="handleChangeStatus"
          style="width: 230px"
          placeholder="Chọn trạng thái"
          v-model="paymentStatus"
          multiple
          collapse-tags
          clearable
      >
        <el-option
            v-for="(item, index) in listPaymentStatus"
            :label="item"
            :key="index"
            :value="item"
        >
        </el-option>
      </el-select>
      <el-input
          @clear="handleClearEmployeeName"
          clearable
          style="width: 240px"
          placeholder="Nhập tên, SĐT"
          v-model="employeeName"
          @keyup.enter.native="handleChangeEmployeeName"
      >
        <el-button
            slot="append"
            icon="el-icon-search"
            @click="handleSearchResultByName"
        ></el-button>
      </el-input>
      <el-select
          @change="handleChangeSpendTurn"
          style="width: 100px"
          class=""
          v-model="dataSearch.spendTurn"
          placeholder="Đợt chi"
      >
        <el-option
            v-for="item in spendTurn"
            :key="item"
            :value="item"
            :label="`Đợt ${item}`"
        ></el-option>
      </el-select>
      <el-button
          style="float: right"
          class="custom_button"
          type="success"
          icon="el-icon-plus"
          size="medium"
          v-if="checkRevenue"
          @click="handleOpenAddNameExpensesDialog"
      >
        Thêm khoản
      </el-button>
      <span class="loading_title" v-if="loadingBill === true" style="color: red;	text-align: right;">Dữ liệu đang được xử lý... <span
          v-loading="loadingBill"></span> </span>
      <span class="loading_title" v-if="loadingUpdate === true">Đang cập nhật điểm danh... <span
          v-loading="loadingUpdate"></span></span>
      <span class="loading_title" v-if="bulkPayments === true">Đang cập nhật thanh toán toàn bộ... <span
          v-loading="bulkPayments"></span></span>

    </div>
    <slot></slot>
    <el-row style="margin-top: 15px ">

      <el-col :span="20" :offset="4" style=" display: inline-flex;justify-content: right ">
        <el-pagination
            style=" margin-right: 5.2rem;margin-left: auto"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPagePagination"
            :page-sizes="listSizeLimit"
            :page-size="sizeLimit"
            layout=" prev, pager,sizes, next"
            :total="sizeLimit * totalPages"
        >
        </el-pagination>
        <el-menu
            style="margin-right: 1.2rem"
            v-if="checkPermission(['salary_new_excel_action'])"
            background-color="#67C23A"
            text-color="#FFFFFF"
            active-text-color="#FFFFFF"
            :unique-opened="true"
            menu-trigger="hover"
            mode="horizontal"
            @select="handleCommand"
        >
          <el-submenu index="1">
            <template slot="title">Tác vụ</template>
            <el-menu-item index="updateAttendanceData" v-if="updateAttendance === true">Cập nhật điểm danh
            </el-menu-item>
            <el-submenu index="2">
              <template slot="title">Phiếu lương</template>
              <el-menu-item index="2-createSalarySlip">Khởi tạo phiếu lương</el-menu-item>
              <el-menu-item index="2-pdfPreview">In phiếu lương 1 liên</el-menu-item>
              <el-menu-item index="2-pdfSplit">In phiếu luơng 2 liên</el-menu-item>
              <el-menu-item index="2-exportAllBill"
              >Xuất phiếu lương theo tháng
              </el-menu-item
              >
              <el-menu-item index="2-cancelsSalarySlip">Hủy phiếu lương</el-menu-item>
              <el-menu-item v-if="listHiddenOrderKids" index="2-restoreSalarySlip">Khôi phục phiếu lương</el-menu-item>
              <el-menu-item v-if="listHiddenOrderKids" index="2-destroySalarySlip">Xóa phiếu lương</el-menu-item>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">Xuất phiếu lương tổng hợp</template>
              <el-menu-item index="3-exportExcel">Theo đợt thu</el-menu-item>
              <el-menu-item index="3-exportAllExcel"
              >Theo tháng
              </el-menu-item
              >
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">Gửi phiếu lương</template>
              <el-menu-item index="4-sendApp">Gửi qua Onekids</el-menu-item>
              <el-menu-item index="4-sendSMS">Gửi SMS</el-menu-item>
            </el-submenu>
            <el-submenu index="5">
              <template slot="title">Mẫu phiếu lương</template>
              <el-menu-item index="5-uploadTemplateSchool">Upload File mẫu</el-menu-item>
              <el-menu-item index="5-deleteTemplateSchool">Xóa File mẫu</el-menu-item>
            </el-submenu>
            <el-menu-item index="showListForApp">Hiển thị</el-menu-item>
            <el-menu-item index="hiddenListForApp">Bỏ hiển thị</el-menu-item>
            <el-menu-item index="createNoteExcel">Tạo ghi chú</el-menu-item>
            <el-menu-item index="allCashier">Thanh toán hàng loạt</el-menu-item>
          </el-submenu>
        </el-menu>
        <el-button
            v-if="checkPermission(['salary_new_excel_export_model'])"
            style="font-family: Roboto,serif"
            type="success"
            icon="el-icon-link"
            size="medium"
            @click="handleOpenExportTempDialog"
        >
          Xuất mẫu công lương
        </el-button>

        <el-button
            v-if="checkPermission(['salary_new_excel_import_local'])"
            style="font-family: Roboto,serif"
            type="success"
            icon="el-icon-link"
            size="medium"
            @click="handleImportSalaryChecker"
        >
          Nhập từ Excel
        </el-button>
      </el-col>
    </el-row>

    <!--  dialog field  -->
    <!--    Xuất mẫu công lương-->
    <ExportTemplateEmployeeSalary
        :isOpenExportTemplateDialog="isOpenExportTemplateDialog"
        @closeExportTemplateDialog="handleCloseExportTemplateDialog"
    />
    <!--    Tải mẫu file công lương-->
    <UploadTemplateEmployeeSalary
        :isOpenUploadTemplateEmployeeSalary="openUploadTemplateEmployeeSalary"
        @close="handleCloseTemplateEmployeeSalary"
    />
    <!--    Gửi công lương qua App-->
    <SendAppEmployeeSalary
        :dialog-visible-ex="dialogVisibleEx"
        @close="handleCloseSendApp"
        ref="SendAppDialog"
    />
    <!--    Xóa mẫu công lương-->
    <DeleteTemplateEmployeeSalary
        :is-dialog-template-visible="isOpenDeleteTemplate"
        @closeTemplateDeleteDialog="handleCloseTemplateDeleteDialog"
    />
    <!--    Tạo ghi chú cho nhân viên-->
    <CreateNoteEmployeeSalary
        :isOpenCreateNote="createNote"
        @closeNoteDialog="handleCloseNoteDialog"
    />
    <!--    Thanh toán hàng loạt-->
    <AllCashierEmployeeSalary
        :isOpenAllCashierDialog="showAllCashierDialog"
        :bulkPayments.sync="bulkPayments"
        @dialog-close="closeAllCashierDialog"
        ref="AllCashier"
    />
    <!--    Tải file excel công lương lên-->
    <ImportExcelEmployeeSalary
        :isOpenImportChecker="isOpenExcelChecker"
        ref="importExcelEmployeeSalary"
        @closeCheckerSalaryDialog="handleCloseImportSalaryChecker"
        @refreshSpendTurn="handleRefreshCollectionTurn"
    />
    <!--    Thêm khoản chi-->
    <AddNameExpenses
        :isOpenAddNameExpensesDialog="showAddNameExpensesDialog"
        @closeAddNameExpensesDialog="closeAddNameExpensesDialog"
    />
    <!--    Thêm khoản chi-->
    <AddNameExpenses
        :isOpenAddNameExpensesDialog="showAddNameExpensesDialog"
        @closeAddNameExpensesDialog="closeAddNameExpensesDialog"
    />

  </div>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import {v4} from "uuid";
import checkPermission from "@/utils/permission";
import ExportTemplateEmployeeSalary
  from "@/components/FinanceVer2/employeeSalary/employeeSalarySearchDialog/ExportTemplateEmployeeSalary.vue";
import UploadTemplateEmployeeSalary
  from "@/components/FinanceVer2/employeeSalary/employeeSalarySearchDialog/UploadTemplateEmployeeSalary.vue";
import DeleteTemplateEmployeeSalary
  from "@/components/FinanceVer2/employeeSalary/employeeSalarySearchDialog/DeleteTemplateEmployeeSalary.vue";
import SendAppEmployeeSalary
  from "@/components/FinanceVer2/employeeSalary/employeeSalarySearchDialog/SendAppEmployeeSalary.vue";
import CreateNoteEmployeeSalary
  from "@/components/FinanceVer2/employeeSalary/employeeSalarySearchDialog/CreateNoteEmployeeSalary.vue";
import AllCashierEmployeeSalary
  from "@/components/FinanceVer2/employeeSalary/employeeSalarySearchDialog/AllCashierEmployyeeSalary.vue";
import ImportExcelEmployeeSalary
  from "@/components/FinanceVer2/employeeSalary/employeeSalarySearchDialog/ImportExcelEmployeeSalary.vue";
import updateAttendanceDataEmployeeMixin from "@/global/mixinEmployee/updateAttendanceDataEmployee.mixin";
import paymentEmployeeSalaryMixin from "@/global/mixinEmployee/paymentEmployeeSalary.mixin";
import listEmployeeForAppMixin from "@/global/mixinEmployee/listEmployeeForApp.mixin";
import NewSalaryService from "@/services/EmployeeService/NewSalaryService";
import exportExcelEmployeeMixin from "@/global/mixinEmployee/exportExcelEmployee.mixin";
import {statusEmployeeListNew} from "@/constant/listCommon";
import sendSMSEmployeeMixin from "@/global/mixinEmployee/sendSMSEmployee.mixin";
import AddNameExpenses from "@/components/FinanceVer2/employeeSalary/employeeSalarySearchDialog/AddNameExpenses.vue";

export default {
  name: "EmployeeSalarySearch",
  components: {
    AddNameExpenses,
    ImportExcelEmployeeSalary,
    AllCashierEmployeeSalary,
    CreateNoteEmployeeSalary,
    SendAppEmployeeSalary,
    DeleteTemplateEmployeeSalary,
    UploadTemplateEmployeeSalary,
    ExportTemplateEmployeeSalary,
  },
  watch: {
    isOrderDelete: {
      handler() {
        this.isUpdateSpendTurn = false //không cho phép gọi update đợt thu mới nhất khi thao tác ẩn hóa đơn, giữ lại đợt thu hiện tại
        this.getStatus();
      },
      deep: true
    },
    isOrderOpen: {
      handler() {
        this.isUpdateSpendTurn = false //không cho phép gọi update đợt thu mới nhất khi thao tác ẩn hóa đơn, giữ lại đợt thu hiện tại
        this.getStatus();
      },
      deep: true
    },
    isOrderDestroy: {
      handler() {
        this.isUpdateSpendTurn = false //không cho phép gọi update đợt thu mới nhất khi thao tác ẩn hóa đơn, giữ lại đợt thu hiện tại
        this.getStatus();
      },
      deep: true
    },
    // gọi nếu cần cập nhật trạng thái
    updateStatus: {
      handler() {
        this.isUpdateSpendTurn = false
        this.getStatus();
      },
      deep: true,
    },
    monthAndYear: {
      handler(val) {
        this.dataSearch.month = val.split('-')[0]
        this.dataSearch.year = val.split('-')[1]

      }
    },
  },
  computed: {
    ...mapState("employeeSalaryNew", [
      "totalPages",
      "idInfoList",
      "listNullBillKids",
      "listHiddenOrderKids",
      "listShowForApp",
      "updateStatus",
      "messageShowForApp",
      "messageHiddenForApp",
      "messageHiddenForApp",
      "responseFilterList", "checkRevenue","checkResponse"
    ]),
    ...mapGetters("employeeStore", ["employeeList"]),
  },
  mixins: [
    exportExcelEmployeeMixin,
    sendSMSEmployeeMixin,
    listEmployeeForAppMixin,
    paymentEmployeeSalaryMixin,
    updateAttendanceDataEmployeeMixin,
  ],

  data() {
    return {
      showAddNameExpensesDialog: false,
      isUpdateSpendTurn: true,
      monthAndYear: moment().format('M-yyyy'),
      // watcher
      dataInitialized: false, // sử dụng để ngăn watcher call api khởi tạo data
      // phân trang
      currentPagePagination: 1,
      // tên học sinh
      employeeName: "",
      paymentStatus: [],
      statusEmployeeListNew,
      departmentList: [],
      classList: [],
      listPaymentStatus: [],
      flag: false,
      // giới hạn bản ghi
      sizeLimit: 30, // mặc định là  giới hạn 20 bản ghi
      listSizeLimit: [30, 40, 50],
      spendTurn: 0,
      //Export Excel for month param
      previewExcelParam: {
        guiId: "success",
        date: "",
        idInfoList: "",
        spendTurn: 0,
      },
      dataSearch: {
        monthAndYear: "",
        spendTurn: null, // đợt chi
        month: moment().format('M'),
        employeeStatus: "Đang làm",
        namePhone: "", // tên nhân viên
        page: 1,
        limit: 30,
        statusSalary: [],
        year: moment().format("YYYY"),
        idDepartment: ""
      },
      // dialog field
      isOpenExportTemplateDialog: false,
      isOpenExportExcelByMonthDialog: false,
      isOpenExcelChecker: false,
      isOpenHistoryOrderKids: false,
      openUploadTemplateEmployeeSalary: false,
      createNote: false,
      dialogVisibleEx: false,
      isOpenDeleteTemplate: false,
      showListForApp: {
        date: "",
        idInfoList: "",
        spendTurn: "",
      },
      showAllCashierDialog: false,
      bulkPayments: false,
    };
  },

  created() {
    this.fetchDataTable();
    this.$store.commit("employeeSalaryNew/renew_bill_preview");
    this.dataSearch.monthAndYear = this.monthAndYear;
    this.$store.dispatch("employeeStore/fetchDataEmployeeList");
  },

  methods: {
    checkPermission,

    // lấy danh sách trươc khi lấy ra table
    fetchDataTable() {
      this.getDepartmentList(); // Lấy danh sách trạng thái
    },

    // Lấy danh sách phòng ban
    getDepartmentList() {
      NewSalaryService
          .getListDepartment()
          .then((resp) => {
            this.departmentList = resp.data
            this.getStatus();
          })
          .catch((err) => {
            this.$message({
              message: "Lỗi dữ liệu!",
              type: "error",
              duration: 5000
            });
            this.$store.commit("employeeSalaryNew/check_response",false)
            console.log(err);
          });
    },

    // Lấy trạng thái phiếu lương
    getStatus() {
      const param = {
        idDepartment: this.dataSearch.idDepartment,
        month: this.dataSearch.month,
        year: this.dataSearch.year
      }
      NewSalaryService
          .getPaymentStatus(param)
          .then((res) => {
            this.listPaymentStatus = res.data;
            if (!this.isUpdateSpendTurn) {
              this.$store.dispatch(
                  "employeeSalaryNew/init_employee_data", this.dataSearch);
            } else {
              this.getSpendTurn();
            }
          }).catch(() =>{
        this.$message({
          message: "Lỗi dữ liệu!",
          type: "error",
          duration: 5000
        });
        this.$store.commit("employeeSalaryNew/check_response",false)
      });
    },

    // Lấy đợt chi
    getSpendTurn() {
      const param = {
        idDepartment: this.dataSearch.idDepartment,
        month: this.dataSearch.month,
        year: this.dataSearch.year
      }
      NewSalaryService.getSpendTurn(param)
          .then((res) => {
            if (res.data === 0) {
              this.dataSearch.spendTurn = null;
              this.spendTurn = null;
              this.$store.commit("employeeSalaryNew/loading_data")
              this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
              return;
            }
            this.spendTurn = res.data;
            this.dataSearch.spendTurn = this.spendTurn;
            this.$store.commit("employeeSalaryNew/loading_data")
            this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
          }).catch(() => {
        this.$message({
          message: "Lỗi dữ liệu!",
          type: "error",
          duration: 5000
        });
        this.$store.commit("employeeSalaryNew/check_response",false)
      });
    },

    // xử lý đổi trạng thái nhân viên
    handleChangeEmployeeStatus() {
      this.$store.commit("employeeSalaryNew/loading_data");
      this.dataSearch.page = 1;
      this.currentPagePagination = 1;
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
    },

    /**
     * thay đổi phòng ban
     */
    handleChangeDepartment() {
      this.$store.commit("employeeSalaryNew/loading_data");
      this.isUpdateSpendTurn = true;  // cho phép gọi update đợt thu mới nhất
      this.currentPagePagination = 1;
      this.dataSearch.page = 1;
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
    },

    // Thay đổi trạng thái chi tiền
    handleChangeStatus() {
      this.flag = false
      if (2 <= this.paymentStatus.length) {
        for (let i = 0; i < this.paymentStatus.length; i++) {
          if (this.paymentStatus[i] === "Chưa có phiếu lương") {
            this.flag = true;
            break;
          }
        }
      }
      if (this.flag === false) {
        this.dataSearch.statusSalary = this.paymentStatus;
        this.$store.commit("employeeSalaryNew/loading_data");
        this.currentPagePagination = 1;
        this.dataSearch.page = 1;
        this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
      } else {
        this.$message.error({
          showClose: true,
          message: "Không thể chọn Chưa có phiếu lương cùng với các trạng thái khác. <br>" +
              "Vui lòng chọn lại!",
          dangerouslyUseHTMLString: true,
        });
      }
    },
    // lấy đợt chi theo ngày
    handelUpdateDate() {
      this.$store.commit("employeeSalaryNew/loading_data");
      this.$store.commit("employeeSalaryNew/setCheckRevenue");
      this.dataSearch.page = 1;
      this.currentPagePagination = 1;
      this.isUpdateSpendTurn = true;
      this.dataSearch.monthAndYear = this.monthAndYear;
      this.getStatus();
    },

    // bắt sự kiện tìm theo tên qua enter
    handleChangeEmployeeName() {
      this.$store.commit("employeeSalaryNew/loading_data");
      this.dataSearch.page = 1;
      this.currentPagePagination = 1;
      this.dataSearch.namePhone = this.employeeName;
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
    },

    // Trả ra kết quả tìm kiếm theo tên khi bấm vào icon tìm kiếm
    handleSearchResultByName() {
      this.$store.commit("employeeSalaryNew/loading_data");
      this.currentPagePagination = 1;
      this.dataSearch.page = 1;
      this.dataSearch.namePhone = this.employeeName;
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
    },
    // tìm kiếm khi xóa tên nhân viên
    handleClearEmployeeName() {
      this.$store.commit("employeeSalaryNew/loading_data");
      this.dataSearch.page = 1;
      this.currentPagePagination = 1;
      this.dataSearch.namePhone = this.employeeName;
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
    },
    // Thay đổi đợt chi
    handleChangeSpendTurn() {
      this.$store.commit("employeeSalaryNew/loading_data");
      this.dataSearch.page = 1;
      this.currentPagePagination = 1;
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
    },

    /**
     Xử lý phân trang
     */
    handleSizeChange(value) {
      this.$store.commit("employeeSalaryNew/loading_data")
      this.currentPagePagination = 1;
      this.dataSearch.page = 1;
      this.sizeLimit = value;
      this.dataSearch.limit = value;
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
    },
    handleCurrentChange(value) {
      this.$store.commit("employeeSalaryNew/loading_data")
      this.currentPagePagination = value;
      this.dataSearch.page = value;
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
    },

    // mở dialog import
    handleImportSalaryChecker() {
      this.isOpenExcelChecker = !this.isOpenExcelChecker;
    },

    handleRefreshCollectionTurn() {
      this.monthAndYear = this.dataSearch.monthAndYear
      this.handelUpdateDate()
    },
    // đóng thẻ import
    handleCloseImportSalaryChecker(status) {
      this.isOpenExcelChecker = status;
    },

    handleCommand(command) {
      // gán giá trị
      const guiId = v4();
      const date = this.$funcCommon.formatMY(this.dataSearch.year, this.dataSearch.month)
      const idGrade = this.dataSearch.idGrade;
      const idInfoList = this.idInfoList;
      const spendTurn = this.dataSearch.spendTurn;
      const dataSearch = this.dataSearch;
      const showListForApp = this.showListForApp;
      if (command === "5-uploadTemplateSchool") {
        this.openUploadTemplateEmployeeSalary = true;
        return;
      }
      if (command === '5-deleteTemplateSchool') {
        this.isOpenDeleteTemplate = !this.isOpenDeleteTemplate
        return;
      }
      if (command === "2-exportAllBill") {
        this.printMonthlyInvoicesInBulk(date, guiId);
        return;
      }
      if (command === "3-exportAllExcel") {
        const exportExcelAll = {
          date: date,
          guiId: guiId
        }
        this.exportExcelEmployeeSalaryAllByMonth(exportExcelAll);
        return;
      }
      if (command === "updateAttendanceData") {
        this.updateAttendanceDataEmployee(date, this.dataSearch)
        return;
      }
      if (this.idInfoList === "") {
        this.$message.error({
          showClose: true,
          message: "Chưa có nhân viên nào được chọn",
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.listHiddenOrderKids) {
        if (command === "2-restoreSalarySlip") {
          this.restoreSalarySlip(idInfoList, dataSearch, showListForApp);
          return;
        }
        if (command === "2-destroySalarySlip") {
          this.destroySalarySlip(idInfoList, dataSearch, showListForApp);
          return;
        }
        this.$message.error({
          showClose: true,
          message: `Nhân viên:  ${this.listHiddenOrderKids} <br>Đã bị ẩn, không thực hiện được tác vụ`,
          dangerouslyUseHTMLString: true,
          duration: 5000
        });
        return;
      }
      if (this.dataSearch.spendTurn !== null) {
        if (command === "3-exportExcel") {
          this.handleExportExcelBySpendTurn(guiId, date, idInfoList, spendTurn);
          return;
        }
      }
      if (command === "2-createSalarySlip") {
        if (this.dataSearch.spendTurn !== null) {
          const idInfoListArray = this.idInfoList.split(',').map(Number);
          let flag = false;
          for (let i = 0; i < idInfoListArray.length; i++) {
            for (let j = 0; j < this.responseFilterList.length; j++) {
              if (idInfoListArray[i] === this.responseFilterList[j].id && this.responseFilterList[j].statusSalary !== null) {
                flag = true;
                this.$message.error({
                  showClose: true,
                  message: `Chỉ thực hiện với nhân viên chưa có phiếu lương!`,
                  dangerouslyUseHTMLString: true,
                  duration: 5000
                });
                return;
              }
            }
          }
          if (!flag) {
            const data = {
              idGrade: idGrade,
              idInfoList: this.idInfoList,
              date: date,
              spendTurn: this.dataSearch.spendTurn,
              idClass: this.dataSearch.idClass
            }
            NewSalaryService.createSalarySlip(data).then(() => {
              this.$notify.success({
                title: "Thông báo",
                message: "Khởi tạo phiếu lương thành công",
              });
              this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
            }).catch((error) => {
              console.log(error)
            })
          }
          return;
        } else {
          this.$message.error({
            showClose: true,
            message: `Chỉ thực hiện với danh sách nhân viên có đợt chi!`,
            dangerouslyUseHTMLString: true,
            duration: 5000
          });
          return;
        }
      }
      if (this.listNullBillKids) {
        this.$message.error({
          showClose: true,
          message: `Nhân viên:  ${this.listNullBillKids}<br>Chưa có khoản, không thực hiện được tác vụ`,
          dangerouslyUseHTMLString: true,
          duration: 5000
        });
        return;
      }

      // kiểm soát xem có trường hợp k thể xuất hóa đơn k

      switch (command) {
        case "2-pdfPreview": {
          this.previewExcelParam.date = this.$funcCommon.formatMY(this.dataSearch.year, this.dataSearch.month);
          this.previewExcelParam.idInfoList = this.idInfoList;
          this.previewExcelParam.spendTurn =
              this.dataSearch.spendTurn;
          this.$store.dispatch(
              "employeeSalaryNew/preview_bill_data",
              {param: this.previewExcelParam, printStyle: 'pdfPreview'}
          );
          break;
        }
        case"2-pdfSplit": {
          this.previewExcelParam.date = this.$funcCommon.formatMY(this.dataSearch.year, this.dataSearch.month);
          this.previewExcelParam.idInfoList = this.idInfoList;
          this.previewExcelParam.spendTurn =
              this.dataSearch.spendTurn;
          this.$store.dispatch(
              "employeeSalaryNew/preview_bill_data",
              {param: this.previewExcelParam, printStyle: 'pdfSplit'}
          );
          break;
        }
        case "showListForApp": {
          this.showListEmployeeForApp(idInfoList, dataSearch, showListForApp);
          break;
        }

        case "hiddenListForApp": {
          this.hiddenListEmployeeForApp(idInfoList, dataSearch, showListForApp);
          break;
        }

        case "2-cancelsSalarySlip": {
          this.cancelsSalarySlip(idInfoList, dataSearch, showListForApp);
          break;
        }

        case "createNoteExcel": {
          this.createNoteMethod();
          break;
        }
        case "4-sendSMS": {
          this.sendSMS(idInfoList, date, dataSearch.spendTurn);
          break;
        }
        case "4-sendApp": {
          if (!this.idInfoList) {
            this.$message.error({
              showClose: true,
              message: `Không có nhân viên được chọn để hiển thị lên App`,
            });
            break;
          }
          if (this.messageHiddenForApp) {
            this.$message.error({
              showClose: true,
              message: `Danh sách học sinh chưa được hiển thị : <br>${this.messageHiddenForApp}`,
              dangerouslyUseHTMLString: true,
              duration: 5000,
            });
            break;
          }
          this.sendApp();
          break;
        }
        case "allCashier": {
          const idKidsListArray = this.idInfoList.split(",").map(Number)
          let employeeListNotActive = this.employeeList.filter(x => x.activated === false);
          let user = null;
          idKidsListArray.forEach((x) => {
            employeeListNotActive.forEach((y) => {
              if (x === y.id) {
                user = y;
              }
            })
          });
          if(user){
            this.$confirm(
                "Có nhân viên " + user.fullName + " chưa được kích hoạt.\n " +
                "Vui lòng kích hoạt tài khoản hoặc hủy chọn nhân viên!",
                'Cảnh báo', {
                  confirmButtonText: 'OK',
                  showCancelButton: false,
                  type: 'error'
                })
            break;
          }

          for (let i = 0; i < idKidsListArray.length; i++) {
            for (let j = 0; j < this.responseFilterList.length; j++) {
              if (idKidsListArray[i] === this.responseFilterList[j].id) {
                if (this.responseFilterList[j].statusSalary !== "Chưa chi 100%") {
                  user = this.responseFilterList[j];
                  break;
                }
              }
            }
          }
          if (!user) {
            this.openAllCashierDialog();
            break;
          } else {
            this.$confirm(
                "Nhân viên "+ user.fullName + " đã có khoản thanh toán. " +
                "Cách thức này chỉ thực hiện với nhân viên chưa chi 100%!",
                'Cảnh báo', {
                  confirmButtonText: 'OK',
                  showCancelButton: false,
                  type: 'error'
                })
            break;
          }

        }
        default:
          break;
      }
    },
    // đóng thẻ import
    handleCloseOpenHistoryOrderKids(status) {
      this.isOpenHistoryOrderKids = status;
    },
    //Mở component HistoryOrderKidsCode
    handleOpenHistoryOrderKids() {
      this.isOpenHistoryOrderKids = !this.isOpenHistoryOrderKids;
    },
    // đóng thẻ import
    handleOpenExportTempDialog() {
      if (this.idInfoList.split(",").map(Number).indexOf(0) === 0) {
        this.$message({
          message: "Chưa có nhân viên nào được chọn!",
          type: "error",
        });
      } else {
        this.isOpenExportTemplateDialog = true;
      }
    },

    handleCloseExportTemplateDialog(status) {
      this.isOpenExportTemplateDialog = status;
    },
    // Gửi qua App mở dialog
    sendApp() {
      this.dialogVisibleEx = !this.dialogVisibleEx;
    },
    handleCloseTemplateDeleteDialog(value) {
      this.isOpenDeleteTemplate = value
    },
    /**
     * Mở dialog hiển thị tạo ghi chú
     */
    createNoteMethod() {
      if (this.idInfoList.split(",").map(Number).indexOf(0) === 0) {
        this.$message({
          message: "Chưa có nhân viên nào được chọn!",
          type: "error",
        });
      } else {
        this.createNote = !this.createNote;
      }
    },
    handleCloseNoteDialog(close) {
      this.createNote = close;
    },
    handleCloseTemplateEmployeeSalary() {
      this.openUploadTemplateEmployeeSalary = false;
    },
    handleCloseSendApp(close) {
      this.dialogVisibleEx = close;
    },
    /**
     * Mở Dialog thanh toán hàng loạt
     */
    openAllCashierDialog() {

      let month = this.dataSearch.month;
      let spendTurn = this.dataSearch.spendTurn;
      let year = this.dataSearch.year;
      this.showAllCashierDialog = true;
      this.$refs.AllCashier.getDataAllCashierInitial(
          month,
          spendTurn,
          this.idInfoList,
          year
      );

    },
    closeAllCashierDialog() {
      this.showAllCashierDialog = false;
      this.$store.commit('employeeSalaryNew/get_status_excel')
    },

    // Mở dialog thêm khoản chi
    handleOpenAddNameExpensesDialog() {
      this.showAddNameExpensesDialog = true
    },

    closeAddNameExpensesDialog() {
      this.showAddNameExpensesDialog = false
    },

  },
};
</script>
<style lang="scss" scoped>

.search-table {
  margin-bottom: 20px;
  z-index: 1;
  display: flex;
  width: 100%;
  gap: 0.3rem;
  flex-wrap: wrap;
}

/deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 36px;
  line-height: 35px;
  border-bottom: 0px solid transparent;
  color: #909399;
  background-color: #67c23a !important;
  border-radius: 4px;
  width: 109%;
}

/deep/ .el-submenu__title {
  font-family: Roboto, serif;
  font-size: 14px;
  color: #303133;
  padding: 0 20px;
  -webkit-transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  box-sizing: border-box;
}

/deep/ .el-submenu__title i {
  color: #fff;
}

/deep/ .el-menu--popup-right-start {
  margin-left: 5px;
  margin-right: 5px;
}

/deep/ .el-menu--horizontal {
  border-right: none;
}

/deep/ .el-menu--horizontal {
  width: auto;
  border-right: none;
  border-radius: 4px;
}

.loading_title {
  font-family: Roboto, serif;
  font-size: 14px;
  color: red;
  margin-left: auto;
  margin-right: 1.5rem;
}

.custom_button {
  font-family: Roboto, serif;
  font-size: 14px;
  margin-left: auto;
  margin-right: 0.5rem;
}

/deep/ .el-loading-spinner .circular {
  height: 33px;
  width: 42px;
  -webkit-animation: loading-rotate 2s linear infinite;
  animation: loading-rotate 2s linear infinite;
}

</style>
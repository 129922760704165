<template>
  <div class="dialog-container">
    <el-dialog
        width="600px"
        title="Thay đổi tên khoản"
        :visible.sync="isOpenChangeNameExpensesDialog"
        :before-close="handleCloseChangeNameExpensesDialog"
        :close-on-click-modal="false"
    >
      <div>
        <el-form
            :model="formData"
            label-position="left"
            ref="formData"
            :rules="rules"
        >
          <el-form-item label="Tên khoản cũ">
            <el-input v-model="nameExpenses" :disabled=true></el-input>
          </el-form-item>
          <el-form-item label="Tên khoản mới" prop="newNameKC">
            <el-input v-model="formData.newNameKC" placeholder="Nhập tên khoản mới"></el-input>
            <span class="error-message">{{ customErrorMessages.newNameKC }}</span>
          </el-form-item>
        </el-form>
        <div class="custom_button">
          <el-button :disabled="checkDelete" size="medium" type="warning" @click="deleteExpenses"
                     style="margin-right: 230px" v-if="showSaveChange">
            <i class="el-icon-delete"/> Xóa khoản
          </el-button>
          <el-button size="medium" :disabled="checkDelete" type="warning" @click="deleteExpenses"
                     style="margin-right: 325px" v-else>
            <i class="el-icon-delete"/> Xóa khoản
          </el-button>
          <el-button
              type="success"
              size="medium"
              :loading="loadingChangeName"
              @click="submitForm"
              v-if="showSaveChange"
          >
            <i class="el-icon-circle-check"/> Lưu
          </el-button>
          <el-button size="medium" type="danger" @click="handleCloseChangeNameExpensesDialog">
            <i class="el-icon-circle-close"/> Đóng
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import {mapState} from "vuex";
import NewSalaryService from "@/services/EmployeeService/NewSalaryService";
import moment from "moment";

export default {
  name: "ChangeNameExpenses",
  data() {
    return {
      showSaveChange: false,
      customErrorMessages: {
        newNameKC: ""
      },
      loadingChangeName: false,
      formData: {
        spendTurn: "",
        date: "",
        newNameKC: "",
        oldNameKC: "",
      },
      rules: {
        newNameKC: [
          {
            validator: this.validateNameChange,
            trigger: "change"
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('employeeSalaryNew', ['dataSearch'])
  },
  props: {
    isOpenChangeNameExpensesDialog: {
      type: Boolean,
      default: () => false
    },
    nameExpenses: {
      type: String,
      default: () => ""
    },
    paySlipNameKT: {
      type: Object
    },
    checkDelete: {
      type: Boolean,
      default: () => true
    }
  },
  methods: {
    validateNameChange(rule, value, callback) {
      for (let i = 1; i <= 20; i++) {
        if (!value) {// Kiểm tra nếu giá trị rỗng
          this.customErrorMessages.newNameKC = "Giá trị không được để trống!";
          this.showSaveChange = false
          break
        } else if (!isNaN(value)) {
          this.showSaveChange = false
          this.customErrorMessages.newNameKC = "Không được nhập tên khoản là các số!";
          break
        } else if (value.toLowerCase().replace(/\s/g, "") === ("Khoản " + i.toString()).toLowerCase().replace(/\s/g, "")) {
          this.showSaveChange = false
          this.customErrorMessages.newNameKC = "Mời bạn nhập khoản khác với khoản mặc định!";
          break
        } else if (i < 10) {
          if (value.toLowerCase().replace(/\s/g, "") === this.paySlipNameKT[`nameK0${i}`].toLowerCase().replace(/\s/g, "")) {
            this.showSaveChange = false
            this.customErrorMessages.newNameKC = "Tên khoản trùng lặp với khoản đã có sẵn!";
            break
          } else {
            this.showSaveChange = true
            this.customErrorMessages.newNameKC = ""; // Xóa thông báo lỗi tùy chỉnh
            callback();
          }
        } else if (i > 9) {
          if (value.toLowerCase().replace(/\s/g, "") === this.paySlipNameKT[`nameK${i}`].toLowerCase().replace(/\s/g, "")) {
            this.showSaveChange = false
            this.customErrorMessages.newNameKC = "Tên khoản trùng lặp với khoản đã có sẵn!";
            break
          } else {
            this.showSaveChange = true
            this.customErrorMessages.newNameKC = ""; // Xóa thông báo lỗi tùy chỉnh
            callback();
          }
        } else {
          this.showSaveChange = true
          this.customErrorMessages.newNameKC = ""; // Xóa thông báo lỗi tùy chỉnh
          callback();
        }
      }
    },
    handleCloseChangeNameExpensesDialog() {
      this.loadingChangeName = false;
      this.customErrorMessages.newNameKC = ""
      this.$refs.formData.resetFields()
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
      this.$emit("closeChangeNameExpensesDialog", false);
    },
    async submitForm() {
      try {
        this.loadingChangeName = true;
        this.formData.date = moment(this.dataSearch.month, "M").format("YYYY-MM-DD");
        this.formData.spendTurn = this.dataSearch.spendTurn
        this.formData.oldNameKC = this.nameExpenses
        await NewSalaryService.changeNameKC(this.formData).then(() => {
          this.$notify.success({
            title: "Thông báo",
            message: "Đổi tên thành công",
          });
          this.handleCloseChangeNameExpensesDialog()
        })
      } catch (error) {
        this.$message({
          message: "Đổi tên thất bại!",
          type: "error",
        });
      } finally {
        this.loadingChangeName = false;
      }
    },

    // Xóa khoản chi
    deleteExpenses() {
      this.$confirm('Thao tác này sẽ xóa khoản chi hiện tại. Tiếp tục?', 'Warning', {
        title: 'Cảnh báo',
        confirmButtonText: 'Có',
        cancelButtonText: 'Không',
        type: 'warning'
      }).then(() => {
        const param = {
          year: this.dataSearch.year,
          month: this.dataSearch.month,
          spendTurn: this.dataSearch.spendTurn
        }
        NewSalaryService.deleteLastExpense(param).then(() => {
          this.$message({
            type: 'success',
            message: 'Xóa khoản chi thành công'
          })
          this.handleCloseChangeNameExpensesDialog()
          ;
        })
      }).catch(() => {
        this.$message({
          type: "warning",
          message: 'Thao tác hủy bỏ thành công'
        });
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.custom_button {
  display: flex;
  justify-content: right;
  margin-top: 30px;
}

.error-message {
  color: red;
}
</style>
<template>
  <div class="dialog-container">
    <el-dialog
        width="700px"
        title="Upload file mẫu Excel công lương"
        :visible.sync="isOpenUploadTemplateEmployeeSalary"
        :before-close="handleCloseTemplateEmployeeSalary"
        :close-on-click-modal="false"
    >
      <div class="upload-section">
        <el-form
            :model="formData"
            label-position="left"
            :rules="rules"
            ref="formData"
        >
          <div class="file-description">
            <el-form-item label="Nhập mô tả" prop="sendDescription">
              <el-input
                  type="textarea"
                  :rows="2"
                  v-model="formData.sendDescription"
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item>
            <div class="file-upload-field">
              <el-upload
                  action = "#"
                  :auto-upload="false"
                  :on-change="toggleUpload"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  ref="upload"
                  accept=".xls,.xlsx"
                  :file-list="formData.fileList"
              >
                <el-button size="small" type="primary" style="font-size: 15px">Upload file <i
                    class="el-icon-upload el-icon-right"></i></el-button>
              </el-upload>
            </div>
          </el-form-item>
        </el-form>
        <div class="buttons">
          <el-button
              :loading="loadingUploadTemplate"
              size="small"
              type="success"
              style="margin-right: 10px"
              @click="handleUpload()"
              v-if="this.formData.fileList.length !== 0 && this.formData.sendDescription !== ''"
          >
            Tải lên
          </el-button>
          <el-button size="small" type="danger" @click="handleCloseTemplateEmployeeSalary">
            <i class="el-icon-circle-close"/> Đóng
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment/moment";
import {mapState} from "vuex";
import NewSalaryService from "@/services/EmployeeService/NewSalaryService";

export default {
  name: "UploadTemplateEmployeeSalary",
  data() {
    return {
      loadingUploadTemplate: false,
      showUploadButton: false,
      year: moment().format("YYYY"),
      formData: {
        sendDescription: "",
        fileList: [],
      },
      rules: {
        sendDescription: [
          {
            required: true,
            message: "Mô tả không được để trống!",
            trigger: "change",
          },
        ],
      },
    };
  },
  props: {
    isOpenUploadTemplateEmployeeSalary: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapState('employeeSalaryNew', ['dataSearch'])
  },

  methods: {
    handleCloseTemplateEmployeeSalary() {
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
      this.$emit("close", false);
      this.clearForm();
    },

    handleRemove(file, fileList) {
      this.showUploadButton = fileList.length === 0;
    },
    handlePreview() {
      console.log(this.formData)
    },
    beforeRemove(file) {
      return new Promise((resolve, reject) => {
        this.$confirm(`Bạn chắc chắn muốn xóa ${file.name} ?`)
            .then(() => {
              this.showUploadButton = false;
              this.$refs.upload.clearFiles();
            })
            .catch(() => {
              reject();
            });
      });
    },
    async handleUpload() {
      this.loadingUploadTemplate = true;
      try {
        if (!this.formData.fileList || this.formData.fileList.length === 0) {
          console.error("Không có file được chọn");
          this.loadingUploadTemplate = false;
          return;
        }
        const formData = new FormData();
        formData.append("description", this.formData.sendDescription);
        for (const file of this.formData.fileList) {
          formData.append("nameFile", file.raw);
        }
        await NewSalaryService.uploadTemplateSample(formData).then((res) => {
          if (res.data.message !== "Tải file thất bại") {
            this.$notify.success({
              title: "Thông báo",
              message: "Upload dữ liệu thành công",
            });
            this.loadingUploadTemplate = false;
          } else {
            this.$message({
              message: "Upload dữ liệu thất bại. Kiểm tra lại cấu trúc trong File!",
              type: "error",
            });
            this.loadingUploadTemplate = false;
          }
        })
        this.loadingUploadTemplate = false;
        this.showUploadButton = false;
        this.$refs.upload.clearFiles();
        this.handleCloseTemplateEmployeeSalary();
      } catch (error) {
        this.$message({
          message: "Upload dữ liệu thất bại. Kiểm tra lại cấu trúc trong File!",
          type: "error",
        });
        this.loadingUploadTemplate = false;
      }
    },

    toggleUpload(file, fileList) {
      this.formData.fileList = fileList.slice(-1);
      this.showUploadButton = fileList.length > 0;
    },

    //   Xóa form
    clearForm() {
      this.showUploadButton = false;
      this.loadingUploadTemplate = false;
      this.$refs.formData.resetFields()
      this.$refs.upload.clearFiles();
    },

  },
};
</script>

<style lang="scss" scoped>
.dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.file-upload-label {
  margin-top: 10px;
  text-align: left;
  margin-bottom: 5px;
}

.file-upload-field {
  display: flex;
  justify-content: left;
}

.buttons {
  display: flex;
  justify-content: right;
  text-align: right;
  margin-top: 10px;
}

.buttons button {
  font-size: 15px;
}

</style>

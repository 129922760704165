<template>
  <el-dialog
      width="700px"
      top="50px"
      :visible.sync="isOpenEmployeeDetailDialog"
      :before-close="handleClose"
      @click="handleOutsideClick"
      :close-on-click-modal="false"
  >
    <div>
      <h2>Thông tin bổ sung</h2>
    </div>
    <h4>
      <el-row>
        <el-col :span="12">Tiêu đề</el-col>
        <el-col :span="3">{{ "Tháng " + dataSearch.month }}</el-col>
        <el-col :span="4" style="margin-left: 13px">{{ monthBefore }}</el-col>
        <el-col :span="2" style="margin-left: 10px">Excel</el-col>
        <el-col :span="2" style="float: right">Đơn vị</el-col>
      </el-row>
    </h4>

    <hr/>
    <el-row>
      <el-col :span="12">Làm việc T2-T6</el-col>
      <el-col :span="3" style="margin-left: 20px">{{ updateFields.workT2t6Total }}</el-col>
      <el-col :span="4" style="margin-left: 7px">{{ updateFields.workT2t6TotalMonthBefore }}</el-col>
      <el-col :span="2" v-if="!isUpdate">{{ updateFields.workT2t6 }}</el-col>
      <el-col :span="2" v-else>
        <el-input
            size="mini" type="number"
            v-model="updateFields.workT2t6"
        >
        </el-input>
      </el-col>
      <el-col :span="2" style="float: right">Ngày</el-col>
    </el-row>
    <hr/>

    <el-row>
      <el-col :span="12">Làm việc T7</el-col>
      <el-col :span="3" style="margin-left: 20px">{{ updateFields.workT7Total }}</el-col>
      <el-col :span="4" style="margin-left: 7px">{{ updateFields.workT7TotalMonthBefore }}</el-col>
      <el-col :span="2" v-if="!isUpdate">{{ updateFields.workT7 }}</el-col>
      <el-col :span="2" v-else>
        <el-input
            size="mini" type="number"
            v-model="updateFields.workT7"
        >
        </el-input>
      </el-col>
      <el-col :span="2" style="float: right">Ngày</el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="12">Làm việc CN</el-col>
      <el-col :span="3" style="margin-left: 20px">{{ updateFields.workCnTotal }}</el-col>
      <el-col :span="4" style="margin-left: 7px">{{ updateFields.workCnTotalMonthBefore }}</el-col>
      <el-col :span="2" v-if="!isUpdate">{{ updateFields.workCn }}</el-col>
      <el-col :span="2" v-else>
        <el-input
            size="mini" type="number"
            v-model="updateFields.workCn"
        >
        </el-input>
      </el-col>
      <el-col :span="2" style="float: right">Ngày</el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="12">Nghỉ có phép (T2-T6)</el-col>
      <el-col :span="3" style="margin-left: 20px">{{ updateFields.absentCpT2t6Total }}</el-col>
      <el-col :span="4" style="margin-left: 7px">{{ updateFields.absentCpT2t6TotalMonthBefore }}</el-col>
      <el-col :span="2" v-if="!isUpdate">{{ updateFields.absentCpT2t6 }}
      </el-col>
      <el-col :span="2" v-else>
        <el-input
            size="mini" type="number"
            v-model="updateFields.absentCpT2t6"
        >
        </el-input>
      </el-col>
      <el-col :span="2" style="float: right">Ngày</el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="12">Nghỉ không phép (T2-T6)</el-col>
      <el-col :span="3" style="margin-left: 20px">{{ updateFields.absentKpT2t6Total }}</el-col>
      <el-col :span="4" style="margin-left: 7px">{{ updateFields.absentKpT2t6TotalMonthBefore }}</el-col>
      <el-col :span="2" v-if="!isUpdate">{{ updateFields.absentKpT2t6 }}
      </el-col>
      <el-col :span="2" v-else>
        <el-input
            size="mini" type="number"
            v-model="updateFields.absentKpT2t6"
        >
        </el-input>
      </el-col>
      <el-col :span="2" style="float: right">Ngày</el-col>
    </el-row>

    <hr/>
    <el-row>
      <el-col :span="12">Nghỉ có phép (T7)</el-col>
      <el-col :span="3" style="margin-left: 20px">{{ updateFields.absentCpT7Total }}</el-col>
      <el-col :span="4" style="margin-left: 7px">{{ updateFields.absentCpT7TotalMonthBefore }}</el-col>
      <el-col :span="2" v-if="!isUpdate">{{ updateFields.absentCpT7 }}
      </el-col>
      <el-col :span="2" v-else>
        <el-input
            size="mini" type="number"
            v-model="updateFields.absentCpT7"
        >
        </el-input>
      </el-col>
      <el-col :span="2" style="float: right">Ngày</el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="12">Nghỉ không phép (T7)</el-col>
      <el-col :span="3" style="margin-left: 20px">{{ updateFields.absentKpT7Total }}</el-col>
      <el-col :span="4" style="margin-left: 7px">{{ updateFields.absentKpT7TotalMonthBefore }}</el-col>
      <el-col :span="2" v-if="!isUpdate">{{ updateFields.absentKpT7 }}
      </el-col>
      <el-col :span="2" v-else>
        <el-input
            size="mini" type="number"
            v-model="updateFields.absentKpT7"
        >
        </el-input>
      </el-col>
      <el-col :span="2" style="float: right">Ngày</el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="12">Ca tối</el-col>
      <el-col :span="3" style="margin-left: 20px">{{ updateFields.quantityEveningTotal }}</el-col>
      <el-col :span="4" style="margin-left: 7px">{{ updateFields.quantityEveningTotalMonthBefore }}</el-col>
      <el-col :span="2" v-if="!isUpdate">{{ updateFields.quantityEvening }}
      </el-col>
      <el-col :span="2" v-else>
        <el-input
            size="mini" type="number"
            v-model="updateFields.quantityEvening"
        >
        </el-input>
      </el-col>
      <el-col :span="2" style="float: right">Buổi</el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="12">Trông muộn</el-col>
      <el-col :span="3" style="margin-left: 20px">{{ updateFields.quantityTakeCareLateTotal }}</el-col>
      <el-col :span="4" style="margin-left: 7px">{{ updateFields.quantityTakeCareLateTotalMonthBefore }}</el-col>
      <el-col :span="2" v-if="!isUpdate">{{ updateFields.quantityTakeCareLate }}
      </el-col>
      <el-col :span="2" v-else>
        <el-input
            size="mini" type="number"
            v-model="updateFields.quantityTakeCareLate"
        >
        </el-input>
      </el-col>
      <el-col :span="2" style="float: right">Lần</el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="12">Thêm giờ</el-col>
      <el-col :span="3" style="margin-left: 20px">{{ updateFields.quantityOvertimeTotal }}</el-col>
      <el-col :span="4" style="margin-left: 7px">{{ updateFields.quantityOvertimeTotalMonthBefore }}</el-col>
      <el-col :span="2" v-if="!isUpdate">{{ updateFields.quantityOvertime }}
      </el-col>
      <el-col :span="2" v-else>
        <el-input
            size="mini" type="number"
            v-model="updateFields.quantityOvertime"
        >
        </el-input>
      </el-col>
      <el-col :span="2" style="float: right">Giờ</el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="12">Đi muộn</el-col>
      <el-col :span="3" style="margin-left: 20px">{{ updateFields.quantityComeLateTotal }}</el-col>
      <el-col :span="4" style="margin-left: 7px">{{ updateFields.quantityComeLateTotalMonthBefore }}</el-col>
      <el-col :span="2" v-if="!isUpdate">{{ updateFields.quantityComeLate }}</el-col>
      <el-col :span="2" v-else>
        <el-input
            size="mini" type="number"
            v-model="updateFields.quantityComeLate"
        >
        </el-input>
      </el-col>
      <el-col :span="2" style="float: right">Giờ</el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="12">Về sớm</el-col>
      <el-col :span="3" style="margin-left: 20px">{{ updateFields.quantityBackSoonTotal }}</el-col>
      <el-col :span="4" style="margin-left: 7px">{{ updateFields.quantityBackSoonTotalMonthBefore }}</el-col>
      <el-col :span="2" v-if="!isUpdate">{{ updateFields.quantityBackSoon }}
      </el-col>
      <el-col :span="2" v-else>
        <el-input
            size="mini" type="number"
            v-model="updateFields.quantityBackSoon"
        >
        </el-input>
      </el-col>
      <el-col :span="2" style="float: right">Giờ</el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="12">Số ngày theo lịch hàng tháng</el-col>
      <el-col :span="3" style="margin-left: 20px">{{ updateFields.quantityDateTotal }}</el-col>
      <el-col :span="4" style="margin-left: 7px">{{ updateFields.quantityDateTotalMonthBefore }}</el-col>
      <el-col :span="2" v-if="!isUpdate">{{ updateFields.quantityDate }}
      </el-col>
      <el-col :span="2" v-else>
        <el-input
            size="mini" type="number"
            v-model="updateFields.quantityDate"
        >
        </el-input>
      </el-col>
      <el-col :span="2" style="float: right">Ngày</el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="14">(1) Ghi chú nhà trường:</el-col>
      <el-col :offset="1" v-if="!isUpdate">
        <i style="white-space: pre-line">{{ updateFields.noteExcel1 }}</i>
      </el-col>
      <el-col :offset="1" :span="23" v-else>
        <el-input
            size="mini" placeholder="Nhập tại đây" v-model="updateFields.noteExcel1" type="textarea"
        >
        </el-input>
      </el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="18">(2) Ghi chú trên phiếu lương:</el-col>
      <el-col :offset="1" v-if="!isUpdate">
        <i style="white-space: pre-line">{{ updateFields.noteExcel2 }}</i>
      </el-col>
      <el-col :offset="1" :span="23" v-else>
        <el-input
            size="mini"
            placeholder="Nhập tại đây"
            v-model="updateFields.noteExcel2" type="textarea"
        >
        </el-input>
      </el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="14">(3) Ghi chú khác:</el-col>
      <el-col :offset="1" v-if="!isUpdate">
        <i style="white-space: pre-line">{{ updateFields.noteExcel3 }}</i>
      </el-col>
      <el-col :offset="1" :span="23" v-else>
        <el-input
            size="mini"
            placeholder="Nhập tại đây"
            v-model="updateFields.noteExcel3" type="textarea"
        >
        </el-input>
      </el-col>
    </el-row>
    <hr/>
    <el-row>
      <el-col :span="14">Mô tả đợt chi :</el-col>
      <el-col :offset="1" v-if="!isUpdate">
        <i style="white-space: pre-line">{{ updateFields.descriptionTurn }}</i>
      </el-col>
      <el-col :offset="1" :span="23" v-else>
        <el-input
            size="mini"
            placeholder="Nhập tại đây"
            v-model="updateFields.descriptionTurn" type="textarea"
        >
        </el-input>
      </el-col>
    </el-row>
    <hr/>
    <div slot="footer" class="dialog-footer" style="text-align: right">
      <el-button size="medium" type="primary" @click="saveInfoAttendance" v-show="isEditing">Cập nhật</el-button>
      <el-button size="medium" type="success" @click="handleUpdate" v-show="!isEditing"
                 v-if="this.statusOrder !== 'Ẩn hóa đơn'">Sửa
      </el-button>
      <el-button size="medium" type="danger" @click="handleClose"><i class="el-icon-circle-close"/> Đóng</el-button>
    </div>
  </el-dialog>
</template>
<script>
import {mapState} from "vuex";
import NewSalaryService from "@/services/EmployeeService/NewSalaryService";


export default {
  name: "ExcelEmployeeSalaryDetail",

  computed: {
    ...mapState("employeeSalaryNew", ["dataSearch"]),
    formattedNote() {
      // Thay thế ký tự "/n" bằng thẻ <br> để tạo các dòng mới
      return this.updateFields.noteExcel2.replace('/n', '<br>');
    },
  },

  props: {
    kidsDetailData: {
      type: Object,
      default: () => {
      },
    },
    isOpenEmployeeDetailDialog: {
      type: Boolean,
      default: () => false,
    },
  },


  data() {
    return {
      isUpdate: false,
      isEditing: false,
      updateFields: {
        workT2t6: 0,
        workT7: 0,
        workCn: 0,
        absentCpT2t6: 0,
        absentKpT2t6: 0,
        absentCpT7: 0,
        absentKpT7: 0,
        quantityDate: 0,
        noteExcel1: "",
        noteExcel2: "",
        noteExcel3: "",
        workT2t6Total: 0,
        workT7Total: 0,
        workCnTotal: 0,
        absentCpT2t6Total: 0,
        absentKpT2t6Total: 0,
        absentCpT7Total: 0,
        absentKpT7Total: 0,
        quantityDateTotal: 0,
        descriptionTurn: '',
        quantityOvertime: 0,
        quantityComeLate: 0,
        quantityBackSoon: 0,
        quantityEvening: 0,
        quantityTakeCareLate: 0,
        quantityComeLateTotal: 0,
        quantityOvertimeTotal: 0,
        quantityBackSoonTotal: 0,
        quantityEveningTotal: 0,
        quantityTakeCareLateTotal: 0,
        workT2t6TotalMonthBefore: 0,
        workT7TotalMonthBefore: 0,
        workCnTotalMonthBefore: 0,
        absentCpT2t6TotalMonthBefore: 0,
        absentKpT2t6TotalMonthBefore: 0,
        absentCpT7TotalMonthBefore: 0,
        absentKpT7TotalMonthBefore: 0,
        quantityDateTotalMonthBefore: 0,
        quantityComeLateTotalMonthBefore: 0,
        quantityOvertimeTotalMonthBefore: 0,
        quantityBackSoonTotalMonthBefore: 0,
        quantityEveningTotalMonthBefore: 0,
        quantityTakeCareLateTotalMonthBefore: 0,
      },
      dataResp: {
        date: "",
        idInfo: 0,
        spendTurn: 0,
        workT2t6: 0,
        workT7: 0,
        workCn: 0,
        absentCpT2t6: 0,
        absentKpT2t6: 0,
        absentCpT7: 0,
        absentKpT7: 0,
        quantityComeLate: 0,
        quantityDate: 0,
        quantityOvertime: 0,
        quantityBackSoon: 0,
        quantityEvening: 0,
        quantityTakeCareLate: 0,
        noteExcel1: "",
        noteExcel2: "",
        noteExcel3: "",
        descriptionTurn: "",
      },
      idInfo: 0,
      spendTurn: 0,
      statusOrder: "",
      monthCurrent: "",
      monthBefore: "",
    };

  },
  methods: {


    getInfoByMonthAndTurn(month, idInfo, spendTurn, date) {

      /**
       * Tháng trước
       */
      if (month == 1) {
        this.monthBefore = "Tháng 12/" + (parseInt(this.dataSearch.year) - 1);
      } else {
        this.monthBefore = "Tháng " + (month - 1);
      }
      this.idInfo = idInfo
      this.spendTurn = spendTurn
      const param = {
        idInfo: idInfo,
        spendTurn: spendTurn,
        date: date
      }
      NewSalaryService.displaysAdditionalEmployeeInformation(param).then((resp) => {
        this.updateFields.workT2t6 = resp.data.workT2t6;
        this.updateFields.workT7 = resp.data.workT7;
        this.updateFields.workCn = resp.data.workCn;
        this.updateFields.absentCpT2t6 = resp.data.absentCpT2t6;
        this.updateFields.absentKpT2t6 = resp.data.absentKpT2t6;
        this.updateFields.absentCpT7 = resp.data.absentCpT7;
        this.updateFields.absentKpT7 = resp.data.absentKpT7;
        this.updateFields.quantityComeLate = resp.data.quantityComeLate;
        this.updateFields.quantityDate = resp.data.quantityDate;
        this.updateFields.noteExcel1 = resp.data.noteExcel1;
        this.updateFields.noteExcel2 = resp.data.noteExcel2;
        this.updateFields.noteExcel3 = resp.data.noteExcel3;
        this.updateFields.workT2t6Total = resp.data.workT2t6Total;
        this.updateFields.workT7Total = resp.data.workT7Total;
        this.updateFields.workCnTotal = resp.data.workCnTotal;
        this.updateFields.absentCpT2t6Total = resp.data.absentCpT2t6Total;
        this.updateFields.absentKpT2t6Total = resp.data.absentKpT2t6Total;
        this.updateFields.absentCpT7Total = resp.data.absentCpT7Total;
        this.updateFields.absentKpT7Total = resp.data.absentKpT7Total;
        this.updateFields.quantityComeLateTotal = resp.data.quantityComeLateTotal;
        this.updateFields.quantityDateTotal = resp.data.quantityDateTotal;
        this.updateFields.descriptionTurn = resp.data.descriptionTurn;
        this.updateFields.workT2t6TotalMonthBefore = resp.data.workT2t6TotalMonthBefore;
        this.updateFields.workT7TotalMonthBefore = resp.data.workT7TotalMonthBefore;
        this.updateFields.workCnTotalMonthBefore = resp.data.workCnTotalMonthBefore;
        this.updateFields.absentCpT2t6TotalMonthBefore = resp.data.absentCpT2t6TotalMonthBefore;
        this.updateFields.absentKpT2t6TotalMonthBefore = resp.data.absentKpT2t6TotalMonthBefore;
        this.updateFields.absentCpT7TotalMonthBefore = resp.data.absentCpT7TotalMonthBefore;
        this.updateFields.absentKpT7TotalMonthBefore = resp.data.absentKpT7TotalMonthBefore;
        this.updateFields.quantityComeLateTotalMonthBefore = resp.data.quantityComeLateTotalMonthBefore;
        this.updateFields.quantityDateTotalMonthBefore = resp.data.quantityDateTotalMonthBefore;
        this.updateFields.quantityEveningTotal = resp.data.quantityEveningTotal
        this.updateFields.quantityEvening = resp.data.quantityEvening
        this.updateFields.quantityOvertime = resp.data.quantityOvertime
        this.updateFields.quantityTakeCareLate = resp.data.quantityTakeCareLate
        this.updateFields.quantityBackSoon = resp.data.quantityBackSoon
        this.updateFields.quantityOvertimeTotal = resp.data.quantityOvertimeTotal
        this.updateFields.quantityBackSoonTotal = resp.data.quantityBackSoonTotal
        this.updateFields.quantityEveningTotal = resp.data.quantityEveningTotal
        this.updateFields.quantityTakeCareLateTotal = resp.data.quantityTakeCareLateTotal
        this.updateFields.quantityOvertimeTotalMonthBefore = resp.data.quantityOvertimeTotalMonthBefore
        this.updateFields.quantityBackSoonTotalMonthBefore = resp.data.quantityBackSoonTotalMonthBefore
        this.updateFields.quantityEveningTotalMonthBefore = resp.data.quantityEveningTotalMonthBefore
        this.updateFields.quantityTakeCareLateTotalMonthBefore = resp.data.quantityTakeCareLateTotalMonthBefore
      })
    },

    saveInfoAttendance() {
      this.dataResp.date = this.$funcCommon.formatMY(this.dataSearch.year, this.dataSearch.month)
      this.dataResp.idInfo = this.idInfo;
      this.dataResp.spendTurn = this.spendTurn;
      this.dataResp.workT2t6 = this.updateFields.workT2t6;
      this.dataResp.workT7 = this.updateFields.workT7;
      this.dataResp.workCn = this.updateFields.workCn;
      this.dataResp.absentCpT2t6 = this.updateFields.absentCpT2t6;
      this.dataResp.absentKpT2t6 = this.updateFields.absentKpT2t6;
      this.dataResp.absentCpT7 = this.updateFields.absentCpT7;
      this.dataResp.absentKpT7 = this.updateFields.absentKpT7;
      this.dataResp.quantityComeLate = this.updateFields.quantityComeLate;
      this.dataResp.quantityDate = this.updateFields.quantityDate;
      this.dataResp.noteExcel1 = this.updateFields.noteExcel1;
      this.dataResp.noteExcel2 = this.updateFields.noteExcel2;
      this.dataResp.noteExcel3 = this.updateFields.noteExcel3;
      this.dataResp.quantityOvertime = this.updateFields.quantityOvertime;
      this.dataResp.quantityBackSoon = this.updateFields.quantityBackSoon;
      this.dataResp.quantityEvening = this.updateFields.quantityEvening;
      this.dataResp.quantityTakeCareLate = this.updateFields.quantityTakeCareLate;
      this.dataResp.descriptionTurn = this.updateFields.descriptionTurn;
      this.$confirm("Bạn có chắc chắn muốn lưu thay đổi ?", "Thông báo !", {
        distinguishCancelAndClose: true,
        closeOnClickModal: false,
        confirmButtonText: "Có",
        cancelButtonText: "Không",
      }).then(() => {
        NewSalaryService.updateAdditionalEmployeeInformation(this.dataResp).then(() => {
          this.$message({
            message: "Cập nhật thành công",
            type: "success",
          });
          this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
        })
            .catch((err) => {
              this.$message({
                message: err.response.data.message,
                type: "error",
              });
            });
      });
      this.handleClose();
    },

    handleClose() {
      this.isEditing = false;
      this.isUpdate = false;
      this.$emit("closeDetailKid", false);
    },
    handleUpdate() {
      this.isEditing = true;
      this.isUpdate = !this.isUpdate;
    },
    // Kiểm tra xem sự kiện bấm chuột có xảy ra trong dialog hay không
    handleOutsideClick(event) {
      if (!this.$el.contains(event.target)) {
        this.isUpdate = false;
      }
    },

  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input--mini .el-input__inner {
  padding-left: 0;
  font-size: 14px;
  font-family: Roboto, serif;
  height: 15px;
  line-height: 28px;
  border: none;
  color: #3a3ace;
}

h2 {
  padding-bottom: 10px;
}
</style>

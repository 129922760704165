<template>
  <el-dialog
      :title="'Điều chỉnh tiền đã chi '"
      :visible.sync="isOpenCollectedMoney"
      width="800px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      top="5vh"
  >
    <el-form :model="dataResp" :rules="rules"  ref="ruleForm" :label-position="labelPosition">
      <el-form-item label="Tiền phải chi" :label-width="'170px'">
        <el-currency-input
            v-model="form.sumMoney"
            id="sumMoney"
            autocomplete="off"
            readonly
            type="number"
            :disabled="true"
        ></el-currency-input>
      </el-form-item>
      <el-form-item v-if="beforeMoney >= 0" label="Tiền thừa tháng trước" :label-width="'170px'"  prop="beforeMoney">
        <el-currency-input
            v-model="beforeMoney"
            id="beforeMoney"
            autocomplete="off"
            :options="customOption"
            :disabled="isDisabled"
        ></el-currency-input>
      </el-form-item>
      <el-form-item v-else label="Tiền thiếu tháng trước" :label-width="'170px'"  prop="beforeMoney">
        <el-currency-input
            v-model="beforeMoney"
            id="beforeMoney"
            autocomplete="off"
            :options="customOption"
            :disabled="isDisabled"
        ></el-currency-input>
      </el-form-item>
      <el-form-item label="Tiền mặt" :label-width="'170px'" prop="cashMoney">
        <el-currency-input
            v-model="cashMoney"
            autocomplete="off"
            id="cashMoney"
            type="number"
            :options="customOption"
            :disabled="isDisabled"
        ></el-currency-input>
      </el-form-item>
      <el-form-item label="Chuyển khoản" :label-width="'170px'" prop="transferMoney">
        <el-currency-input
            v-model="transferMoney"
            autocomplete="off"
            id="transferMoney"
            type="number"
            :options="customOption"
            :disabled="isDisabled"
        ></el-currency-input>
      </el-form-item>
      <el-form-item label="Tiền đã chi" :label-width="'170px'">
        <template>
          <el-currency-input
              v-model="form.spendMoney"
              autocomplete="off"
              id="spendMoney"
              type="number"
              :options="customOption"
              :disabled="true"
          ></el-currency-input>
        </template>
      </el-form-item>
      <el-form-item v-if="form.afterMoney >= 0" label="Số tiền còn thừa" :label-width="'170px'">
        <el-currency-input
            v-model="afterMoneyMath"
            id="afterMoney"
            autocomplete="off"
            type="number"
            :options="customOption"
            :disabled="true"
        ></el-currency-input>
      </el-form-item>
      <el-form-item v-if="form.afterMoney < 0" label="Số tiền còn thiếu" :label-width="'170px'">
        <el-currency-input
            v-model="afterMoneyMath"
            id="afterMoney"
            autocomplete="off"
            type="number"
            :options="customOption"
            :disabled="true"
        ></el-currency-input>
      </el-form-item>
      <el-form-item label="Lịch sử ghi chú" :label-width="'170px'">
        <el-input
            v-model="form.noteExcel3"
            autocomplete="off"
            id="noteExcel3"
            type="textarea"
            :rows="3"
            :disabled="true"
        ></el-input>
      </el-form-item>
      <el-form-item
          label="Ghi chú"
          :label-width="'170px'"
          v-if="this.statusSalary !== 'Ẩn phiếu lương'"
      >
        <el-input
            v-model="noteExcel4"
            autocomplete="off"
            id="noteExcel4"
            :rows="3"
            type="textarea"
            placeholder="Nhập thông tin cần chỉnh sửa"
            :disabled="isDisabled"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="modifyCollectedMoney" v-show="isEditing" :loading="loadingButton"
      >Cập nhật
      </el-button>
      <el-button
          type="success"
          v-show="!isEditing"
          @click="editForm"
          v-if="this.statusSalary !== 'Ẩn phiếu lương'"
      >Sửa</el-button
      >
      <el-button type="danger"  @click="handleClose"
      > <i class="el-icon-circle-close"/> Đóng</el-button
      >
    </span>
  </el-dialog>

</template>

<script>
import {mapState} from "vuex";
import checkPermission from "@/utils/permission";
import NewSalaryService from "@/services/EmployeeService/NewSalaryService";

export default {
  name: "CollectedMoneyEmployeeSalary",

  computed: {
    ...mapState("employeeSalaryNew", ["responseFilterList", "dataSearch"]),
  },

  data() {
    return {
      loadingButton: false,
      customOption: {currency: "VND", locale: "vn", allowNegative: true},
      labelPosition: "left",
      noteExcel4: "",
      isDisabled: true,
      isEditing: false,
      rules:{
        beforeMoney: [
          { required: true, message: 'Giá trị không được để trống', trigger: 'blur' },
        ],
        cashMoney: [
          { required: true, message: 'Giá trị không được để trống', trigger: 'blur' },
        ],
        transferMoney: [
          { required: true, message: 'Giá trị không được để trống', trigger: 'blur' },
        ],
      },
      isReadOnly: true,
      form: {
        sumMoney: 0,
        noteExcel3: "",
        spendMoney: 0,
        afterMoney: 0,
      },
      beforeMoney: 0,
      transferMoney: 0,
      cashMoney: 0,

      /**
       * Phần nguyên tiền đã thu
       */

      afterMoneyMath: 0,
      dataResp: {
        date: "",
        idInfo: "",
        spendTurn: "",
        spendMoney: "",
        cashMoney: 0,
        transferMoney: 0,
        beforeMoney: 0,
        afterMoney: "",
        noteExcel3: "",
      },
      idInfo: Number,
      spendTurn: Number,
      statusSalary: "",
      isBeforeMoney: "",
      old: {
        cashMoney: "",
        transferMoney: "",
        beforeMoney: "",
      }
    };
  },

  watch: {
    /**
     * Theo dõi thay đổi tiền mặt, chuyển khoản
     * @param newValue
     */
    beforeMoney(newValue) {
      this.beforeMoney = newValue;
      this.dataResp.beforeMoney = this.beforeMoney
      this.total();
    },
    cashMoney(newValue) {
      this.cashMoney = newValue;
      this.dataResp.cashMoney = this.cashMoney
      this.total();
    },
    transferMoney(newValue) {
      this.transferMoney = newValue;
      this.dataResp.transferMoney = this.transferMoney
      this.total();
    },
  },

  props: {
    isOpenCollectedMoney: {
      type: Boolean,
      default: () => false, // Set a default value for the prop
    },
  },

  methods: {
    checkPermission,
    total() {
      this.form.spendMoney = this.cashMoney + this.transferMoney;
      this.dataResp.beforeMoney = this.beforeMoney;
      this.form.afterMoney =
          this.form.spendMoney -
          this.form.sumMoney +
          this.dataResp.beforeMoney;

      if (this.form.afterMoney < 0) {
        this.afterMoneyMath = -this.form.afterMoney;
      } else {
        this.afterMoneyMath = this.form.afterMoney;
      }
    },

    handleChangeRadio() {
      this.beforeMoney = 0;
    },

    //  Lấy thông tin hiển thị dialog
    getCollectedMoney(idInfo, spendTurn, statusSalary, date) {
      this.statusSalary = statusSalary;
      this.idInfo = idInfo
      this.spendTurn = spendTurn
      const param = {
        date: date,
        idInfo: idInfo,
        spendTurn: spendTurn
      }
      NewSalaryService.displaysTheAmountSpent(param).then((resp) => {
        this.form.sumMoney = resp.data.sumMoney;
        this.beforeMoney = resp.data.beforeMoney;
        this.cashMoney = resp.data.cashMoney;
        this.transferMoney = resp.data.transferMoney;
        this.form.noteExcel3 = resp.data.noteExcel3;
        this.form.spendMoney = resp.data.spendMoney;
        this.form.afterMoney = resp.data.afterMoney;


        if (this.form.afterMoney < 0) {
          this.afterMoneyMath = -this.form.afterMoney;
        } else {
          this.afterMoneyMath = this.form.afterMoney;
        }

        this.old.beforeMoney = resp.data.beforeMoney;
        this.old.cashMoney = resp.data.cashMoney;
        this.old.transferMoney = resp.data.transferMoney;
        this.old.noteExcel3 = resp.data.noteExcel3;
      });
    },

    modifyCollectedMoney() {
      this.$refs.ruleForm.validate((valid) =>{
        if (valid){
          this.loadingButton = true
          this.dataResp.date = this.$funcCommon.formatMY(this.dataSearch.year, this.dataSearch.month)
          this.dataResp.idInfo = this.idInfo;
          this.dataResp.spendTurn = this.spendTurn;
          this.dataResp.spendMoney = this.form.spendMoney;
          this.dataResp.cashMoney = this.cashMoney;
          this.dataResp.transferMoney = this.transferMoney;
          this.dataResp.afterMoney = this.form.afterMoney;
          this.dataResp.beforeMoney = this.beforeMoney;
          this.noteExcel4 += "\n";
          this.dataResp.noteExcel3 = this.noteExcel4;

          if (this.dataResp.beforeMoney === this.old.beforeMoney && this.dataResp.cashMoney === this.old.cashMoney &&
              this.dataResp.transferMoney === this.old.transferMoney) {
            this.$message({
              message: "Chưa thay đổi tiền thanh toán!",
              type: "error",
            });
            this.loadingButton = false
          } else {
            this.$confirm("Bạn có chắc chắn muốn lưu thay đổi ?", "Thông báo !", {
              confirmButtonText: "Có",
              cancelButtonText: "Không",
            }).then(() => {
              NewSalaryService.updateEmployeeSalarySlips(this.dataResp)
                  .then(() => {
                    this.noteExcel4 = ""
                    this.$message({
                      message: "Cập nhật thành công",
                      type: "success",
                    });
                    this.loadingButton = false
                  })
                  .then(() => {
                    this.$store.commit('employeeSalaryNew/get_status_excel')
                    this.handleClose()
                  })
                  .catch((err) => {
                    this.$message({
                      message: err.response.data.message,
                      type: "error",
                    });
                    this.loadingButton = false
                  });
            }).catch(() => {
              this.loadingButton = false
            });
          }
        }else {
          this.loadingButton = false
        }
      })

    },

    handleClose() {
      this.noteExcel4 = ""
      this.loadingButton = false
      this.isDisabled = true;
      this.isEditing = false;
      this.$refs.ruleForm.resetFields();
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
      this.$emit("closeCollectedMoney", false);
    },

    editForm() {
      this.isDisabled = false;
      this.isEditing = true;
    },
  },
};
</script>

<style scoped></style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Tạo ghi chú nhân viên ",
        visible: _vm.isOpenCreateNote,
        width: "800px",
        "before-close": _vm.handleClose,
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.isOpenCreateNote = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "formNote",
          attrs: { model: _vm.formNote, "label-position": _vm.labelPosition },
        },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { "text-align": "left" },
              attrs: {
                label: "(1) Ghi chú nhà trường",
                "label-width": "160px",
              },
            },
            [
              _c("el-input", {
                staticStyle: { "text-align": "center" },
                attrs: {
                  id: "noteExcel1",
                  autocomplete: "off",
                  type: "textarea",
                  rows: 2,
                  placeholder: "Nhập ghi chú tại đây",
                },
                model: {
                  value: _vm.formNote.noteExcel1,
                  callback: function ($$v) {
                    _vm.$set(_vm.formNote, "noteExcel1", $$v)
                  },
                  expression: "formNote.noteExcel1",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "text-align": "left" },
              attrs: {
                label: "(2) Ghi chú phiếu lương",
                "label-width": "160px",
              },
            },
            [
              _c("el-input", {
                staticStyle: { "text-align": "center" },
                attrs: {
                  autocomplete: "off",
                  id: "noteExcel2",
                  type: "textarea",
                  rows: 2,
                  placeholder: "Nhập ghi chú tại đây",
                },
                model: {
                  value: _vm.formNote.noteExcel2,
                  callback: function ($$v) {
                    _vm.$set(_vm.formNote, "noteExcel2", $$v)
                  },
                  expression: "formNote.noteExcel2",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "text-align": "left" },
              attrs: { label: "(3) Ghi chú khác", "label-width": "160px" },
            },
            [
              _c("el-input", {
                staticStyle: { "text-align": "center" },
                attrs: {
                  autocomplete: "off",
                  id: "noteExcel3",
                  type: "textarea",
                  rows: 2,
                  placeholder: "Nhập ghi chú tại đây",
                },
                model: {
                  value: _vm.formNote.noteExcel3,
                  callback: function ($$v) {
                    _vm.$set(_vm.formNote, "noteExcel3", $$v)
                  },
                  expression: "formNote.noteExcel3",
                },
              }),
            ],
            1
          ),
          _c("el-form-item", [
            _c(
              "span",
              { staticStyle: { "margin-left": "160px", color: "red" } },
              [
                _vm._v(
                  "* Tất cả ghi chú của nhân viên được chọn sẽ bị ghi đè bằng ghi chú mới."
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "success", loading: _vm.loadingNote },
              on: { click: _vm.saveNoteExcel },
            },
            [_vm._v("Tạo ghi chú ")]
          ),
          _c(
            "el-button",
            { attrs: { type: "danger" }, on: { click: _vm.handleClose } },
            [_c("i", { staticClass: "el-icon-circle-close" }), _vm._v(" Đóng")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
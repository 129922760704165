var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-content row-data" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingData,
              expression: "loadingData",
            },
          ],
          attrs: {
            "element-loading-spinner": "el-icon-loading",
            "empty-text": _vm.textTable,
            data: _vm.responseFilterList,
            "element-loading-text": _vm.$tableLoading,
            "element-loading-background": "rgba(255,255,255, 0)",
            "header-cell-style": _vm.tableHeaderColor,
            "max-height": _vm.$tableMaxHeight,
            border: "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", align: "center", width: "55" },
          }),
          _c("el-table-column", {
            attrs: {
              type: "index",
              fixed: "left",
              label: "STT",
              width: "50",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: { width: "205", fixed: "left", label: "Họ tên" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-tooltip", { attrs: { placement: "top" } }, [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v("Mã nhân viên: " + _vm._s(scope.row.code)),
                          _c("br"),
                          _vm._v("SĐT: " + _vm._s(scope.row.phone)),
                        ]
                      ),
                      _c("span", [_vm._v(_vm._s(scope.row.fullName))]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "145", label: "Số tài khoản", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-tooltip", { attrs: { placement: "top" } }, [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [_vm._v("Ngân hàng: " + _vm._s(scope.row.nameBank))]
                      ),
                      _c("span", [_vm._v(_vm._s(scope.row.bankAccountNumber))]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", width: "70", label: "Hiển thị" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-checkbox", {
                      attrs: {
                        disabled:
                          scope.row.statusSalary === null ||
                          scope.row.statusSalary === "Ẩn phiếu lương",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.handelShowOrHidden(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.orderHidden,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "orderHidden", $$v)
                        },
                        expression: "scope.row.orderHidden",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin bổ sung", align: "center", width: "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleOpenEmployeeDetail(scope.row)
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-document" })]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", width: "100", label: "Tồn phiếu lương" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.count !== 0
                      ? _c(
                          "el-button",
                          {
                            staticClass: "click-in-row",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.inventoryOrder(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.count) + "\n        ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "120",
              label: "Trạng thái phiếu lương",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("div", { class: _vm.getStatusColor(row.statusSalary) }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.getStatusValue(row.statusSalary)) +
                          "\n        "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "120",
              label: "Chuyển thanh toán",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.transactionEntry === "MONEY_WALLET" &&
                    scope.row.afterMoney !== 0
                      ? _c("span", { staticStyle: { color: "orange" } }, [
                          _vm._v("Không chuyển"),
                        ])
                      : _vm._e(),
                    scope.row.transactionEntry === "MONEY_MONTH" &&
                    scope.row.afterMoney !== 0
                      ? _c("span", { staticStyle: { color: "blue" } }, [
                          _vm._v("Sang tháng sau"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "right",
              width: "110",
              label: "Thiếu/Thừa tháng trước",
              prop: "beforeMoney",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("formatCurrency")(row.beforeMoney)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "right",
              width: "110",
              label: "Phải chi tháng này",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("formatCurrency")(row.sumMoney)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "right", width: "115", label: "Đã chi" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.getStatusValue(scope.row.statusSalary) !==
                    "Chưa có phiếu lương"
                      ? _c(
                          "el-button",
                          {
                            staticClass: "click-in-row",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.collectedMoneyMethod(
                                  scope.row,
                                  scope.row.statusSalary
                                )
                              },
                            },
                          },
                          [
                            scope.row.spendMoney
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrency")(
                                        scope.row.spendMoney
                                      )
                                    )
                                  ),
                                ])
                              : _c("span", [_c("b", [_vm._v("-")])]),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "right", width: "115", label: "Tiền mặt" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("formatCurrency")(row.cashMoney)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "right", width: "115", label: "Chuyển khoản" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("formatCurrency")(row.transferMoney)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "right",
              width: "115",
              label: "Thiếu / Thừa còn lại",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("formatCurrency")(row.afterMoney)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm.paySlipNameKT.nameK01 !== "Khoản 1"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.paySlipNameKT.nameK01,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameExpenses(
                                        _vm.paySlipNameKT.nameK01
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.paySlipNameKT.nameK01))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.statusSalary) !==
                          "Chưa có phiếu lương"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.statusSalary
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyK01
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyK01
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2892007105
                ),
              })
            : _vm._e(),
          _vm.paySlipNameKT.nameK02 !== "Khoản 2"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.paySlipNameKT.nameK02,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameExpenses(
                                        _vm.paySlipNameKT.nameK02
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.paySlipNameKT.nameK02))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.statusSalary) !==
                          "Chưa có phiếu lương"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.statusSalary
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyK02
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyK02
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1512094369
                ),
              })
            : _vm._e(),
          _vm.paySlipNameKT.nameK03 !== "Khoản 3"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.paySlipNameKT.nameK03,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameExpenses(
                                        _vm.paySlipNameKT.nameK03
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.paySlipNameKT.nameK03))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.statusSalary) !==
                          "Chưa có phiếu lương"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.statusSalary
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyK03
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyK03
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2913757441
                ),
              })
            : _vm._e(),
          _vm.paySlipNameKT.nameK04 !== "Khoản 4"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.paySlipNameKT.nameK04,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameExpenses(
                                        _vm.paySlipNameKT.nameK04
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.paySlipNameKT.nameK04))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.statusSalary) !==
                          "Chưa có phiếu lương"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.statusSalary
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyK04
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("formatCurrency")(
                                                  scope.row.moneyK04
                                                )
                                              )
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                        },
                                        [_vm._v("-")]
                                      ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  117505921
                ),
              })
            : _vm._e(),
          _vm.paySlipNameKT.nameK05 !== "Khoản 5"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.paySlipNameKT.nameK05,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameExpenses(
                                        _vm.paySlipNameKT.nameK05
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.paySlipNameKT.nameK05))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.statusSalary) !==
                          "Chưa có phiếu lương"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.statusSalary
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyK05
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyK05
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  890306625
                ),
              })
            : _vm._e(),
          _vm.paySlipNameKT.nameK06 !== "Khoản 6"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.paySlipNameKT.nameK06,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameExpenses(
                                        _vm.paySlipNameKT.nameK06
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.paySlipNameKT.nameK06))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.statusSalary) !==
                          "Chưa có phiếu lương"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.statusSalary
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyK06
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyK06
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3805361185
                ),
              })
            : _vm._e(),
          _vm.paySlipNameKT.nameK07 !== "Khoản 7"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.paySlipNameKT.nameK07,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameExpenses(
                                        _vm.paySlipNameKT.nameK07
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.paySlipNameKT.nameK07))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.statusSalary) !==
                          "Chưa có phiếu lương"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.statusSalary
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyK07
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyK07
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  912056961
                ),
              })
            : _vm._e(),
          _vm.paySlipNameKT.nameK08 !== "Khoản 8"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.paySlipNameKT.nameK08,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameExpenses(
                                        _vm.paySlipNameKT.nameK08
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.paySlipNameKT.nameK08))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.statusSalary) !==
                          "Chưa có phiếu lương"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.statusSalary
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyK08
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyK08
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2464482913
                ),
              })
            : _vm._e(),
          _vm.paySlipNameKT.nameK09 !== "Khoản 9"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.paySlipNameKT.nameK09,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameExpenses(
                                        _vm.paySlipNameKT.nameK09
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.paySlipNameKT.nameK09))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.statusSalary) !==
                          "Chưa có phiếu lương"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.statusSalary
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyK09
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyK09
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1941793729
                ),
              })
            : _vm._e(),
          _vm.paySlipNameKT.nameK10 !== "Khoản 10"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.paySlipNameKT.nameK10,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameExpenses(
                                        _vm.paySlipNameKT.nameK10
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.paySlipNameKT.nameK10))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.statusSalary) !==
                          "Chưa có phiếu lương"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.statusSalary
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyK10
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyK10
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3761445185
                ),
              })
            : _vm._e(),
          _vm.paySlipNameKT.nameK11 !== "Khoản 11"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.paySlipNameKT.nameK11,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameExpenses(
                                        _vm.paySlipNameKT.nameK11
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.paySlipNameKT.nameK11))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.statusSalary) !==
                          "Chưa có phiếu lương"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.statusSalary
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyK11
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyK11
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1610820193
                ),
              })
            : _vm._e(),
          _vm.paySlipNameKT.nameK12 !== "Khoản 12"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.paySlipNameKT.nameK12,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameExpenses(
                                        _vm.paySlipNameKT.nameK12
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.paySlipNameKT.nameK12))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.statusSalary) !==
                          "Chưa có phiếu lương"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.statusSalary
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyK12
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyK12
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  847296129
                ),
              })
            : _vm._e(),
          _vm.paySlipNameKT.nameK13 !== "Khoản 13"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.paySlipNameKT.nameK13,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameExpenses(
                                        _vm.paySlipNameKT.nameK13
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.paySlipNameKT.nameK13))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.statusSalary) !==
                          "Chưa có phiếu lương"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.statusSalary
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyK13
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyK13
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3105816993
                ),
              })
            : _vm._e(),
          _vm.paySlipNameKT.nameK14 !== "Khoản 14"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.paySlipNameKT.nameK14,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameExpenses(
                                        _vm.paySlipNameKT.nameK14
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.paySlipNameKT.nameK14))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.statusSalary) !==
                          "Chưa có phiếu lương"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.statusSalary
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyK14
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyK14
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1084097
                ),
              })
            : _vm._e(),
          _vm.paySlipNameKT.nameK15 !== "Khoản 15"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.paySlipNameKT.nameK15,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameExpenses(
                                        _vm.paySlipNameKT.nameK15
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.paySlipNameKT.nameK15))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.statusSalary) !==
                          "Chưa có phiếu lương"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.statusSalary
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyK15
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyK15
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3904087009
                ),
              })
            : _vm._e(),
          _vm.paySlipNameKT.nameK16 !== "Khoản 16"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.paySlipNameKT.nameK16,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameExpenses(
                                        _vm.paySlipNameKT.nameK16
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.paySlipNameKT.nameK16))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.statusSalary) !==
                          "Chưa có phiếu lương"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.statusSalary
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyK16
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyK16
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3140562945
                ),
              })
            : _vm._e(),
          _vm.paySlipNameKT.nameK17 !== "Khoản 17"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.paySlipNameKT.nameK17,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameExpenses(
                                        _vm.paySlipNameKT.nameK17
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.paySlipNameKT.nameK17))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.statusSalary) !==
                          "Chưa có phiếu lương"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.statusSalary
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyK17
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyK17
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2978986785
                ),
              })
            : _vm._e(),
          _vm.paySlipNameKT.nameK18 !== "Khoản 18"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.paySlipNameKT.nameK18,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameExpenses(
                                        _vm.paySlipNameKT.nameK18
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.paySlipNameKT.nameK18))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.statusSalary) !==
                          "Chưa có phiếu lương"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.statusSalary
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyK18
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyK18
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  936361537
                ),
              })
            : _vm._e(),
          _vm.paySlipNameKT.nameK19 !== "Khoản 19"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.paySlipNameKT.nameK19,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameExpenses(
                                        _vm.paySlipNameKT.nameK19
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.paySlipNameKT.nameK19))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.statusSalary) !==
                          "Chưa có phiếu lương"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.statusSalary
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyK19
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyK19
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  660606817
                ),
              })
            : _vm._e(),
          _vm.paySlipNameKT.nameK20 !== "Khoản 20"
            ? _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "115",
                  label: _vm.paySlipNameKT.nameK20,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: scope.column.label,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeNameExpenses(
                                        _vm.paySlipNameKT.nameK20
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.paySlipNameKT.nameK20))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.getStatusValue(scope.row.statusSalary) !==
                          "Chưa có phiếu lương"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "click-in-row",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetailKT(
                                        scope.row,
                                        scope.column.label,
                                        scope.row.statusSalary
                                      )
                                    },
                                  },
                                },
                                [
                                  scope.row.moneyK20
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                scope.row.moneyK20
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3195547041
                ),
              })
            : _vm._e(),
          _vm.responseFilterList.length <= 10
            ? _c("el-table-column", {
                attrs: {
                  label: "Tác vụ",
                  fixed: "right",
                  align: "center",
                  width: "225px",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticStyle: { display: "inline-flex" } },
                            [
                              _c("el-button", {
                                staticStyle: { display: "none" },
                              }),
                              _vm.checkPermission(["salary_new_excel_payment"])
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: _vm.getButtonCashierType(
                                          scope.row
                                        ),
                                        disabled:
                                          scope.row.statusSalary === null ||
                                          scope.row.statusSalary ===
                                            "Ẩn phiếu lương" ||
                                          scope.row.sumMoney === 0,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.orderKidsCashierMethod(
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Thanh toán\n          ")]
                                  )
                                : _vm._e(),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "success",
                                    size: "mini",
                                    disabled:
                                      scope.row.statusSalary === null ||
                                      scope.row.sumMoney === 0,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.orderKidsHistoryMethod(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("\n            Lịch sử\n          ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4134839901
                ),
              })
            : _c("el-table-column", {
                attrs: {
                  label: "Tác vụ",
                  fixed: "right",
                  align: "center",
                  width: "208px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticStyle: { display: "inline-flex" } },
                          [
                            _c("el-button", {
                              staticStyle: { display: "none" },
                            }),
                            _vm.checkPermission(["salary_new_excel_payment"])
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: _vm.getButtonCashierType(scope.row),
                                      disabled:
                                        scope.row.statusSalary === null ||
                                        scope.row.statusSalary ===
                                          "Ẩn phiếu lương" ||
                                        scope.row.sumMoney === 0,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.orderKidsCashierMethod(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Thanh toán\n          ")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "success",
                                  size: "mini",
                                  disabled:
                                    scope.row.statusSalary === null ||
                                    scope.row.sumMoney === 0,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.orderKidsHistoryMethod(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            Lịch sử\n          ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("InventoryOrderEmployeeSalary", {
            ref: "InventoryOrderEmployeeSalary",
            attrs: { isOpenInventoryOrder: _vm.showInventoryOrder },
            on: {
              closeInventoryOrder: function ($event) {
                return _vm.closeInventoryOrderDialog()
              },
            },
          }),
          _c("ExcelEmployeeSalaryDetail", {
            ref: "ExcelEmployeeSalaryDetail",
            attrs: {
              isOpenEmployeeDetailDialog: _vm.isOpenEmployeeDetailDialog,
              "kids-detail-data": _vm.kidDetailData,
            },
            on: { closeDetailKid: _vm.handleCloseDetailKid },
          }),
          _vm.checkPermission(["fees_2_excel_cash_payment"])
            ? _c("CollectedMoneyEmployeeSalary", {
                ref: "CollectedMoneyEmployeeSalary",
                attrs: { isOpenCollectedMoney: _vm.showCollectedMoney },
                on: {
                  closeCollectedMoney: function ($event) {
                    return _vm.closeCollectedMoneyDialog()
                  },
                },
              })
            : _vm._e(),
          _c("DetailExpensesEmployeeSalary", {
            ref: "DetailExpensesEmployeeSalary",
            attrs: { isOpenDetailKT: _vm.detailKT },
            on: {
              closeDetailKT: function ($event) {
                return _vm.closeDetailKTDialog()
              },
            },
          }),
          _c("HistoryExpensesEmployeeSalary", {
            ref: "HistoryExpensesEmployeeSalary",
            attrs: { isOpenHistoryDialog: _vm.showHistoryDialog },
            on: {
              closeHistoryOrderKids: function ($event) {
                return _vm.closeOrderKidsHistoryDialog()
              },
            },
          }),
          _c("ExpensesEmployeeSalaryExcelCashier", {
            ref: "ExpensesEmployeeSalaryExcelCashier",
            attrs: {
              isOpenCashierDialog: _vm.showOrderKidsExcelCashierDialog,
              isActivatedAccount: _vm.isActivatedAccount,
            },
            on: { "dialog-close": _vm.closeOrderKidsExcelCashierDialog },
          }),
          _vm.checkPermission(["fees_2_excel_cash_payment"])
            ? _c("ChangeNameExpenses", {
                attrs: {
                  isOpenChangeNameExpensesDialog: _vm.modifierLesRevenus,
                  nameExpenses: _vm.nameExpenses,
                  paySlipNameKT: _vm.paySlipNameKT,
                  checkDelete: _vm.checkDelete,
                },
                on: {
                  closeChangeNameExpensesDialog:
                    _vm.closeChangeNameExpensesDialog,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <el-dialog
      :before-close="handleClose"
      :close-on-click-modal="false"
      :title="'Chi tiết khoản chi : '+ nameKC "
      :visible.sync="isOpenDetailKT"
      top="5vh"
      width="500px"
  >
    <el-form ref="formReq" :label-position="labelPosition" :model="formReq" :rules="rules">
      <el-form-item :label-width="'80px'" label="Số lượng" prop="quantityKC">
        <el-input-number :disabled="isDisabled"  v-model="formReq.quantityKC"  ></el-input-number>
        <span v-if="formReq.quantityKC<0" class="error-message"> &nbsp; &nbsp;* Nhà trường thu tiền.</span>
      </el-form-item>
      <el-form-item :label-width="'80px'" label="Đơn giá" prop="priceKC">
        <el-currency-input id="priceKC" v-model="formReq.priceKC" :disabled="isDisabled" autocomplete="off"
                           style="text-align: center"
        ></el-currency-input>
      </el-form-item>
    </el-form>
    <div style="font-size: 20px; margin-left: 1px;">Thành tiền :
      {{  Math.round(Number(formReq.quantityKC) * Number(formReq.priceKC))| formatCurrency }}
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button v-show="isEditing" type="primary" @click="saveDetailKT('formReq')" :loading="loadingButton">Cập nhật </el-button>
      <el-button v-if="this.statusOrder !== 'Ẩn phiếu lương'" v-show="!isEditing" type="success"
                 @click="editForm">Sửa</el-button>
    <el-button type="danger" @click="handleClose"> <i class="el-icon-circle-close"/> Đóng</el-button>
      </span>
  </el-dialog>
</template>

<script>

import {mapState} from "vuex";
import NewSalaryService from "@/services/EmployeeService/NewSalaryService";

export default {
  name: "DetailExpensesEmployeeSalary",

  computed: {
    ...mapState("employeeSalaryNew", ["dataSearch"]),
  },


  data() {
    return {
      loadingButton : false,
      rules: {
        priceKC: [{
          required: true,
          message: "Không được để trống!",
          trigger: "change",
        }],
        quantityKC: [{
          required: true,
          message: "Không được để trống!",
          trigger: "change",
        }],
      },
      nameKC: "",
      isDisabled: true,
      isEditing: false,
      formReq: {
        priceKC: 0,
        quantityKC: 0,
        discountKT: 0,
      },
      dataResp: {
        idInfo:"",
        spendTurn: "",
        nameKC: "",
        moneyKC: 0,
        priceKC: 0,
        quantityKC: 0,
        date: 0
      },
      dataReq: {
        idInfo: "",
        date: "",
        spendTurn: "",
        nameKC: "",
      },
      labelPosition: 'left',
      statusOrder: "",
    }
  },
  props: {
    isOpenDetailKT: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
   async showDetailKT( idInfo, spendTurn, nameKC, statusOrder, date) {
      this.statusOrder = statusOrder;
      this.dataReq.idInfo = idInfo;
      this.dataReq.spendTurn = spendTurn;
      this.dataReq.nameKC = nameKC;
      this.dataReq.date = date;
      this.nameKC = nameKC;
      await NewSalaryService.showDetailPayment(this.dataReq).then((resp) => {
        this.formReq.quantityKC = resp.data.quantityKC;
        this.formReq.priceKC = resp.data.priceKC;
      })
    },

    saveDetailKT(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loadingButton = true
          this.dataResp.quantityKC = this.formReq.quantityKC;
          this.dataResp.priceKC = this.formReq.priceKC;
          this.dataResp.nameKC = this.nameKC
          this.dataResp.spendTurn = this.dataReq.spendTurn;
          this.dataResp.date = this.dataReq.date;
          this.dataResp.idInfo = this.dataReq.idInfo;
          this.dataResp.moneyKC = Math.round(this.formReq.quantityKC * this.formReq.priceKC);
          NewSalaryService.showDetailPayment(this.dataReq).then((resp) => {
            if (this.dataResp.quantityKC === resp.data.quantityKC && this.dataResp.priceKC === resp.data.priceKC) {
              this.$message({
                message: "Không có dữ liệu thay đổi",
                type: "error",
              });
              this.loadingButton = false
            }
            else {
              NewSalaryService.saveDetailKC(this.dataResp).then(() => {
                this.$message({
                  message: "Cập nhật thành công",
                  type: "success",
                });
                this.loadingButton = false
              }).then(() => {
                this.$store.commit('employeeSalaryNew/get_status_excel')
                this.handleClose()
              }).catch((err) => {
                this.$message({
                  message: err.response.data.message,
                  type: "error",
                });

              });
            }
          })
        } else {
          return false;
        }
      });
    },

    handleClose() {
      this.loadingButton = false
      this.isDisabled = true;
      this.isEditing = false
      this.$refs.formReq.resetFields();
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
      this.$emit("closeDetailKT", false);
    },

    editForm() {
      this.isDisabled = false;
      this.isEditing = true;
    }
  },


}
</script>

<style scoped>

.el-form-item .error-message {
  color: red;
}

</style>
<template>
  <div class="dialog-container">
    <el-dialog
        width="450px"
        title="Thêm khoản"
        :visible.sync="isOpenAddNameExpensesDialog"
        :before-close="handleCloseAddNameExpensesDialog"
        :close-on-click-modal="false"
    >
      <div>
        <el-form
            :model="formData"
            label-position="left"
            ref="formData"
            :rules="rules"
        >
          <el-form-item label="Tên khoản mới" prop="newNameKC">
            <el-input v-model="formData.newNameKC" placeholder="Nhập tên khoản chi mới"></el-input>
            <span class="error-message">{{ customErrorMessages.newNameKC }}</span>
          </el-form-item>
        </el-form>
        <div class="custom_button">
          <el-button
              type="success"
              size="medium"
              :loading="loadingChangeName"
              @click="submitForm"
              v-if="showSaveChange"
          >
            <i class="el-icon-circle-check"/> Lưu
          </el-button>
          <el-button size="medium" type="danger" @click="handleCloseAddNameExpensesDialog">
            <i class="el-icon-circle-close"/> Đóng
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import {mapState} from "vuex";
import newSalaryService from "@/services/EmployeeService/NewSalaryService";

export default {
  name: "AddNameExpenses",
  data() {
    return {
      showSaveChange: false,
      customErrorMessages: {
        newNameKC: ""
      },
      loadingChangeName: false,
      formData: {
        spendTurn: "",
        year: "",
        month: "",
        newNameKC: "",
      },
      rules: {
        newNameKC: [
          {
            validator: this.validateNameChange,
            trigger: "change"
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('employeeSalaryNew', ['dataSearch','paySlipNameKT'])
  },
  props: {
    isOpenAddNameExpensesDialog: {
      type: Boolean,
      default: () => false
    },
  },
  methods: {
    validateNameChange(rule, value, callback) {
      for (let i = 1; i <= 20; i++) {
        if (!value) {// Kiểm tra nếu giá trị rỗng
          this.customErrorMessages.newNameKC = "Giá trị không được để trống!";
          this.showSaveChange = false
          break
        }else if (!isNaN(value)){
          this.showSaveChange = false
          this.customErrorMessages.newNameKC = "Không được nhập tên khoản là các số!";
          break
        } else if (value.toLowerCase().replace(/\s/g, "") === ("Khoản " + i.toString()).toLowerCase().replace(/\s/g, "")) {
          this.showSaveChange = false
          this.customErrorMessages.newNameKC = "Mời bạn nhập khoản khác với khoản mặc định!";
          break
        }else if (i < 10 ){
          if (value.toLowerCase().replace(/\s/g, "") === this.paySlipNameKT[`nameK0${i}`].toLowerCase().replace(/\s/g, "")) {
            this.showSaveChange = false
            this.customErrorMessages.newNameKC = "Tên khoản trùng lặp với khoản  đã có sẵn!";
            break
          }else {
            this.showSaveChange = true
            this.customErrorMessages.newNameKC = ""; // Xóa thông báo lỗi tùy chỉnh
            callback();
          }
        }else if (i > 9){
          if (value.toLowerCase().replace(/\s/g, "") === this.paySlipNameKT[`nameK${i}`].toLowerCase().replace(/\s/g, "")) {
            this.showSaveChange = false
            this.customErrorMessages.newNameKC = "Tên khoản trùng lặp với khoản chi đã có sẵn!";
            break
          }else {
            this.showSaveChange = true
            this.customErrorMessages.newNameKC = ""; // Xóa thông báo lỗi tùy chỉnh
            callback();
          }
        } else {
          this.showSaveChange = true
          this.customErrorMessages.newNameKC = ""; // Xóa thông báo lỗi tùy chỉnh
          callback();
        }
      }
    },
    handleCloseAddNameExpensesDialog() {
      this.loadingChangeName = false;
      this.customErrorMessages.newNameKC = ""
      this.$refs.formData.resetFields()
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
      this.$emit("closeAddNameExpensesDialog", false);
    },
    async submitForm() {
      try {
        this.loadingChangeName = true;
        this.formData.spendTurn = this.dataSearch.spendTurn
        this.formData.year = this.dataSearch.year
        this.formData.month = this.dataSearch.month

        await newSalaryService.addOneExpense(this.formData).then(() => {
          this.$notify.success({
            title: "Thông báo",
            message: "Thêm khoản thành công",
          });
          this.handleCloseAddNameExpensesDialog()
        })
      } catch (error) {
        this.$message({
          message: "Thêm khoản thất bại!",
          type: "error",
        });
      } finally {
        this.loadingChangeName = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.custom_button {
  display: flex;
  justify-content: right;
  margin-top: 30px;
}

.error-message {
  color: red;
}
</style>
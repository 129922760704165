import NewSalaryService from "@/services/EmployeeService/NewSalaryService";

export default {
    methods:{
        // Hiển thị trên app  cho  1 hoặc nhiều nhân viên
        showListEmployeeForApp(idInfoList,dataSearch,showListForApp) {
            if (idInfoList.split(",").map(Number).indexOf(0) === 0) {
                this.$message({
                    message: "Chưa có nhân viên nào được chọn",
                    type: "error",
                });
            } else {
                showListForApp.date = this.$funcCommon.formatMY(dataSearch.year, dataSearch.month);
                showListForApp.idInfoList = idInfoList.split(",").map(Number);
                showListForApp.spendTurn = dataSearch.spendTurn;

                NewSalaryService
                    .showEmployeeSalarySlips(showListForApp)
                    .then(() => {
                        this.$message({
                            message: "Hiển thị thành công",
                            type: "success",
                        });
                        this.$store.dispatch(
                            "employeeSalaryNew/init_employee_data",
                            dataSearch
                        );
                    })
                    .catch((err) => {
                        this.$message({
                            message: err.response.data.message,
                            type: "error",
                        });
                    });
            }
        },

        // Bỏ Hiển thị 1 list phiếu lương trên APP công lương nhân viên
        hiddenListEmployeeForApp(idInfoList,dataSearch,showListForApp) {
            if (idInfoList.split(",").map(Number).indexOf(0) === 0) {
                this.$message({
                    message: "Chưa có nhân viên nào được chọn",
                    type: "error",
                });
            } else {
                showListForApp.date = this.$funcCommon.formatMY(dataSearch.year, dataSearch.month);
                showListForApp.idInfoList = idInfoList.split(",").map(Number);

                showListForApp.spendTurn = dataSearch.spendTurn;

                NewSalaryService
                    .hiddenEmployeeSalarySlips(showListForApp)
                    .then(() => {
                        this.$message({
                            message: "Bỏ hiển thị thành công",
                            type: "success",
                        });
                        this.$store.dispatch(
                            "employeeSalaryNew/init_employee_data",
                            dataSearch
                        );
                    })
                    .catch((err) => {
                        this.$message({
                            message: err.response.data.message,
                            type: "error",
                        });
                    });
            }
        },
    }
}
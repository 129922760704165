<template>
  <el-dialog
      :title="'Tạo ghi chú nhân viên '"
      :visible.sync="isOpenCreateNote"
      width="800px"
      :before-close="handleClose"
      top="5vh"
      :close-on-click-modal="false"
  >
    <el-form :model="formNote" ref="formNote" :label-position="labelPosition">
      <el-form-item label="(1) Ghi chú nhà trường" :label-width="'160px'" style="text-align: left">
        <el-input v-model="formNote.noteExcel1" id="noteExcel1" autocomplete="off" type="textarea" :rows="2"
                  style="text-align: center"
                  placeholder="Nhập ghi chú tại đây"></el-input>
      </el-form-item>
      <el-form-item label="(2) Ghi chú phiếu lương" :label-width="'160px'" style="text-align: left">
        <el-input v-model="formNote.noteExcel2" autocomplete="off" id="noteExcel2" type="textarea" :rows="2"
                  style="text-align: center"
                  placeholder="Nhập ghi chú tại đây"></el-input>
      </el-form-item>
      <el-form-item label="(3) Ghi chú khác" :label-width="'160px'" style="text-align: left">
        <el-input v-model="formNote.noteExcel3" autocomplete="off" id="noteExcel3" type="textarea" :rows="2"
                  style="text-align: center"
                  placeholder="Nhập ghi chú tại đây"></el-input>
      </el-form-item>
      <el-form-item>
        <span style="margin-left: 160px; color: red">* Tất cả ghi chú của nhân viên được chọn sẽ bị ghi đè bằng ghi chú mới.</span>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="success" @click="saveNoteExcel" :loading="loadingNote">Tạo ghi chú </el-button>
    <el-button @click="handleClose" type="danger"><i class="el-icon-circle-close"/> Đóng</el-button>
      </span>
  </el-dialog>
</template>

<script>
import {mapState} from "vuex";
import NewSalaryService from "@/services/EmployeeService/NewSalaryService";

export default {
  name: "CreateNoteEmployeeSalary",

  computed: {
    ...mapState("employeeSalaryNew", ["idInfoList", "dataSearch"]),
  },

  data() {
    return {
      loadingNote: false,
      labelPosition: 'left',
      formNote: {
        noteExcel1: "",
        noteExcel2: "",
        noteExcel3: "",
      },
      dataResp: {
        date: "",
        idInfoList: [],
        spendTurn: "",
        noteExcel1: "",
        noteExcel2: "",
        noteExcel3: "",
      },
    }
  },

  props: {
    isOpenCreateNote: {
      type: Boolean,
      default: false
    }
  },

  methods: {

    getNoteExcel() {
      this.formNote.noteExcel1 = "";
      this.formNote.noteExcel2 = "";
      this.formNote.noteExcel3 = "";

    },


    saveNoteExcel() {
      this.loadingNote = true;
      this.dataResp.noteExcel1 = this.formNote.noteExcel1;
      this.dataResp.noteExcel2 = this.formNote.noteExcel2;
      this.dataResp.noteExcel3 = this.formNote.noteExcel3;
      if (this.dataResp.noteExcel1 === "" && this.dataResp.noteExcel2 === "" && this.dataResp.noteExcel3 === "") {
        this.$message({
          message: "Chưa nhập ghi chú nào",
          type: "error",
        });
        this.loadingNote = false;
      } else {
        this.dataResp.date = this.$funcCommon.formatMY(this.dataSearch.year, this.dataSearch.month)
        this.dataResp.idInfoList = this.idInfoList.split(",").map(Number);
        this.dataResp.spendTurn = this.dataSearch.spendTurn;
        NewSalaryService.createNoteEmployeeSalarySlips(this.dataResp).then(() => {
          this.$message({
            message: "Tạo ghi chú thành công",
            type: "success",
          });
          this.loadingNote = false;
          this.formNote.noteExcel1 = "";
          this.formNote.noteExcel2 = "";
          this.formNote.noteExcel3 = "";
          this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
        }).catch((err) => {
          this.$message({
            message: err.response.data.message,
            type: "error",
          });
          this.loadingNote = false;
        });
        this.handleClose();
      }
    },
    handleClose() {
      this.loadingNote = false;
      this.formNote.noteExcel1 = "";
      this.formNote.noteExcel2 = "";
      this.formNote.noteExcel3 = "";
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
      this.$emit("closeNoteDialog", false);
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <el-dialog
        :title="'Thanh toán phiếu lương: ' + titleInfoAdd"
        :visible.sync="isOpenCashierDialog"
        :empty-text="textTable"
        width="1200px"
        :before-close="closeDialog"
        :close-on-click-modal="false"
        top="1vh"
    >
      <el-col :span="16">
            <span>STK:<span style="font-weight: bold">{{ this.employee.bankAccountNumber }}</span>&nbsp;&nbsp;
              <span>Ngân hàng: </span><span style="font-weight: bold">{{ this.employee.nameBank }}</span></span>
      </el-col>
      <el-col :span="8" style="text-align: right">
         <span>Số tiền tính toán: <span v-if="moneyCheckTotal === 0" style="font-weight: bold">0</span>
              <span v-else style="font-weight: bold">{{ moneyCheckTotal | formatCurrency }}</span>
        </span>
      </el-col>
      <div
          style="
          display: inline-block;
          position: relative;
          z-index: 1;
        "
      ></div>
      <el-table
          ref="multipleTable"
          :empty-text="textTable"
          highlight-current-row
          :data="listExpense"
          :cell-style="tableRowStyle"
          :header-cell-style="tableHeaderColor"
          :max-height="320"
          @selection-change="handleSelectionChange"
          border

      >
        <el-table-column
            type="selection"
            width="55"
            align="center"
        ></el-table-column>
        <el-table-column
            type="index"
            fixed
            label="STT"
            width="80"
            align="center"
        ></el-table-column>
        <el-table-column
            label="Tên khoản"
            prop="fnPackage.name"
            min-width="180"
            align="left"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Số lượng" align="center" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.quantity }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Đơn giá" align="right" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.price | formatCurrency }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Thành tiền" align="right" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.money | formatCurrency }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" width="120">
          <template v-slot:header>
            <div>Tính toán</div>
            <el-checkbox v-model="checkAll" @change="changeAllCheckboxCalculator"></el-checkbox>
          </template>
          <template v-slot:default="scope">
            <el-checkbox v-model="isCheckbox[scope.$index]"
                         @change="changeCheckboxCalculator(scope.$index)"></el-checkbox>
          </template>
        </el-table-column>
      </el-table>
      <br/>
      <div>
        <el-row :gutter="20">
          <el-col :span="6" style="margin-right: 15px">
            <div class="">
              <div class="text-money">
                <span v-if="beforeMoney < 0">Tháng trước thiếu</span>
                <span v-else>Tháng trước thừa</span>

                <span>
                  <span
                      style="font-weight: bold; float: right; color: yellow"
                      v-if="beforeMoney == 0"
                  >0</span
                  >
                  <span
                      style="font-weight: bold; float: right; color: blue"
                      v-if="beforeMoney > 0"
                  >{{ beforeMoney | formatCurrency }}</span
                  >
                  <span
                      style="font-weight: bold; float: right; color: red"
                      v-if="beforeMoney < 0"
                  >{{ Math.abs(beforeMoney) | formatCurrency }}</span
                  >
                </span>
              </div>

              <div class="text-money">
                Tổng tiền tất cả phiếu lương
                <span style="font-weight: bold; float: right">
                  <span v-if="sumMoney == 0">0</span>
                  <span v-else>{{ sumMoney | formatCurrency }}</span>
                </span>
              </div>
              <div class="text-money">
                Tổng tiền đã trả
                <span style="font-weight: bold; float: right">
                  <span v-if="spendMoney == 0">0</span>
                  <span v-else>{{ spendMoney | formatCurrency }}</span>
                </span>
              </div>

              <div class="text-money">
                <span v-if="afterMoney < 0">Tiền còn lại phải trả</span>
                <span v-else>Tiền còn lại được nhận</span>

                <span>
                  <span
                      style="font-weight: bold; float: right; color: yellow"
                      v-if="afterMoney == 0"
                  >0</span
                  >
                  <span
                      style="font-weight: bold; float: right; color: blue"
                      v-if="afterMoney > 0"
                  >{{ afterMoney | formatCurrency }}</span
                  >
                  <span
                      style="font-weight: bold; float: right; color: red"
                      v-if="afterMoney < 0"
                  >{{ Math.abs(afterMoney) | formatCurrency }}</span
                  >
                </span>
              </div>

              <hr
                  style="
                  border-bottom: 1px solid #c0c4cc;
                  margin-top: -0.5em;
                  margin-bottom: 0.7em;
                "
              />
              <div class="text-money">
                Tổng tiền nhập
                <span style="font-weight: bold; float: right; color: yellow">
                  <span v-if="totalMoney == 0">0</span>
                  <span v-else>{{ totalMoney | formatCurrency }}</span>
                </span>
              </div>

              <div class="text-money">
                <span v-if="afterMoneyThisMonth < 0">Số tiền còn thiếu</span>
                <span v-else>Tiền thừa</span>
                <span>
                  <span
                      style="font-weight: bold; float: right; color: yellow"
                      v-if="afterMoneyThisMonth == 0"
                  >0</span
                  >
                  <span
                      style="font-weight: bold; float: right; color: blue"
                      v-if="afterMoneyThisMonth > 0"
                  >{{ afterMoneyThisMonth | formatCurrency }}</span
                  >
                  <span
                      style="font-weight: bold; float: right; color: red"
                      v-if="afterMoneyThisMonth < 0"
                  >{{ Math.abs(afterMoneyThisMonth) | formatCurrency }}</span
                  >
                </span>
              </div>
            </div>
          </el-col>
          <el-col :span="17" style="float: right">
            <el-form
                label-width="155px"
                :model="dataInput"
                label-position="left"
                :rules="rules"
                ref="dataInput"
            >
              <el-form-item
                  label="Tiền mặt"
                  prop="cashMoney"
                  style="display: inline-block; width: 45%"
                  :class="{ 'negative-value': cashMoney < 0 }"
              >
                <el-currency-input
                    :options="customOption"
                    v-model="cashMoney"
                    placeholder="Nhập tiền mặt"
                ></el-currency-input>
              </el-form-item>
              <el-form-item
                  label="Chuyển khoản"
                  prop="transferMoney"
                  style="display: inline-block; width: 45%; float: right"
              >
                <el-currency-input
                    :options="customOption"
                    v-model="transferMoney"
                    placeholder="Nhập tiền chuyển khoản"
                ></el-currency-input>
              </el-form-item>


              <el-form-item
                  label="Người thanh toán"
                  prop="schoolPay"
                  style="display: inline-block; width: 45%"
              >
                <el-input
                    v-model="dataInput.schoolPay"
                    placeholder="Nhà trường"
                ></el-input>
              </el-form-item>
              <el-form-item
                  label="Ngày thanh toán"
                  prop="datePayment"
                  style="display: inline-block; width: 45%; float: right"
              >
                <el-date-picker
                    v-model="dataInput.datePayment"
                    type="date"
                    :clearable="false"
                    style="width: 100%"
                    value-format="yyyy-MM-dd"
                    format="dd-MM-yyyy"
                    placeholder="Chọn ngày"
                    :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>

              <el-form-item label="Tiền thừa thiếu" prop="transferMoneyType">
                <el-radio-group
                    @change="onChangeRadioTransactionEntry"
                    v-model="dataInput.transferMoneyType"
                    style="margin-top: 12px"
                >
                  <el-radio style="color: black" label="MONEY_WALLET"
                  >Không chuyển
                  </el-radio>
                  <el-radio style="color: black" label="MONEY_MONTH"
                  >Sang tháng sau
                  </el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="Mô tả" prop="noteHistory">
                <el-input
                    v-model="noteHistory"
                    placeholder="Nhập mô tả"
                ></el-input>
              </el-form-item>

              <el-form-item>
                <el-checkbox
                    v-if="isActivatedAccount"
                    v-model="dataInput.statusSendNotify"
                >Gửi thông báo thanh toán trên App
                </el-checkbox>
                <span
                    v-else
                    style="color: #d13b93">Tài khoản chưa được kích hoạt, không thể gửi thông báo thanh toán App</span>
              </el-form-item>
              <br/>
              <br/>
            </el-form>
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
            type="primary"
            size="medium"
            :loading="loadingButton"
            mini
            @click="submitForm()"
        >
          <i class="el-icon-circle-check"/>
          <span>Thanh toán</span>
        </el-button>

        <el-button
            type="success"
            size="medium"
            :loading="loadingPdfButton"
            @click="orderPrintMethodNew"
        >
          <i class="el-icon-printer"/>
          <span>In hóa đơn </span>
        </el-button>

        <el-button type="danger" size="medium" @click="closeDialog()">
          <i class="el-icon-circle-close"/>
          <span>{{ $t("button.close") }}</span>
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import moment from "moment";

import newSalaryService from "@/services/EmployeeService/NewSalaryService";

export default {
  name: "ExpensesEmployeeSalaryExcelCashier",
  props: {
    isOpenCashierDialog: {
      type: Boolean,
      default: () => false,
    },
    isActivatedAccount:{
      type: Boolean,
      default:() => false,
    }
  },

  data() {
    return {
      selectAllEnabled: true,
      customOption: {
        currency: "VND",
        locale: "de",
        allowNegative: true,
      },
      previewBillParams: {
        guiId: "success",
        date: "",
        idInfoList: "", // tham số này chỉ truyền vào 1 id thay vì list id
        spendTurn: "",
      },
      textTable: this.$tableEmpty,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },

      titleInfoAdd: "",
      date: "",
      showOrderPrintDialog: false,
      multipleSelection: "",

      /**
       * Bắt đầu
       */
      isCheckbox: Array.from({length: 20}, () => true),
      checkAll: true,
      totalMoney: 0,
      isRadio: false,

      /**
       * Data hiển thị trong bảng
       */

      listExpense: [],
      afterMoney: 0,
      beforeMoney: 0,
      sumMoney: 0,
      spendMoney: 0,
      moneyCheckTotal: 0,
      transactionEntry: "",

      /**
       * Data gửi về để lưu lịch sử thanh toán
       */

      dataInput: {
        idInfo: Number,
        spendTurn: 0,
        spendMoney: 0,
        cashMoney: 0,
        transferMoney: 0,
        afterMoney: 0,
        paymentType: null,
        schoolPay: "Nhà trường",
        date: "",
        datePayment: moment(String(new Date())).format("YYYY-MM-DD"),
        noteHistory: null,
        transferMoneyType: "MONEY_WALLET",
        statusSendNotify: false
      },
      employee: {},

      cashMoney: 0,
      transferMoney: 0,
      afterMoneyThisMonth: 0,
      noteHistory: "",
      responseData: {},
      loadingButton: false,
      loadingPdfButton: false,
      rules: {
        cashMoney: [
          {
            required: true,
            message: "Tiền mặt không được để trống!",
            trigger: "blur",
          },
        ],

        transferMoney: [
          {
            required: true,
            message: "Tiền chuyển khoản không được để trống!",
            trigger: "blur",
          },
        ],
        schoolPay: [
          {required: true, message: 'Người thanh toán không được để trống!', trigger: 'blur'},
        ],
      },

      idInfo: 0,
      fullName: "",
      paySlipCode: "",
      paySlipCodeNotify: "",
      spendTurn: 0,
      month: 0,
      year: 0,


    };
  },

  watch: {
    /**
     * Theo dõi thay đổi tiền mặt, chuyển khoản
     * @param newValue
     */
    cashMoney(newValue) {
      if (newValue == null) {
        this.dataInput.cashMoney = 0;
      } else {
        this.dataInput.cashMoney = newValue;
      }
      this.getAfterMoneyThisMonth();
    },
    transferMoney(newValue) {
      if (newValue == null) {
        this.dataInput.transferMoney = 0;
      } else {
        this.dataInput.transferMoney = newValue;
      }
      this.getAfterMoneyThisMonth();
    },
    noteHistory(newValue) {
      this.dataInput.noteHistory = newValue;
    },
  },
  methods: {
    calculateTotalMoney() {
      this.moneyCheckTotal = this.isCheckbox.reduce((total, isChecked, index) => {
        if (isChecked) {
          return total + this.listExpense[index].money;
        } else {
          return total;
        }
      }, 0);
    },
    changeAllCheckboxCalculator() {
      this.isCheckbox = this.isCheckbox.map(() => this.checkAll);
      this.calculateTotalMoney();
    },
    /**
     * Xử lý khi lựa chọn checkbox tính toán
     */

    changeCheckboxCalculator(index) {
      let money = this.listExpense[index].money;
      if (this.isCheckbox[index]) {
        this.moneyCheckTotal += money;
      } else {
        this.moneyCheckTotal -= money;
      }
      this.checkAll = this.isCheckbox.every((isChecked) => isChecked);
      this.calculateTotalMoney();
    },
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;";
    },
    tableRowStyle({row}) {
      if (row.paid === 0) {
        return "color: #F56C6C";
      } else if (row.paid < row.money) {
        return "color: #E6A23C";
      }
    },

    clearData() {
      this.loadingButton = false;
      this.checkNotOrder = false;
      this.listExpense = [];
      this.afterMoney = 0;
      this.beforeMoney = 0;
      this.sumMoney = 0;
      this.spendMoney = 0;
      this.moneyCheckTotal = 0;
      this.isCheckbox = [];
      this.totalMoney = 0;
      this.isRadio = false;
      this.cashMoney = 0;
      this.transferMoney = 0;
      this.afterMoneyThisMonth = 0;
      this.noteHistory = "";
      this.cashMoney = 0;
      this.transferMoney = 0;
      this.transactionEntry = "";
      this.$refs["dataInput"].resetFields();
    },

    /**
     * Đóng Dialog
     */

    closeDialog() {
      this.$emit("dialog-close");
      this.checkAll = true;
      setTimeout(() => {
        this.clearData();
      }, 300);
    },

    closeOrderPrintDialog() {
      this.showOrderPrintDialog = false;
      setTimeout(() => {
        this.loadingPdfButton = false;
      }, 1500);
      this.reloadMethod();
    },


    /**
     * Hàm in hóa đơn : Chờ Dialog in hóa đơn
     */
    orderPrintMethodNew() {
      this.checkButtonBefore();
      this.$store.dispatch(
          "employeeSalaryNew/preview_info_for_one_kid",
          this.previewBillParams
      ); // gọi api để lấy thông tin hóa đơn ...

    },

    checkButtonBefore() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          message: "Không có khoản chi nào được chọn",
          type: "error",
        });
        throw null;
      }
    },
    reloadMethod() {
      // setTimeout(() => {
      // location.reload();
      // }, 6000);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$store.commit("employeeSalaryNew/bill_table_for_one_kid", val);
    },

    /**
     * Nhận dữ liệu từ cha, khởi tạo dữ liệu hiển thị ra màn hình
     // * @param data
     */
    getDataCashierSalaryInitial(idInfo, fullName, paySlipCode, paySlipCodeNotify, spendTurn, month, year) {
      this.idInfo = idInfo;
      this.fullName = fullName;
      this.paySlipCode = paySlipCode;
      this.paySlipCodeNotify = paySlipCodeNotify;
      this.spendTurn = spendTurn;
      this.month = month;
      this.year = year;
      // tham số in hóa đơn
      const date = this.$funcCommon.formatMY(year, month)
      this.previewBillParams.date = date;
      this.previewBillParams.spendTurn = spendTurn;
      this.previewBillParams.idInfoList = idInfo;
      this.dataInput.idKids = idInfo;
      this.dataInput.spendTurn = spendTurn;
      if (month < 10) {
        this.dataInput.date = year + "-0" + month + "-15";
      } else {
        this.dataInput.date = year + "-" + month + "-15";
      }
      let param = {
        idInfo: idInfo,
        spendTurn: spendTurn,
        month: month,
        year: year
      }
      newSalaryService
          .showAllExpenseForSaveHistory(param)
          .then((res) => {
            this.listExpense = res.data.expenseDetailList;
            this.$refs.multipleTable.toggleAllSelection()
            this.titleInfoAdd = fullName + " - " + res.data.paySlipCode;
            this.afterMoney = res.data.afterMoney;
            this.sumMoney = res.data.sumMoney;
            this.spendMoney = res.data.spendMoney;
            this.beforeMoney = res.data.beforeMoney;
            this.isCheckbox = Array.from({length: this.listExpense.length}, () => true);
            this.calculateTotalMoney()
            this.afterMoneyThisMonth = this.dataInput.cashMoney + this.dataInput.transferMoney + this.afterMoney;
          })
          .catch(() => {
            this.$message({
              message: "Thất bại",
              type: "error",
            });
          });
    },

    /**
     * Load lại thông tin sau khi thanh toán
     */
    getInformationAfterPayment() {
      this.dataInput.schoolPay = "Nhà trường";
      this.noteHistory = "";
      this.dataInput.statusSendNotify = false;
      this.dataInput.paymentType = "";
      this.totalMoney = 0;
      this.cashMoney = 0;
      this.transferMoney = 0;
      const param = {
        idInfo: this.idInfo,
        spendTurn: this.spendTurn,
        month: this.month,
        year: this.year
      }
      newSalaryService.showAllExpenseForSaveHistory(param)
          .then((res) => {
            this.listExpense = res.data.expenseDetailList;
            this.afterMoney = res.data.afterMoney;
            this.beforeMoney = res.data.beforeMoney;
            this.sumMoney = res.data.sumMoney;
            this.spendMoney = res.data.spendMoney;
            this.loadingButton = false;
            this.afterMoneyThisMonth = 0;
          })
          .then(() => {
            this.$store.commit('employeeSalaryNew/get_status_excel')
          })
          .catch(() => {
            this.$message({
              message: "Thất bại",
              type: "error",
            });
          });
    },

    /**
     * Thay đổi chọn radio loại thanh toán
     */

    onChangeRadio() {
      if (this.dataInput.paymentType === "CASH") {
        this.dataInput.cashMoney = this.totalMoney;
        this.dataInput.transferMoney = 0;
        this.getAfterMoneyThisMonth();
      }
      if (this.dataInput.paymentType === "TRANSFER") {
        this.dataInput.transferMoney = this.totalMoney;
        this.dataInput.cashMoney = 0;
        this.getAfterMoneyThisMonth();
      }
    },

    /**
     * Thay đổi chọn radio trạng thái kết chuyển
     */
    onChangeRadioTransactionEntry() {
      this.transactionEntry = this.dataInput.transferMoneyType;
    },

    /**
     * Lấy tiền thiếu thừa còn lại tháng này
     */
    getAfterMoneyThisMonth() {
      this.afterMoneyThisMonth = this.dataInput.cashMoney + this.dataInput.transferMoney + this.afterMoney;
      this.totalMoney = this.dataInput.cashMoney + this.dataInput.transferMoney;

    },

    /**
     * Gửi form lên để lưu dữ liệu vào DB
     */

    submitForm() {
      this.dataInput.spendMoney = this.totalMoney;
      this.dataInput.afterMoney = this.afterMoneyThisMonth;
      const dataSubmit = {
        paySlipCode: this.paySlipCode,
        paySlipCodeNotify: this.paySlipCodeNotify,
        idInfo: this.idInfo,
        spendTurn: this.spendTurn,
        spendMoney: this.dataInput.spendMoney,
        cashMoney: this.dataInput.cashMoney,
        transferMoney: this.dataInput.transferMoney,
        afterMoney: this.dataInput.afterMoney,
        schoolPay: this.dataInput.schoolPay,
        date: this.dataInput.date,
        datePayment: this.dataInput.datePayment,
        noteHistory: this.dataInput.noteHistory,
        transactionEntry: this.dataInput.transferMoneyType,
        statusSendNotify: this.dataInput.statusSendNotify,

      };

      this.$refs["dataInput"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          let moneyInputLocal = this.$funcCommon.formatMoney(this.totalMoney);
          this.$confirm(
              "Bạn có chắc chắn muốn thanh toán với tổng tiền là " +
              moneyInputLocal +
              " VNĐ",
              {
                distinguishCancelAndClose: true,
                confirmButtonText: "Có",
                closeOnClickModal: false,
                cancelButtonText: "Không",
              }
          )
              .then(() => {
                newSalaryService
                    .saveEmployeePaymentForTurn(dataSubmit)
                    .then((resp) => {
                      this.$message({
                        message: resp.data.message,
                        type: "success",
                      });
                      this.getInformationAfterPayment();
                    })
                    .catch((resp) => {
                      this.$message({
                        message: resp.data.message,
                        type: "error",
                      });
                    });
              })
              .catch(() => {
                setTimeout(() => {
                  this.loadingButton = false;
                }, 300);
              });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__body {
  word-break: break-word;
}

/deep/ .el-dialog__title {
  font-size: 25px;
  color: #606266;
}

/deep/ .el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}

/deep/
.el-form-item.is-required:not(.is-no-asterisk)
> .el-form-item__label:before {
  content: "";
  color: #f56c6c;
  margin-left: -4px;
}

/deep/
.el-form-item.is-required:not(.is-no-asterisk)
> .el-form-item__label:after {
  content: "*";
  color: #f56c6c;
  margin-left: 3px;
}

/deep/ .el-form-item__label {
  color: black;
}

.el-row {
  margin-top: 15px;
  padding-top: 20px;
  background: #97bee7;
  color: black;
  border-radius: 5px;
}

.input-common {
  margin-right: 5px;
}

.text-money {
  margin-bottom: 15px;
}

/deep/ .el-form-item__content {
  line-height: 0;
}

/deep/ .negative-value .el-input__inner {
  color: red;
}
</style>
export default {
    data(){
        return{
            countColumnAttributes: 0,
        }
    },
    // Đếm số cột khoản thu
    methods:{
        countNonEmptyAttributes(listKT) {
            let count = 0;
            for (let i = 1; i <= 20; i++) {
                if (i < 10){
                    if (listKT[`nameK0${i}`] !== `Khoản ${i}`) {
                        count++;
                    }
                }else {
                    if (listKT[`nameK${i}`] !== `Khoản ${i}`) {
                        count++;
                    }
                }

            }
            this.countColumnAttributes = count;
        },
    }
}
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            width: "700px",
            title: "Upload file mẫu Excel công lương",
            visible: _vm.isOpenUploadTemplateEmployeeSalary,
            "before-close": _vm.handleCloseTemplateEmployeeSalary,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isOpenUploadTemplateEmployeeSalary = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "upload-section" },
            [
              _c(
                "el-form",
                {
                  ref: "formData",
                  attrs: {
                    model: _vm.formData,
                    "label-position": "left",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "file-description" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Nhập mô tả",
                            prop: "sendDescription",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", rows: 2 },
                            model: {
                              value: _vm.formData.sendDescription,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "sendDescription", $$v)
                              },
                              expression: "formData.sendDescription",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-form-item", [
                    _c(
                      "div",
                      { staticClass: "file-upload-field" },
                      [
                        _c(
                          "el-upload",
                          {
                            ref: "upload",
                            attrs: {
                              action: "#",
                              "auto-upload": false,
                              "on-change": _vm.toggleUpload,
                              "on-preview": _vm.handlePreview,
                              "on-remove": _vm.handleRemove,
                              "before-remove": _vm.beforeRemove,
                              accept: ".xls,.xlsx",
                              "file-list": _vm.formData.fileList,
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "font-size": "15px" },
                                attrs: { size: "small", type: "primary" },
                              },
                              [
                                _vm._v("Upload file "),
                                _c("i", {
                                  staticClass: "el-icon-upload el-icon-right",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "buttons" },
                [
                  this.formData.fileList.length !== 0 &&
                  this.formData.sendDescription !== ""
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { "margin-right": "10px" },
                          attrs: {
                            loading: _vm.loadingUploadTemplate,
                            size: "small",
                            type: "success",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleUpload()
                            },
                          },
                        },
                        [_vm._v("\n          Tải lên\n        ")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "danger" },
                      on: { click: _vm.handleCloseTemplateEmployeeSalary },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-close" }),
                      _vm._v(" Đóng\n        "),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
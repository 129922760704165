<template>
  <div>
    <el-dialog
        title="Danh sách nhân viên"
        :visible.sync="dialogFormVisible"
        width="1200px"
        :before-close="handleClose"
        :close-on-click-modal="false">
      <div style="margin-bottom: 1%">
        <span style="color: green">&#10004; : Thông tin không thay đổi</span>
        <span style="color: red; margin-left: 7%">&#10008; : Thông tin đã thay đổi</span>
      </div>
      <el-table
          :empty-text="textTable"
          ref="multipleTable"
          :data="listConfirm"
          :header-cell-style="tableHeaderColor"
          :max-height="400"
          @selection-change="handleSelectionChange"
          border
      >
        <el-table-column
            type="selection"
            align="center"
            width="75"
        >
        </el-table-column>
        <el-table-column
            type="index"
            fixed
            label="STT"
            width="75"
            align="center"
        ></el-table-column>
        <el-table-column
            width="150"
            fixed
            align="center"
            label="Họ và tên"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.fullName }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Mã nhân viên"
            width="120"
            align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.code }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Thông tin nhân viên"
            width="120"
            align="center"
        >
          <template slot-scope="scope">
            <span style="color: green" v-if="scope.row.notifyInfo === 'true'">&#10004;</span>
            <span style="color: red" v-else>&#10008;</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Thông tin khoản chi"
            align="center"
            width="120">
          <template slot-scope="scope">
            <span style="color: green" v-if="scope.row.notifyPayment === 'true'">&#10004;</span>
            <span style="color: red" v-else>&#10008;</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Thông tin khoản chi âm"
            align="center"
            width="120">
          <template slot-scope="scope">
            <span style="color: green" v-if="scope.row.notifyMinusPayment === 'true'">&#10004;</span>
            <span style="color: red" v-else>&#10008;</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Tiền thanh toán"
            align="center"
            width="120">
          <template slot-scope="scope">
            <span style="color: green" v-if="scope.row.notifyMoney === 'true'">&#10004;</span>
            <span style="color: red" v-else>&#10008;</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Số liệu điểm danh"
            align="center"
            width="129">
          <template slot-scope="scope">
            <span style="color: green" v-if="scope.row.notifyAttendance === 'true'">&#10004;</span>
            <span style="color: red" v-else>&#10008;</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Số liệu điểm danh âm"
            align="center"
            width="129">
          <template slot-scope="scope">
            <span style="color: green" v-if="scope.row.notifyMinusAttendance === 'true'">&#10004;</span>
            <span style="color: red" v-else>&#10008;</span>
          </template>
        </el-table-column>
      </el-table>
      <br>
      <el-row class="overwrite" v-if="listConfirm.length !==0">
        <el-col :span="8">
          <div>
            <el-checkbox
                v-model="overwriteAttendance"
            >Cho phép ghi đè điểm danh
            </el-checkbox>
          </div>
        </el-col>
        <el-col :span="8">
          <div>
            <el-checkbox
                v-model="overwritePayment"
            >Cho phép ghi đè khoản chi
            </el-checkbox>
          </div>
        </el-col>
        <el-col :span="8">
          <div>
            <el-checkbox v-model="overwriteNote"
            >Cho phép ghi đè ghi chú
            </el-checkbox>
          </div>
        </el-col>
      </el-row>


      <el-row class="overwrite" v-if="listConfirm.length !==0" style="margin-top: 25px">
        <el-col :span="8">
          <div class="checkbox-container">
            <el-checkbox disabled v-model="overwriteMoney">
              <span style="color: red">Ghi đè tiền thanh toán từ File Excel</span>
            </el-checkbox>
          </div>
        </el-col>
        <el-col :span="8">
          <el-checkbox style="color: black" v-model="checkCreateMoney" @change="changeCreateMoney">Thêm mới tiền đã chi
            (Lần đầu)
          </el-checkbox>
        </el-col>
      </el-row>
      <el-row class="overwrite" v-if="listConfirm.length !==0" style="margin-top: 20px">
        <el-col :offset="8">
          <el-checkbox style="color: black" v-model="checkUpdateMoney" @change="changeUpdateMoney">Cập nhật tiền đã chi
          </el-checkbox>
        </el-col>
      </el-row>
      <el-row v-if="updateMoney==='UPDATE_MONEY'" style="margin-top: 15px">
        <el-col :offset="8">
          <el-radio-group v-model="statusTransactionEntry">
            <el-radio style="color: black" label="MONEY_WALLET">Không chuyển</el-radio>
            <el-radio style="color: black" label="MONEY_MONTH">Sang tháng sau</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>
      <el-row>
        <el-col :offset="20" :span="8">
          <el-button type="primary" :loading="loadingImport" @click="handleClickImportButton">Lưu</el-button>
          <el-button type="danger" @click="handleClose"
          ><i class="el-icon-circle-close"/> Đóng
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import newSalaryService from "@/services/EmployeeService/NewSalaryService";
import {mapState} from "vuex";

export default {
  name: "InformationEmployeeSalary",
  computed: {
    ...mapState("employeeSalaryNew", [
      "dataSearch"
    ]),
  },
  props: {
    month: {
      type: String,
      default: () => false
    },
    year: {
      type: String,
      default: () => false
    },
    monthAndYearUpload: {
      type: String,
      default: () => false
    },
    dialogFormVisible: {
      type: Boolean,
      default: () => false
    },
    listConfirm: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      overwriteAttendance: true,
      overwritePayment: true,
      overwriteMoney: true,
      overwriteNote: true,
      statusTransactionEntry: "MONEY_WALLET",
      updateMoney: "CREATE_MONEY",
      textTable: this.$tableEmpty,
      loadingImport: false,
      paramSaveExcelToDB: [],
      multipleSelection: [],
      isInputDescriptionTurn: false,
      checkCreateMoney: true,
      checkUpdateMoney: false,
    }
  },
  methods: {
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: semi-bold;";
    },
    changeUpdateMoney() {
      this.checkCreateMoney = false
      if (this.checkUpdateMoney === true) {
        this.updateMoney = 'UPDATE_MONEY'
      } else {
        this.checkCreateMoney = true
        this.updateMoney = 'CREATE_MONEY'
      }
      this.statusTransactionEntry = 'MONEY_WALLET'
    },
    changeCreateMoney() {
      this.checkUpdateMoney = false
      if (this.checkCreateMoney === true) {
        this.updateMoney = 'CREATE_MONEY'
      } else {
        this.checkUpdateMoney = true
        this.updateMoney = 'UPDATE_MONEY'
      }
      this.statusTransactionEntry = 'MONEY_WALLET'
    },

    /**
     *  Phương thức được gọi mỗi khi có sự thay đổi trong các hàng được chọn
     * @param val
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    async handleClickImportButton() {
      this.loadingImport = true
      this.paramSaveExcelToDB = []
      let listMinus = []
      this.multipleSelection.forEach((x) => {
        let param = {
          code: String,
          idInfo: Number,
          month: Number,
          year: Number,
          statusSaveAttendance: Boolean,
          statusSavePayment: Boolean,
          statusSaveMoney: Boolean,
          statusSaveNote: Boolean,
          statusTransactionEntry: String,
          statusAddMoneyFirst: String,
        }
        if (x.notifyMinusPayment === "true" && x.notifyMinusAttendance === "true") {
          param.code = x.code
          param.idInfo = x.idInfo
          param.month = this.month
          param.year = this.year
          param.statusSaveAttendance = this.overwriteAttendance
          param.statusSavePayment = this.overwritePayment
          param.statusSaveMoney = this.overwriteMoney
          param.statusSaveNote = this.overwriteNote
          param.statusTransactionEntry = this.statusTransactionEntry
          param.statusAddMoneyFirst = this.updateMoney
          this.paramSaveExcelToDB.push(param)
        }
        if (x.notifyMinusPayment === "false" || x.notifyMinusAttendance === "false") {
          listMinus.push(x)
        }
      });
      if (this.multipleSelection.length < 1) {
        this.$message({
          message: "Bạn chưa chọn nhân viên nào",
          type: "error",
        });
        this.loadingImport = false
      }else if (this.overwritePayment === true && this.multipleSelection.length !== this.listConfirm.length && this.checkUpdateMoney === true){
        this.$message({
          message: "Đề nghị chọn tất cả nhân viên để cập nhật mới toàn bộ dữ liệu!",
          type: "error",
        });
        this.loadingImport = false
      } else {
        if (listMinus.length > 0) {
          let messageErr = "Các học sinh: " + "<br>" + "<br>"
          for (let i = 0; i < listMinus.length; i++) {
            messageErr += (i + 1) + "-" + listMinus[i].name + "<br>"
          }
          messageErr = messageErr + "<br>" + "Thông tin điểm danh hoặc khoản chi bị âm, vui lòng kiểm tra lại!"
          this.$message({
            message: messageErr,
            type: "error",
            dangerouslyUseHTMLString: true,
            duration: 5000
          });
          this.loadingImport = false
        } else {
          try {
            await this.$confirm("Bạn có chắc chắn Upload File", "Thông báo!", {
              distinguishCancelAndClose: true,
              closeOnClickModal: false,
              confirmButtonText: "Có",
              cancelButtonText: "Không",
            });
            await newSalaryService.saveDataExcelSalary(this.paramSaveExcelToDB);
            this.$message({
              message: "Upload File thành công",
              type: "success",
            });
            this.handleBeforeUploadSuccess();
            this.handleClose();
          } catch (error) {
            this.$message({
              message: "Upload File thất bại",
              type: "error",
            });
          } finally {
            this.loadingImport = false;
          }
        }
      }
    },

    /**
     * Lấy lượt chi
     */



    handleBeforeUploadSuccess() {
      this.dataSearch.month = this.$props.month
      this.dataSearch.year = this.$props.year
      this.dataSearch.monthAndYear = this.$props.monthAndYearUpload
      this.$store.dispatch("finance2Module/init_kids_data", this.dataSearch)
      this.$emit("close", false);
    },

    handleClose() {
      this.overwriteAttendance = true
      this.overwritePayment = true
      this.overwriteMoney = true
      this.multipleSelection = []
      this.checkCreateMoney = true
      this.checkUpdateMoney = false
      this.updateMoney = "CREATE_MONEY"
      this.statusTransactionEntry = "MONEY_WALLET"
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
      this.$emit("close", false);
    }
  }
}
</script>

<style lang="scss" scoped>
.button_method {
  text-align: left;
}

.input-common {
  margin-right: 5px;
}

.excel-upload-input {
  display: none;
}

.overwrite {
  margin-top: 5px;
}

.excel_upload {
  float: left;
  margin-right: 10px;
}

/deep/ .el-form-item__label {
  text-align: left;
}

.custom_button {
  display: flex;
  justify-content: right;
  margin-right: 10px;
  margin-top: 10px;
}

.custom_button_error {
  display: flex;
  justify-content: right;
  margin-top: 20px;
}
</style>
<template>
  <div>
    <el-dialog
        width="580px"
        title="Tạo đợt chi"
        :visible.sync="isOpenImportChecker"
        :before-close="handleClose"
        @open="handleOpen"
        :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="Chọn tháng/đợt chi" label-width="150px">
          <el-row>
            <el-col :span="21">
              <el-date-picker
                  style="width: 135px"
                  class="custom-date-picker"
                  :clearable="true"
                  v-model="monthAndYearUpload"
                  type="month"
                  @change="handelChangeDate"
                  placeholder="Chọn tháng"
                  value-format="M-yyyy"
                  format="MM-yyyy"
                  :time-arrow-control="false"
              >
              </el-date-picker>
              <el-select
                  style="width: 130px; margin-left: 10px"
                  class="input-common"
                  v-model="turn"
                  placeholder="Đợt chi"
                  clearable
                  @change="showStartAndEndDateSalary"
              >
                <el-option
                    v-for="item in spendTurn"
                    :key="item"
                    :value="item"
                    :label="`Đợt ${item}`"
                ></el-option>
              </el-select>
              <el-button @click="handleOpenInputDescriptionTurn">
                <i
                    class="el-icon-circle-plus"
                ></i>
              </el-button>
<!--              <span v-if="isInputDescriptionTurn" style="margin-left: 15px">{{ "Đợt " + form.spendTurn }}</span>-->
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item v-if="isInputDescriptionTurn">
          <el-row>
            <el-col :span="4">
              <span>Mô tả đợt chi</span>
            </el-col>
            <el-col :span="14" style="margin-left: 60px">
              <el-input v-model="form.descriptionTurn" autocomplete="off" placeholder="Mời nhập mô tả đợt chi"
                        type="textarea"
                        style="width: 335px; font-family: Roboto,serif"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="Khoảng thời gian" label-width="140px">
          <el-date-picker
              style="width:335px; margin-left: 10px"
              format="dd-MM-yyyy"
              v-model="listDay"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="-"
              start-placeholder="Ngày bắt đầu"
              end-placeholder="Ngày kết thúc"
              :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
      </el-form>

      <div class="file-upload-field">
        <el-upload
            action="#"
            :auto-upload="false"
            :on-change="handleUpload"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            ref="upload"
            accept=".xls,.xlsx"
            :file-list="fileList"
            :limit="1"
            :close-on-click-modal="false"
        >
          <el-button size="small" type="primary" style="font-size: 15px">Tải file <i
              class="el-icon-upload el-icon-right"></i></el-button>
          <span style="margin-left: 55px">Đề nghị tải file Excel theo đúng định dạng của Onekids</span>
        </el-upload>

      </div>
      <div class="custom_button">
        <el-button type="primary" @click="handleExcelSalaryImport" v-if="showUploadButton" :loading="loadingImport">
          Upload <i
            class="el-icon-upload el-icon-right"></i></el-button>
        <el-button @click="handleClose" type="danger" style="margin-right: 45px"><i class="el-icon-circle-close"/> Đóng</el-button>
      </div>
    </el-dialog>
    <div>
      <FailInformationEmployeeSalary
          :dialogFailInformation="dialogFailInformation"
          :listError="listError"
          :listConfirm="listConfirm"
          @closeFailInformation="handleClose"
      />
      <InformationEmployeeSalary
          :month="month"
          :year="year"
          :monthAndYearUpload="monthAndYearUpload"
          :dialogFormVisible="dialogFormVisible"
          :listConfirm="listConfirm"
          @close="handleClose"
          ref="InformationEmployeeSalary"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import {mapState} from "vuex";
import FailInformationEmployeeSalary
  from "@/components/FinanceVer2/employeeSalary/employeeSalarySearchDialog/FailInformationEmployeeSalary.vue";
import InformationEmployeeSalary
  from "@/components/FinanceVer2/employeeSalary/employeeSalarySearchDialog/InformationEmployeeSalary.vue";
import newSalaryService from "@/services/EmployeeService/NewSalaryService";

export default {
  name: "ImportExcelEmployeeSalary",
  components: {InformationEmployeeSalary, FailInformationEmployeeSalary},
  computed: {
    ...mapState("employeeSalaryNew", ["dataSearch"]),
  },
  props: {
    isOpenImportChecker: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: 'Tuần trước',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Tháng trước',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '3 Tháng trước',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        },
          {
            text: '6 Tháng trước',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit('pick', [start, end]);
            }
          },]
      },
      form: {
        descriptionTurn: "",
        spendTurn: 1
      },
      monthAndYearUpload: '',
      spendTurn: null,
      isInputDescriptionTurn: false,
      listDay: [],
      fileList: [],
      showUploadButton: false,
      loadingImport: false,
      startDateExcel: null,
      endDateExcel: null,
      turn: null,
      excelFile: null,
      month: "",
      year: "",
      listError: [],
      listConfirm: [],
      dialogFormVisible: false,
      dialogFailInformation: false,
    }
  },
  methods: {
   async handleOpen(){
      this.monthAndYearUpload = this.dataSearch.monthAndYear
      await this.handelChangeDate(this.monthAndYearUpload)
      if (this.spendTurn > 0){
        this.turn = 1
        await this.showStartAndEndDateSalary()
      }else {
        this.turn = null
        this.monthAndYearUpload = ''
      }
    },
   async handelChangeDate(val) {
      this.isInputDescriptionTurn = false
      this.startDateExcel = null
      this.endDateExcel = null
      this.listDay = []
      this.turn = null
      this.form.descriptionTurn = ''
      if (val === null || val === '') {
        this.monthAndYearUpload = ""
      } else {
        this.month = val.split('-')[0]
        this.year = val.split('-')[1]
        const param = {
          idDepartment: this.dataSearch.idDepartment,
          month: this.month,
          year: this.year
        }
       await newSalaryService.getSpendTurn(param).then((res) => {
          this.spendTurn = res.data;
          this.form.spendTurn = this.spendTurn + 1
        });
      }
    },
    // Chọn ngày bắt đầu và kết thúc đợt chi
    async showStartAndEndDateSalary() {
      this.isInputDescriptionTurn = false
      this.startDateExcel = null
      this.endDateExcel = null
      this.listDay = []
      let param = {
        spendTurn: this.turn,
        month: this.month,
        year: this.year
      }
     await newSalaryService.showStartAndEndDateSalary(param).then((response) => {
        this.startDateExcel = moment(response.data.startDateExcel).format('MM-DD-YYYY')
        this.endDateExcel = moment(response.data.endDateExcel).format('MM-DD-YYYY')
        this.listDay = [this.startDateExcel, this.endDateExcel]
      })
    },
    // Mở input nhập mô tả đợt chi
    handleOpenInputDescriptionTurn() {
      if (this.spendTurn > 0){
        this.$confirm('Tháng bạn chọn đã tồn tại đợt chi! <br> Bạn có muốn tạo thêm đợt chi khác không?',"Cảnh báo!", {
          confirmButtonText: 'Có',
          cancelButtonText: 'Không',
          type: 'warning',
          dangerouslyUseHTMLString: true
        }).then(() => {
          this.startDateExcel = null
          this.endDateExcel = null
          this.listDay = []
          this.isInputDescriptionTurn = true
          this.turn = this.form.spendTurn;
          this.spendTurn = this.turn
        })
      }else {
        this.startDateExcel = null
        this.endDateExcel = null
        this.listDay = []
        this.isInputDescriptionTurn = true
        this.turn = this.form.spendTurn;
        this.spendTurn = this.turn
      }

    },
    // Kiểm tra có file chọn hay không
    handleUpload(file, fileList) {
      this.fileList = fileList
      this.showUploadButton = fileList.length > 0;
      if (!this.fileList || this.fileList.length === 0) {
        return console.error("Không có file được chọn")
      }
    },

    handlePreview() {
    },

    handleRemove(file, fileList) {
      this.showUploadButton = fileList.length === 0;
    },
    // Xóa file chọn
    beforeRemove(file) {
      return new Promise((resolve, reject) => {
        this.$confirm(`Bạn chắc chắn muốn xóa ${file.name} ?`)
            .then(() => {
              this.showUploadButton = false;
              this.$refs.upload.clearFiles();
            })
            .catch(() => {
              reject();
            });
      });
    },

    // Tải file lên và kiểm tra
    handleExcelSalaryImport() {
      this.loadingImport = true
      if (this.turn == null) {
        this.$message({
          message: "Bạn chưa chọn đợt thu",
          type: "error",
        });
        this.loadingImport = false
      } else {
        if (this.listDay !== null) {
          if (this.listDay.length !== 0) {
            this.startDateExcel = moment(this.listDay[0]).format('YYYY-MM-DD')
            this.endDateExcel = moment(this.listDay[1]).format('YYYY-MM-DD')
          }
        }
        if (this.startDateExcel == null && this.endDateExcel == null) {
          this.$message({
            message: "Bạn chưa chọn ngày bắt đầu và ngày kết thúc đợt thu",
            type: "error",
            duration: 5000
          });
          this.loadingImport = false
        } else if (this.monthAndYearUpload === '' || this.monthAndYearUpload === null) {
          this.$message({
            message: "Bạn chưa chọn tháng",
            type: "error",
            duration: 5000
          });
          this.loadingImport = false
        } else {
          for (const file of this.fileList) {
            this.excelFile = file.raw;
          }
          const formDataExcel = new FormData();
          formDataExcel.append("file", this.excelFile);
          formDataExcel.append("spendTurn", this.turn.toString());
          formDataExcel.append("descriptionTurn", this.form.descriptionTurn);
          formDataExcel.append("month", this.month.toString());
          formDataExcel.append("year", this.year.toString());
          formDataExcel.append("startDateExcel", this.startDateExcel);
          formDataExcel.append("endDateExcel", this.endDateExcel);
          newSalaryService
              .readAndCheckDataExcelSalary(formDataExcel)
              .then((res) => {
                if (res.data[0].notifyStructureTable === "false") {
                  this.$message({
                    message: "File của bạn không đúng cấu trúc, vui lòng kiểm tra lại!",
                    type: "error",
                    duration: 5000
                  });
                  this.loadingImport = false
                } else {
                  if (res.data[0].notifyNoChangeNameKt === "false") {
                    this.$message({
                      showClose: true,
                      message: "Bạn chưa thay đổi tên khoản." + "<br>" + "Vui lòng sửa lại tên khoản khác với mẫu mặc định của hệ thống!",
                      dangerouslyUseHTMLString: true,
                      type: "error",
                      duration: 5000
                    });
                    this.loadingImport = false
                  } else {

                    if (res.data[0].notifySkipNameKc == "false") {
                      this.$message({
                        showClose: true,
                        message: "Bạn đã không nhập tên khoản theo đúng thứ tự trong file Excel." + "<br>" + "Vui lòng nhập lại tên khoản!",
                        dangerouslyUseHTMLString: true,
                        type: "error",
                        duration: 5000
                      });
                      this.loadingImport = false
                    } else {
                      if (res.data[0].listDuplicateNamePayment.length !== 0) {
                        let listDuplicate = res.data[0].listDuplicateNamePayment
                        let message = "Các khoản có tên: " + "<br>" + "<br>"
                        for (let i = 0; i < listDuplicate.length; i++) {
                          message += (i + 1) + "-" + listDuplicate[i] + "<br>"
                        }
                        message = message + "<br>" + " Bị trùng, vui lòng kiểm tra lại!"
                        this.$message({
                          showClose: true,
                          message: message,
                          dangerouslyUseHTMLString: true,
                          type: "error",
                          duration: 10000
                        });
                        this.loadingImport = false
                      } else {
                        let listInformation = res.data
                        for (let i = 0; i < listInformation.length; i++) {
                          if (listInformation[i].notifyInfo === "false") {
                            this.listError.push(listInformation[i])
                          } else {
                            this.listConfirm.push(listInformation[i])
                          }
                        }
                        this.loadingImport = false
                        if (this.listError.length === 0) {
                          this.dialogFormVisible = true;
                          this.dialogFailInformation = false;
                        } else {
                          this.dialogFailInformation = true;
                          this.dialogFormVisible = false;
                        }
                      }
                    }

                  }
                }
              }).catch(() => {
            this.$message.error('Không đọc được file Excel')
            this.loadingImport = false
          });
        }
      }
    },
   async getSpendTurn() {
      const param = {
        idDepartment: this.dataSearch.idDepartment,
        month: this.month,
        year: this.year
      }
    await  newSalaryService.getSpendTurn(param)
          .then((res) => {
            this.spendTurn = res.data;
            this.$emit('refreshSpendTurn', this.spendTurn)
          });
    },

    handleClose() {
      this.monthAndYearUpload = ""
      this.getSpendTurn()
      this.listDay = []
      this.isInputDescriptionTurn = false
      this.form.descriptionTurn = ""
      this.turn = null
      this.showUploadButton = false;
      this.listError = [];
      this.listConfirm = [];
      this.dialogFormVisible = false
      this.dialogFailInformation = false
      this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
      this.$emit("closeCheckerSalaryDialog", false);
      this.$refs.upload.clearFiles();
    }
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-form-item__label {
  text-align: left;
}

.custom_button {
  display: flex;
  justify-content: right;
  margin-right: 10px;
  margin-top: 10px;
}

.custom_button_error {
  display: flex;
  justify-content: right;
  margin-top: 20px;
}

.input-common {
  margin-right: 5px;
}
</style>
import NewSalaryService from "@/services/EmployeeService/NewSalaryService";
import {handleAndDownloadExcel} from "@/utils/dowloadExcel";

export default {
    data() {
        return {
            isOrderDelete: false
        }
    },
    methods: {
        // Hủy phiếu lương
        cancelsSalarySlip(idInfoList, dataSearch, showListForApp) {
            if (idInfoList.split(",").map(Number).indexOf(0) === 0) {
                this.$message({
                    message: "Chưa có nhân viên nào được chọn",
                    type: "error",
                });
            } else {
                this.$confirm("Bạn có chắc chắn muốn hủy phiếu lương?", "Thông báo !", {
                    distinguishCancelAndClose: true,
                    closeOnClickModal: false,
                    confirmButtonText: "Có",
                    cancelButtonText: "Không",
                }).then(() => {
                    showListForApp.date = this.$funcCommon.formatMY(dataSearch.year, dataSearch.month);
                    showListForApp.idInfoList = idInfoList.split(",").map(Number);
                    showListForApp.spendTurn = dataSearch.spendTurn;

                    NewSalaryService.cancelsSalarySlip(showListForApp)
                        .then(() => {
                            this.$message({
                                message: "Hủy phiếu lương thành công",
                                type: "success",
                            });
                            this.isOrderDelete = !this.isOrderDelete
                        })
                        .then(() => {
                            this.$store.dispatch("employeeSalaryNew/init_employee_data", dataSearch);
                        })
                        .catch((err) => {
                            this.$message({
                                message: err.response.data.message,
                                type: "error",
                            });
                        });
                });
            }
        },
        
        // Khôi phục phiếu lương
        restoreSalarySlip(idInfoList, dataSearch, showListForApp) {
            if (idInfoList.split(",").map(Number).indexOf(0) === 0) {
                this.$message({
                    message: "Chưa có nhân viên nào được chọn",
                    type: "error",
                });
            } else {
                this.$confirm("Bạn có muốn khôi phục phiếu lương?", "Thông báo !", {
                    distinguishCancelAndClose: true,
                    closeOnClickModal: false,
                    confirmButtonText: "Có",
                    cancelButtonText: "Không",
                }).then(() => {
                    showListForApp.date = this.$funcCommon.formatMY(dataSearch.year, dataSearch.month);
                    showListForApp.idInfoList = idInfoList.split(",").map(Number);
                    showListForApp.spendTurn = dataSearch.spendTurn;

                    NewSalaryService
                        .restoreSalarySlip(showListForApp)
                        .then(() => {
                            this.$message({
                                message: "Khôi phục phiếu lương thành công",
                                type: "success",
                            });
                            this.$store.dispatch("employeeSalaryNew/init_employee_data", dataSearch);
                            this.isOrderOpen = !this.isOrderOpen
                        })
                        .catch((err) => {
                            this.$message({
                                message: err.response.data.message,
                                type: "error",
                            });
                        });
                });
            }
        },

        // Xóa phiếu lương
        destroySalarySlip(idInfoList, dataSearch, showListForApp) {
            if (idInfoList.split(",").map(Number).indexOf(0) === 0) {
                this.$message({
                    message: "Chưa có nhân viên nào được chọn",
                    type: "error",
                });
            } else {
                this.$confirm("Bạn có chắc chắn muốn xóa phiếu lương?", "Thông báo !", {
                    distinguishCancelAndClose: true,
                    closeOnClickModal: false,
                    confirmButtonText: "Có",
                    cancelButtonText: "Không",
                }).then(() => {
                    showListForApp.date = this.$funcCommon.formatMY(dataSearch.year, dataSearch.month);
                    showListForApp.idInfoList = idInfoList.split(",").map(Number);
                    showListForApp.spendTurn = dataSearch.spendTurn;

                    NewSalaryService
                        .destroySalarySlip(showListForApp)
                        .then(() => {
                            this.$message({
                                message: "Xóa phiếu lương thành công",
                                type: "success",
                            });
                            const param = {
                                idDepartment: dataSearch.idDepartment,
                                month: dataSearch.month,
                                year: dataSearch.year
                            }
                            NewSalaryService.getSpendTurn(param)
                                .then((res) => {
                                    if (res.data === 0) {
                                        this.dataSearch.spendTurn = null;
                                        this.spendTurn = null;
                                        this.$store.commit("employeeSalaryNew/loading_data")
                                        this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
                                        return;
                                    }
                                    this.spendTurn = res.data;
                                    this.dataSearch.spendTurn = this.spendTurn;
                                    this.$store.commit("employeeSalaryNew/loading_data")
                                    this.$store.dispatch("employeeSalaryNew/init_employee_data", this.dataSearch);
                                });
                            this.$store.dispatch("employeeSalaryNew/init_employee_data", dataSearch);
                            this.isOrderDestroy = !this.isOrderDestroy
                        })
                        .catch((err) => {
                            this.$message({
                                message: err.response.data.message,
                                type: "error",
                            });
                        });
                });
            }
        },

        // In hóa đơn hàng loạt theo tháng
        printMonthlyInvoicesInBulk(date, guiId) {
           const param = {
               date: date,
               guiId: guiId
           }
            NewSalaryService.issueMonthlySalarySlips(param).then((response) => {
                if (response.data.message === "processing") {
                    this.$notify.info({
                        title: "Thông báo",
                        message: "Dữ liệu đang được xử lý !",
                    });
                }
                const checkProcess = setInterval(() => {
                    NewSalaryService
                        .issueMonthlySalarySlips(param)
                        .then((response) => {
                            // chạy neeus k conf processing
                            if (response.data.message === "processing") {
                                this.loadingBill = true
                                return;
                            }
                            if (response.data.message === "fail") {
                                this.loadingBill = false
                                clearInterval(checkProcess)
                                this.$notify.error({
                                    title: "Thông báo",
                                    message: "Lỗi không xuất được dữ liệu !",
                                });
                                return;
                            }
                            if (response.data.message !== "processing" && response.data.message !== "fail") {
                                const fileNameRegex = /complete_(.*).xlsx/;
                                const matches = response.data.message.match(fileNameRegex);
                                const fileName = matches[1];
                                this.loadingBill = false
                                this.$notify.success({
                                    title: "Thông báo",
                                    message: "Dữ liệu xuất thành công",
                                });
                                let excelFilename = response.data.message.split('complete_')[1];
                                clearInterval(checkProcess);
                                return NewSalaryService
                                    .downloadBillToExcel(guiId)
                                    .then((res) => {
                                        const excelData = res.data;
                                        excelFilename = `${fileName}.xlsx`; // Provide a desired filename for the downloaded file
                                        handleAndDownloadExcel(excelData, excelFilename);
                                    });

                            }
                        })
                        .catch((err) => {
                            return Promise.reject(err);
                        });
                }, 5000);
            })
        },
    },
};